import Api from "./index.js";
import store from "@/store/index.js";

class ProductsApi extends Api {
    async get(filters = {}, pagination = {}) {
        const default_pagination = {
            page: 1,
            posts_per_page: 20,
        };

        pagination = Object.assign(default_pagination, pagination);

        const data = { filters, pagination };
        const result = await this.send("methods/doors", data, "post");
        return result?.data;
    }

    async get_by_id(slug, info = true) {
        const params = new URLSearchParams({ info }).toString();

        const result = await this.send(
            "methods/product/" + slug + "/?" + params
        );
        return result?.data;
    }

    async products_actions(type, data, from = "card") {
        if (store.state.user) {
            if (type == "basket") {
                const result = await this.send(
                    "methods/products_actions/basket",
                    {
                        date: data.date,
                        // products: data.products,
                        products: [
                            {
                                id: data.id,
                                count: data.count,
                                info: data.info,
                                uniq_id: data.uniq_id,
                                color_id: data.color_id,
                            },
                        ],
                        count_add: data.count_add,
                    },
                    "post"
                );

                if (result?.data?.success) {
                    store.commit("setBadgeBasket", result?.data?.products);
                }

                return result?.data;
            }

            if (type == "favourites") {
                const result = await this.send(
                    "methods/products_actions/favourites",
                    { date: data.date, products: [data.id] },
                    "post"
                );

                if (result?.data?.success) {
                    store.commit("setBadgeFavourites", result?.data?.products);
                }

                return result?.data;
            }

            if (type == "compare") {
                const result = await this.send(
                    "methods/products_actions/compare",
                    { date: data.date, products: [data.id] },
                    "post"
                );
                if (result?.data?.success) {
                    store.commit("setBadgeComparison", result?.data?.products);
                }

                return result?.data;
            }

            if (type == "all") {
                const result = await this.send("methods/products_actions/all");

                if (
                    result?.data?.basket?.success ||
                    result?.data?.compare?.success ||
                    result?.data?.favourites?.success
                ) {
                    store.commit(
                        "setBadgeFavourites",
                        result?.data?.favourites?.products
                    );
                    store.commit(
                        "setBadgeComparison",
                        result?.data?.compare?.products
                    );
                    store.commit(
                        "setBadgeBasket",
                        result?.data?.basket?.products
                    );
                    store.commit(
                        "setComparisonSpecifications",
                        result?.data?.compare?.specifications
                    );
                }

                return result?.data;
            }
        } else {
            if (type == "basket") {
                let products = [];

                const items_json = localStorage.getItem("basket");
                if (items_json) {
                    products = JSON.parse(items_json)?.products;
                }

                const temp_id = products.findIndex(
                    (item) => item.uniq_id == data.uniq_id
                );

                if (temp_id != -1) {
                    //   delete products[index]
                    if (from != "card") {
                        products[temp_id].count = data.count;
                    } else {
                        products[temp_id].count += data.count;
                    }
                } else {
                    products.push({
                        id: data.id,
                        count: data.count,
                        info: data.info,
                        uniq_id: data.uniq_id,
                        color_id: data.color_id,
                    });
                }

                const request_result = await this.send(
                    "methods/products_actions/basket",
                    { date: data.date, products },
                    "post"
                );

                if (request_result?.data?.success) {
                    store.commit(
                        "setBadgeBasket",
                        request_result?.data?.products
                    );

                    const cash = request_result?.data?.cash;
                    cash.date = new Date().getTime();
                    localStorage.setItem("basket", JSON.stringify(cash));
                }

                return request_result?.data;
            }

            if (type == "favourites") {
                let products = [];

                const items_json = localStorage.getItem("favourites");
                if (items_json) {
                    products = JSON.parse(items_json)?.products;
                }

                const index = products.findIndex((item) => item == data.id);

                if (index != -1) {
                    delete products[index];
                } else {
                    if (products.length < 20) {
                        products.push(data.id);
                    } else {
                        return {
                            success: false,
                            message:
                                "Нельзя добавить более 20 товаров в избранное.",
                        };
                    }
                }

                const request_result = await this.send(
                    "methods/products_actions/favourites",
                    { date: data.date, products },
                    "post"
                );

                if (request_result?.data?.success) {
                    store.commit(
                        "setBadgeFavourites",
                        request_result?.data?.products
                    );

                    const cash = request_result?.data?.cash;
                    cash.date = new Date().getTime();
                    localStorage.setItem("favourites", JSON.stringify(cash));
                }

                return request_result?.data;
            }

            if (type == "compare") {
                let products = [];

                const items_json = localStorage.getItem("compare");
                if (items_json) {
                    products = JSON.parse(items_json)?.products;
                }

                const index = products.findIndex((item) => item == data.id);

                if (index != -1) {
                    delete products[index];
                } else {
                    if (products.length < 4) {
                        products.push(data.id);
                    } else {
                        return {
                            success: false,
                            message:
                                "Нельзя добавить более 4 товаров в сравнение.",
                        };
                    }
                }

                const request_result = await this.send(
                    "methods/products_actions/compare",
                    { date: data.date, products },
                    "post"
                );

                if (request_result?.data?.success) {
                    store.commit(
                        "setBadgeComparison",
                        request_result?.data?.products
                    );
                    store.commit(
                        "setComparisonSpecifications",
                        request_result?.data?.specifications
                    );

                    const cash = request_result?.data?.cash;
                    cash.date = new Date().getTime();
                    localStorage.setItem("compare", JSON.stringify(cash));
                }

                return request_result?.data;
            }

            if (type == "all") {
                let favourites = [];
                const favourites_json = localStorage.getItem("favourites");
                if (favourites_json) {
                    favourites = JSON.parse(favourites_json)?.products;
                }

                let compare = [];
                const comparison_json = localStorage.getItem("compare");
                if (comparison_json) {
                    compare = JSON.parse(comparison_json)?.products;
                }

                let basket = [];
                const basket_json = localStorage.getItem("basket");
                if (basket_json) {
                    basket = JSON.parse(basket_json)?.products;
                }

                const date = new Date().getTime();

                const request_result = await this.send(
                    "methods/products_actions/all",
                    {
                        arrays: [
                            { type: "favourites", date, products: favourites },
                            { type: "compare", date, products: compare },
                            { type: "basket", date, products: basket },
                        ],
                    },
                    "post"
                );

                if (request_result?.data?.success) {
                    store.commit(
                        "setBadgeFavourites",
                        request_result?.data?.favourites?.products
                    );
                    store.commit(
                        "setBadgeComparison",
                        request_result?.data?.compare?.products
                    );
                    store.commit(
                        "setComparisonSpecifications",
                        request_result?.data?.compare?.specifications
                    );
                    store.commit(
                        "setBadgeBasket",
                        request_result?.data?.basket?.products
                    );

                    const favourites_cash =
                        request_result?.data?.favourites?.cash;
                    favourites_cash.date = new Date().getTime();
                    localStorage.setItem(
                        "favourites",
                        JSON.stringify(favourites_cash)
                    );

                    const compare_cash = request_result?.data?.compare?.cash;
                    compare_cash.date = new Date().getTime();
                    localStorage.setItem(
                        "compare",
                        JSON.stringify(compare_cash)
                    );

                    const basket_cash = request_result?.data?.basket?.cash;
                    basket_cash.date = new Date().getTime();
                    localStorage.setItem("basket", JSON.stringify(basket_cash));
                }
            }
        }
    }

    async get_products(type) {
        if (store.state.user) {
            if (type == "favourites") {
                const result = await this.send(
                    "methods/products_actions/favourites"
                );

                if (result?.data?.success) {
                    store.commit("setBadgeFavourites", result?.data?.products);
                }

                return result?.data;
            }

            if (type == "compare") {
                const result = await this.send(
                    "methods/products_actions/compare"
                );

                if (result?.data?.success) {
                    store.commit("setBadgeComparison", result?.data?.products);
                }

                return result?.data;
            }

            if (type == "basket") {
                const result = await this.send(
                    "methods/products_actions/basket"
                );

                if (result?.data?.success) {
                    store.commit("setBadgeBasket", result?.data?.products);
                }

                return result?.data;
            }
        } else {
            if (type == "basket") {
                let products = null;
                const products_json = localStorage.getItem("basket");

                if (products_json) {
                    const local_products = JSON.parse(products_json);
                    products = local_products.products;
                }

                if (products) {
                    const request_result = await this.send(
                        "methods/products_actions/basket",
                        { date: new Date().getTime(), products },
                        "post"
                    );

                    if (request_result?.data?.success) {
                        store.commit(
                            "setBadgeBasket",
                            request_result?.data?.products
                        );

                        const cash = request_result?.data?.cash;
                        cash.date = new Date().getTime();
                        localStorage.setItem("basket", JSON.stringify(cash));
                    }

                    return request_result?.data;
                } else {
                    return [];
                }
            }

            if (type == "favourites") {
                let products = null;
                const products_json = localStorage.getItem("favourites");

                if (products_json) {
                    const local_products = JSON.parse(products_json);
                    products = local_products.products;
                }

                if (products) {
                    const result = await this.send(
                        "methods/products_actions/favourites",
                        { date: new Date().getTime(), products },
                        "post"
                    );

                    if (result?.data?.success) {
                        store.commit(
                            "setBadgeFavourites",
                            result?.data?.products
                        );

                        const cash = result?.data?.cash;
                        cash.date = new Date().getTime();
                        localStorage.setItem(
                            "favourites",
                            JSON.stringify(cash)
                        );
                    }

                    return result?.data;
                } else {
                    return [];
                }
            }

            if (type == "compare") {
                let products = null;
                const products_json = localStorage.getItem("compare");

                if (products_json) {
                    const local_products = JSON.parse(products_json);
                    products = local_products.products;
                }

                if (products) {
                    const result = await this.send(
                        "methods/products_actions/compare",
                        { date: new Date().getTime(), products },
                        "post"
                    );

                    if (result?.data?.success) {
                        store.commit(
                            "setBadgeComparison",
                            result?.data?.products
                        );
                        store.commit(
                            "setComparisonSpecifications",
                            result?.data?.specifications
                        );

                        const cash = result?.data?.cash;
                        cash.date = new Date().getTime();
                        localStorage.setItem("compare", JSON.stringify(cash));
                    }

                    return result?.data;
                } else {
                    return [];
                }
            }
        }
    }

    async delete_from(type, data) {
        if (store.state.user) {
            if (type == "favourites") {
                const result = await this.send(
                    "methods/deleteFrom/favourites",
                    data,
                    "post"
                );

                store.commit("setBadgeFavourites", result?.data?.products);

                return result?.data;
            }

            if (type == "compare") {
                const result = await this.send(
                    "methods/deleteFrom/compare",
                    data,
                    "post"
                );

                store.commit("setBadgeComparison", result?.data?.products);

                return result?.data;
            }

            if (type == "basket") {
                let result = {};

                if (data?.products) {
                    result = await this.send(
                        "methods/deleteFrom/basket",
                        { date: data.date, products: data.products },
                        "post"
                    );
                } else {
                    result = await this.send(
                        "methods/deleteFrom/basket",
                        { date: data.date, products: [data.id] },
                        "post"
                    );
                }

                store.commit("setBadgeBasket", result?.data?.products);

                return result?.data;
            }
        } else {
            if (type == "basket") {
                let products = [];

                const items_json = localStorage.getItem("basket");
                if (items_json) {
                    products = JSON.parse(items_json)?.products;
                }

                if (data?.products) {
                    products = products.filter((item) => {
                        return !Boolean(
                            data?.products.find(
                                (product) => product == item.uniq_id
                            )
                        );
                    });
                } else {
                    products = products.filter(
                        (item) => item.uniq_id != data.id
                    );
                }

                const request_result = await this.send(
                    "methods/products_actions/basket",
                    { date: data.date, products },
                    "post"
                );

                if (request_result?.data?.success) {
                    store.commit(
                        "setBadgeBasket",
                        request_result?.data?.products
                    );

                    const cash = request_result?.data?.cash;
                    cash.date = new Date().getTime();
                    localStorage.setItem("basket", JSON.stringify(cash));
                }

                return request_result?.data;
            }

            if (type == "favourites") {
                let favouritesItems =
                    JSON.parse(localStorage.getItem("favourites")) || [];

                const index = favouritesItems.findIndex(
                    (item) => item.id === data.id
                );

                if (index !== -1) {
                    favouritesItems.splice(index, 1);

                    localStorage.setItem(
                        "favourites",
                        JSON.stringify(favouritesItems)
                    );
                    store.commit("setBadgeFavourites", favouritesItems);

                    return console.log({
                        success: true,
                        message: "Товар успешно удален из избранное.",
                    });
                } else {
                    return console.log({
                        success: false,
                        message: "Товар не найден в избранное.",
                    });
                }
            }

            if (type == "compare") {
                let comparisonItems =
                    JSON.parse(localStorage.getItem("comparisonItems")) || [];

                const index = comparisonItems.findIndex(
                    (item) => item.id === data.id
                );

                if (index !== -1) {
                    comparisonItems.splice(index, 1);

                    localStorage.setItem(
                        "comparisonItems",
                        JSON.stringify(comparisonItems)
                    );
                    store.commit("setBadgeComparison", comparisonItems);

                    return console.log({
                        success: true,
                        message: "Товар успешно удален из сравнения.",
                    });
                } else {
                    return console.log({
                        success: false,
                        message: "Товар не найден в сравнении.",
                    });
                }
            }
        }
    }

    async createDraft(data) {
        const result = await this.send("methods/createDraft", data, "post");

        return result?.data;
    }

    async getInfoDraft() {
        const id = JSON.parse(sessionStorage.getItem("draft_id"));
        const result = await this.send("methods/orderBy/" + id);

        return result?.data;
    }

    async searchInCatalog(text, type, pagination = {}) {
        const default_pagination = {
            page: 1,
            posts_per_page: 20,
        };
        pagination = Object.assign(default_pagination, pagination);

        const data = { text: text, type: type, pagination };

        const result = await this.send("methods/doors", data, "post");
        return result?.data;
    }

    async createComment(data) {
        const result = await this.send("methods/createComment", data, "post");

        return result?.data;
    }

    async registrationPostProducts() {
        let favourites = [];
        const favourites_json = localStorage.getItem("favourites");
        if (favourites_json) {
            favourites = JSON.parse(favourites_json)?.products;
        }

        let compare = [];
        const comparison_json = localStorage.getItem("compare");
        if (comparison_json) {
            compare = JSON.parse(comparison_json)?.products;
        }

        let basket = [];
        const basket_json = localStorage.getItem("basket");
        if (basket_json) {
            basket = JSON.parse(basket_json)?.products;
        }

        const date = new Date().getTime();

        const request_result = await this.send(
            "methods/products_actions/all",
            {
                arrays: [
                    { type: "favourites", date, products: favourites },
                    { type: "compare", date, products: compare },
                    { type: "basket", date, products: basket },
                ],
            },
            "post"
        );

        if (request_result?.data?.success) {
            store.commit(
                "setBadgeFavourites",
                request_result?.data?.favourites?.products
            );
            store.commit(
                "setBadgeComparison",
                request_result?.data?.compare?.products
            );
            store.commit(
                "setComparisonSpecifications",
                request_result?.data?.compare?.specifications
            );
            store.commit(
                "setBadgeBasket",
                request_result?.data?.basket?.products
            );

            localStorage.setItem("favourites", "");
            localStorage.setItem("compare", "");
            localStorage.setItem("basket", "");
        }
    }
}

export default new ProductsApi();
