<template>
    <div class="radio-btn color" v-if="color">
        <input
            :id="id"
            type="radio"
            :name="'radio_' + name"
            :value="color"
            :checked="checked"
        />
        <label :for="id">
            <div></div>
            <img :src="color" />
        </label>
    </div>
    <div class="radio-btn" v-else>
        <input
            :id="id"
            type="radio"
            :name="'radio_' + name"
            :value="value"
            :checked="checked"
        />
        <label :for="id">{{ value }}</label>
    </div>
</template>
<script>
export default {
    props: {
        id: "",
        value: "",
        color: "",
        name: "",
        checked: false,
    },
};
</script>
<style>
.radio-btn {
    display: inline-flex;
}
.radio-btn input[type="radio"] {
    display: none;
}
.radio-btn label {
    cursor: pointer;
    padding: 10px;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: center;
    color: var(--black);
    border: 1px solid var(--gray);
    transition: 0.5s;
    border-radius: 5px;
}
.radio-btn.color label {
    cursor: pointer;
    width: 52px;
    height: 52px;
    aspect-ratio: 1 / 1;
    padding: 0;
    border-radius: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}
.radio-btn.color label div {
    position: absolute;
    width: 44px;
    height: 44px;
    background-color: var(--gray);
    border-radius: 5px;
    transition: 0.5s;
}
.radio-btn.color label img {
    position: absolute;
    width: 42px;
    height: 42px;
    aspect-ratio: 1 / 1;
    padding: 0;
    border-radius: 5px;
    z-index: 10;
    transition: 0.5s;
}

/* Checked */
.radio-btn input[type="radio"]:checked + label {
    color: var(--accent);
    border: 1px solid var(--accent);
}

.radio-btn.color input[type="radio"]:checked + label {
    border: none;
}
.radio-btn.color input[type="radio"]:checked + label div {
    width: 52px;
    height: 52px;
    background-color: var(--accent);
    border-radius: 10px;
}

/* Hover */
.radio-btn label:hover {
    color: var(--hover);
    border: 1px solid var(--hover);
}
.radio-btn.color label:hover {
    border: none;
}
.radio-btn.color label:hover div {
    background-color: var(--hover);
}
/* Disabled */
.radio-btn input[type="radio"]:disabled + label {
    background: var(--white);
    color: var(--light_gray);
}

.n-button .n-button__content:has(.radio-btn),
.n-button .n-button__content .radio-btn {
    width: 100%;
}

@media screen and (max-width: 1200px) {
    .radio-btn label {
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
    }
}
</style>
