<template>
    <header>
        <div class="container">
            <div class="header__wrapper desktop">
                <main-menu />
                <catalog-menu />
                <search />
                <nav-bar />
            </div>
            <div class="header__wrapper mobile">
                <mobile-menu @open-search="is_active = true" />
            </div>
            <n-drawer v-model:show="is_active" :width="'100%'" placement="left">
                <n-drawer-content>
                    <div class="mobile-search">
                        <n-auto-complete
                            v-model:value="selectedValues"
                            :options="options"
                            placeholder="Поиск по каталогу"
                            clearable
                            :on-update:value="handleSearch"
                            @keydown.enter="checkEnter"
                            :show-empty="true"
                        >
                        </n-auto-complete>
                        <div
                            class="mobile-search-close"
                            @click="is_active = false"
                        >
                            <ph-x :size="20" />
                        </div>
                    </div>
                </n-drawer-content>
            </n-drawer>
        </div>
    </header>
</template>
<script setup>
import MainMenu from "@/components/UI/header/MainMenu.vue";
import CatalogMenu from "@/components/UI/header/CatalogMenu.vue";
import Search from "@/components/UI/header/Search.vue";
import NavBar from "@/components/UI/header/NavBar.vue";
import MobileMenu from "@/components/UI/header/MobileMenu.vue";
import { NSelect, NDrawer, NDrawerContent, NAutoComplete } from "naive-ui";
import { PhMagnifyingGlass, PhX } from "@phosphor-icons/vue";
import productApi from "@/assets/js/api/products.js";
import siteInfo from "@/assets/js/api/site-info.js";
import { h, onMounted, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();

const is_active = ref(false);

const options = ref([]);
const selectedValues = ref(null);
const search_text = ref("");

let timeout = null;

function handleSearch(value) {
    selectedValues.value = value;
    search_text.value = value;

    if (value == "") {
        selectedValues.value = null;
    }

    clearTimeout(timeout);

    timeout = setTimeout(async () => {
        if (!selectedValues.value) {
            return;
        }

        options.value = [];

        const result = await productApi.searchInCatalog(value, "all");

        if (result?.success) {
            result.doors?.forEach((item, key) => {
                const option = {};
                if (item?.slug) {
                    option.label = () => {
                        return h("a", {
                            href: "/product/" + item.slug,
                            innerHTML: item.title,
                        });
                    };
                } else {
                    option.label = item.title;
                }

                option.key = key;
                options.value.push(option);
            });
        }
    }, 200);
}

function checkEnter() {
    selectedValues.value = null;
    sessionStorage.setItem("search_text", search_text.value);
    window.location.href = "/search_results";
}

onMounted(async () => {
    const site_info = await siteInfo.get();
    store.commit("setSiteInfo", site_info);
});
</script>
<style>
.mobile-search {
    background-color: var(--white);
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    transition: 0.5s;
}

.mobile-search.open {
    left: 0;
}

.mobile-search .n-input .n-input-wrapper {
    padding: 16px;
}

.mobile-search .n-base-clear,
.mobile-search .n-base-icon svg {
    width: 16px;
    height: 16px;
}

header .container {
    position: relative;
    overflow: hidden;
}

.header__wrapper {
    padding: 40px 0px;
    display: grid;
    grid-template-columns: 325px 1fr 520px;
    gap: 40px 30px;
}

.header__wrapper.desktop {
    display: grid;
}

.header__wrapper.mobile {
    display: none;
}

.n-dropdown-menu .n-dropdown-option .n-dropdown-option-body {
    cursor: default;
}

@media screen and (max-width: 1440px) {
    .header__wrapper {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px 15px;
    }
}

@media screen and (max-width: 1200px) {
    .header__wrapper.desktop {
        display: none;
    }

    .header__wrapper.mobile {
        display: flex;
    }

    .header__wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0px;
    }
}
</style>
