<template>
    <section>
        <div class="container product__wrapper">
            <ProductInfo
                :data="props?.data"
                :comments="props?.comments"
                :tabs="props?.tabs"
                :variations="props?.variations"
                :current_slide="current_slide"
            />
            <ProductMain
                :data="props?.data"
                :comments="props?.comments"
                :variations="props?.variations"
                @change_img="change_img"
            />
        </div>
    </section>
</template>
<script setup>
import ProductMain from "@/components/product/ProductMain.vue";
import ProductInfo from "@/components/product/ProductInfo.vue";
import { ref } from "vue";

const props = defineProps({
    data: Object,
    comments: Array,
    tabs: Array,
    variations: Array,
});

const current_slide = ref(null);

function change_img(item) {
    current_slide.value = item;
}
</script>
