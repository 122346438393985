<template>
    <section id="search-results">
        <div class="container catalog__wrapper" v-if="products">
            <h1>Результаты поиска: {{ search_text }}</h1>
            <div class="catalog">
                <div class="catalog-list">
                    <CatalogList :catalog="products" :is_results="true" />
                    <n-pagination
                        v-if="count_pages > 1"
                        class="catalog-pagination"
                        v-model:page="page"
                        :page-count="count_pages"
                        :page-slot="7"
                    />
                </div>
            </div>
        </div>
        <div class="not-found-catalog" v-else>
            <img :src="require('@/assets/img/empty_find.png')" alt="" />
            <div class="h3">Товаров не найдено</div>
        </div>
    </section>
</template>
<script setup>
import CatalogList from "@/components/catalog/CatalogList.vue";
import productApi from "@/assets/js/api/products.js";
import { NPagination } from "naive-ui";
import { ref, watch } from "vue";
import { useStore } from "vuex";

const store = useStore();
store.commit("setBreadcrumb", [
    { name: "Главная", link: "/" },
    { name: "Результаты поиска" },
]);

const page = ref(1);
const count_pages = ref(1);
const count_on_page = ref(1);

const products = ref(null);

const search_text = sessionStorage.getItem("search_text");

async function getDoors() {
    store.commit("setLoading", true);
    const result = await productApi.searchInCatalog(search_text, "all", {
        page: page.value,
    });
    page.value = result?.pagination?.page;
    count_pages.value = result?.pagination?.count_pages;
    count_on_page.value = result?.pagination?.count_on_page;
    products.value = result?.doors;

    if (result) {
        store.commit("setLoading", false);
    }
}
getDoors();

watch(page, async () => {
    await getDoors();
});
</script>
