import { h } from "vue";

export function randomInteger(min, max) {
    const rand = min - 0.5 + Math.random() * (max - min + 1);
    return Math.round(rand);
}

export function getMenuItem(item, key) {
    const option = {};

    if (item?.link) {
        option.label = () => {
            return h("a", {
                href: item.link,
                innerHTML: item.label,
            });
        };
    } else {
        option.label = item.label;
    }

    option.key = key;

    if (item?.children) {
        option.children = [];

        item.children.forEach((sub_item, sub_key) => {
            option.children.push(getMenuItem(sub_item, key + "_" + sub_key));
        });
    }

    return option;
}

// Цифры с разрядами
export function numberWithSpaces(x) {
    if (x) {
        const parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return parts.join(".");
    } else {
        return "";
    }
}

export function validateEmail(email) {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

export function replaceNumberForPaste(value) {
    const r = value.replace(/\D/g, "");
    let val = r;
    if (val.charAt(0) === "7") {
        val = "8" + val.slice(1);
    }

    return replaceNumberForInput(val);
}

function replaceNumberForInput(value) {
    let val = "";
    const x = value
        .replace(/\D/g, "")
        .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
    if (x[1] === "") {
        val = "";
    } else if (!x[2] && x[1] !== "") {
        if (x[1] === "8" || x[1] === "7") {
            val = "+7";
        } else {
            val = "+7" + x[1];
        }
    } else {
        // val = !x[3] ? '+7' + x[2] : '+7 (' + x[2] + ') ' + x[3] + (x[4] ? '-' + x[4] : '') + (x[5] ? '-' + x[5] : '');
        val = !x[3]
            ? "+7" + x[2]
            : "+7 (" +
              x[2] +
              ") " +
              x[3] +
              (x[4] ? " " + x[4] : "") +
              (x[5] ? " " + x[5] : "");
    }

    return val;
}

export function replaceToLatters(value, with_space = false) {
    if (value) {
        if (with_space) {
            value = value
                .replace(/[^a-zA-Zа-яА-Я]+$/g, "")
                .replace(/^\s+/g, "")
                .replace("  ", " ");
        } else {
            value = value.replace(/[^a-zA-Zа-яА-Я]/g, "");
        }

        return value;
    } else {
        return "";
    }
}

export function num_word(value, words) {
    value = Math.abs(value) % 100;
    var value1 = value % 10;
    if (value > 10 && value < 20) {
        return words[2];
    }
    if (value1 > 1 && value1 < 5) {
        return words[1];
    }
    if (value1 == 1) {
        return words[0];
    }
    return words[2];
}

export function YMTargetSend(name) {
    // ?_ym_debug=2
    if (typeof ym == "function") {
        return ym(97228700, "reachGoal", name);
    }
}

export function phoneSymbolsRemove(value) {
    return value.replace(/[- )(]/g, "");
}
