<template>
    <section id="brands">
        <div class="container brands__wrapper">
            <div class="brands-header">
                <h2>{{ props?.brand?.title }}</h2>
                <div class="custom-arrow brands-custom-arrow">
                    <button
                        type="button"
                        class="custom-arrow--left"
                        @click="prev"
                        :class="currentIndex == 0 ? 'disabled' : ''"
                    >
                        <n-icon><ArrowBack /></n-icon>
                    </button>
                    <button
                        type="button"
                        class="custom-arrow--right"
                        @click="next"
                        :class="
                            currentIndex == props?.brand?.imgs.length - 2
                                ? 'disabled'
                                : ''
                        "
                    >
                        <n-icon><ArrowForward /></n-icon>
                    </button>
                </div>
            </div>
            <n-carousel
                class="brands-slider"
                draggable
                :slides-per-view="slides_per_view"
                :show-dots="false"
                :loop="false"
                :space-between="space_between"
                ref="brands_slider"
                :on-update:current-index="checkIndex"
            >
                <img
                    :src="img"
                    class="brands-slider-img"
                    v-for="img in props?.brand?.imgs"
                />
            </n-carousel>
        </div>
    </section>
</template>
<script setup>
import { NCarousel, NIcon } from "naive-ui";
import { ArrowBack, ArrowForward } from "@vicons/ionicons5";
import { ref, onMounted } from "vue";

const props = defineProps({
    brand: Object,
});

const slides_per_view = ref(null);
const space_between = ref(null);

const brands_slider = ref(null);
const currentIndex = ref(0);

function prev() {
    brands_slider.value.prev();
}

function next() {
    brands_slider.value.next();
}

function checkIndex() {
    currentIndex.value = brands_slider.value.realIndex;
}

function checkSize() {
    if (window.innerWidth > 1200) {
        slides_per_view.value = 5;
        space_between.value = 50;
    } else {
        slides_per_view.value = 2;
        space_between.value = 10;
    }
}

onMounted(() => {
    checkSize();
    window.addEventListener("resize", checkSize);
});
</script>
<style>
.brands-slider.n-carousel {
    overflow-x: hidden;
    overflow-y: visible;
}
.brands-slider.n-carousel .n-carousel__slides {
    align-items: stretch;
}
.brands__wrapper {
    display: flex;
    flex-direction: column;
    gap: 60px;
}
.brands-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.brands-slider-img {
    height: 230px;
    width: 100%;
    object-fit: contain;
}

.brands-custom-arrow {
    position: static;
    justify-content: unset;
}

@media screen and (max-width: 1200px) {
    .brands__wrapper {
        gap: 20px;
    }
    .brands-custom-arrow {
        gap: 10px;
    }
}
</style>
