<template>
    <div class="header-search">
        <!-- <n-select
            class="header-search-input"
            v-model:value="selectedValues"
            filterable
            placeholder="Поиск по каталогу"
            :options="options"
            clearable
            remote
            :clear-filter-after-select="true"
            @search="handleSearch"
            :menu-props="{ class: 'search-list' }"
            @keydown.enter="checkEnter"
        >
            <template #arrow><ph-magnifying-glass :size="20" /> </template>
        </n-select> -->
        <n-auto-complete
            v-model:value="selectedValues"
            :options="options"
            placeholder="Поиск по каталогу"
            clearable
            :on-update:value="handleSearch"
            @keydown.enter="checkEnter"
            :show-empty="true"
        >
        </n-auto-complete>
    </div>
</template>

<script setup>
import { NSelect, NAutoComplete } from "naive-ui";
import { h, ref } from "vue";
import { PhMagnifyingGlass } from "@phosphor-icons/vue";
import productApi from "@/assets/js/api/products.js";

const options = ref([]);
const selectedValues = ref(null);
const search_text = ref("");

let timeout = null;

function handleSearch(value) {
    selectedValues.value = value;
    search_text.value = value;

    if (value == "") {
        selectedValues.value = null;
    }

    if (value.length > 2) {
        clearTimeout(timeout);

        timeout = setTimeout(async () => {
            if (!selectedValues.value) {
                return;
            }

            options.value = [];

            const result = await productApi.searchInCatalog(value, "all");

            if (result?.success) {
                result.doors.forEach((item, key) => {
                    const option = {};
                    if (item?.slug) {
                        option.label = () => {
                            return h("a", {
                                href: "/product/" + item.slug,
                                innerHTML: item.title,
                            });
                        };
                    } else {
                        option.label = item.title;
                    }

                    option.key = key;
                    options.value.push(option);
                });
            }
        }, 200);
    }
}

function checkEnter() {
    selectedValues.value = null;
    sessionStorage.setItem("search_text", search_text.value);
    window.location.href = "/search_results";
}
</script>

<style>
.header-search {
    position: relative;
    width: 100%;
}
.header-search .n-auto-complete {
    height: 100%;
}
.header-search .n-auto-complete .n-input .n-input__input-el {
    height: 100%;
}
.header-search .n-auto-complete .n-input .n-input-wrapper {
    padding: 16px;
}
.n-auto-complete-menu .n-base-select-option .n-base-select-option__content {
    width: 100%;
    transition: 0.5s;
    height: 100%;
}

.n-auto-complete-menu .v-vl-visible-items {
    display: flex;
    flex-direction: column;
    gap: 3px;
}
.n-auto-complete-menu .n-base-select-option {
    padding: 5px 20px;
    cursor: pointer;
    position: relative;
}
.n-auto-complete-menu .n-base-select-option a {
    display: block;
    box-sizing: border-box;
    color: var(--black);
    font-size: 14px;
    text-decoration: none;
    width: 100%;
    transition: 0.5s;
}
.n-auto-complete-menu
    .n-base-select-option.n-base-select-option--pending::before {
    background: transparent;
}

.n-auto-complete-menu .n-base-select-option:hover a {
    color: var(--hover);
}
.n-auto-complete-menu .n-base-select-option:hover::before {
    background: var(--light_gray);
}

.n-auto-complete-menu .n-base-select-option:active {
    color: var(--accent);
}
</style>
