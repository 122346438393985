<template>
    <div class="favourites">
        <Favourites :favourites="products" />
    </div>
</template>
<script setup>
import Favourites from "@/components/favourites/FavouritesMain.vue";
import productApi from "@/assets/js/api/products.js";
import userApi from "@/assets/js/api/user.js";
import { ref } from "vue";
import { useStore } from "vuex";

const store = useStore();

store.commit("setBreadcrumb", [
    { name: "Главная", link: "/" },
    { name: "Избранное" },
]);

const products = ref(null);

async function init() {
    store.commit("setLoading", true);
    products.value = store.state.itemsInFavourites;
    setTimeout(() => {
        store.commit("setLoading", false);
    }, 1000);
}
init();
</script>
