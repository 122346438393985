<template>
    <section id="policy">
        <div class="container">
            <h1 class="policy-title">Правовая информация</h1>
            <n-tabs
                v-model:value="active_tab"
                type="line"
                animated
                class="privacy-tabs"
                ref="tabs_ref"
            >
                <n-tab-pane :name="item?.name" :tab="item?.tab" v-for="item in tabs">
                    <div
                        class="policy-tab"
                        v-html="item?.content"
                    ></div>
                </n-tab-pane>
            </n-tabs>
        </div>
    </section>
</template>
<script setup>
import { NTabs, NTabPane } from "naive-ui";
import { nextTick, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import pagesApi from "@/assets/js/api/pages.js";

const tabs = ref([]);
const tabs_ref = ref(null);

const store = useStore();

store.commit("setBreadcrumb", [
    { name: "Главная", link: "/" },
    { name: "Правовая информация" },
]);

const active_tab = ref(useRouter().currentRoute.value.params.slug);

onMounted(async () => {
    store.commit("setLoading", true);
    const result_privacy = await pagesApi.getPageData("policy/privacy");
    const result_personal = await pagesApi.getPageData("policy/personal");

    tabs.value.push({
        tab: result_privacy.title,
        name: 'privacy',
        content: result_privacy.content,
    });
    
    tabs.value.push({
        tab: result_personal.title,
        name: 'personal',
        content: result_personal.content,
    });

    if (result_privacy && result_personal) {
        store.commit("setLoading", false);
    }

    await nextTick();
    
    tabs_ref.value.syncBarPosition();
});

</script>
<style>
#policy {
    width: 100%;
}

#policy .policy-title {
    margin-bottom: 60px;
}

#policy .n-tabs-tab__label {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 4.5%;
}

.privacy-tabs .n-tab-pane p {
    max-width: 876px;
}
.privacy-tabs.n-tabs.n-tabs--top .n-tab-pane {
    padding-top: 60px;
}
.privacy-tabs {
    min-width: auto;
}

.policy-tab h3 {
    margin-bottom: 20px;
}

.policy-tab h3:not(:first-of-type) {
    margin-top: 60px;
}
@media screen and (max-width: 1200px) {
    .privacy-tabs.n-tabs .n-tabs-wrapper {
        flex-direction: column;
        align-items: center;
    }
    .privacy-tabs.n-tabs
        .n-tabs-nav.n-tabs-nav--line-type.n-tabs-nav--top
        .n-tabs-bar {
        display: none;
    }
    .privacy-tabs.n-tabs .n-tabs-nav-scroll-content {
        justify-content: center;
    }
    .privacy-tabs.n-tabs.n-tabs--line-type .n-tabs-tab.n-tabs-tab--active {
        border-bottom: 1px solid var(--accent);
    }
    .privacy-tabs.n-tabs.n-tabs--line-type .n-tabs-tab {
        border-bottom: 1px solid transparent;
    }
    #policy .n-tabs-tab__label {
        white-space: break-spaces;
        text-align: center;
    }

    .policy-tab h3 {
        margin-bottom: 10px;
    }

    .policy-tab h3:not(:first-of-type) {
        margin-top: 20px;
    }
}
</style>
