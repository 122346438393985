<template>
    <div class="company">
        <Main :company="page_data?.data?.company" />
        <Advantages :advantages="page_data?.data?.advantages" />
        <Brands :brand="page_data?.data?.brand" />
        <ReviewSection :reviews="page_data?.data?.reviews" />
        <OrderSection />
    </div>
</template>

<script setup>
import Main from "@/components/company/Main.vue";
import Advantages from "@/components/company/Advantages.vue";
import Brands from "@/components/company/Brands.vue";
import ReviewSection from "@/components/UI/ReviewSection.vue";
import OrderSection from "@/components/UI/OrderSection.vue";
import { onMounted, onUpdated, ref } from "vue";
import { useStore } from "vuex";
import pagesApi from "@/assets/js/api/pages.js";

const store = useStore();

store.commit("setBreadcrumb", [
    { name: "Главная", link: "/" },
    { name: "Компания" },
]);
const page_data = ref(null);

onMounted(async () => {
    store.commit("setLoading", true);
    const result = await pagesApi.getPageData("company");
    page_data.value = result;

    if (result) {
        store.commit("setLoading", false);
    }
});
</script>
<style>
.company {
    margin-top: -98px;
}
body:has(.company)
    .breadcrumb
    .n-breadcrumb
    .n-breadcrumb-item
    .n-breadcrumb-item__link,
body:has(.company)
    .breadcrumb
    .n-breadcrumb
    .n-breadcrumb-item
    .n-breadcrumb-item__separator {
    color: var(--dark_gray);
}
body:has(.company)
    .breadcrumb
    .n-breadcrumb
    .n-breadcrumb-item
    .n-breadcrumb-item__link:hover {
    color: var(--white);
}
body:has(.company)
    .breadcrumb
    .n-breadcrumb
    .n-breadcrumb-item:last-child
    .n-breadcrumb-item__link {
    color: var(--white);
}

@media screen and (max-width: 1200px) {
    .company {
        margin-top: -58px;
    }
}
</style>
