<template>
    <div class="map" ref="map"></div>
</template>
<script setup>
import { onMounted, nextTick, ref, watch } from "vue";
import { useStore } from "vuex";

const store = useStore();
const map = ref(null);
let   is_init = false;

watch(store.state, (new_value) => {
    if (new_value?.site_info) {
        initMap(new_value?.site_info?.contacts?.map);
    }
});

async function initMap(map_src) {
    if (!is_init) {
        const map_contacts = document.createElement("script");
        map_contacts.setAttribute("src", map_src);
        await nextTick();
        map.value.appendChild(map_contacts);
        is_init = true;
    }
}
</script>
<style>
.map {
    height: 550px;
    width: 100%;
    margin-bottom: 150px;
}

@media screen and (max-width: 1200px) {
    .map {
        height: 350px;
        margin-bottom: 80px;
    }
}
</style>
