import Api from "./index.js";

class OrderApi extends Api {
    async createOrder(data) {
        const result = await this.send("methods/createOrder", data, "post");

        return result?.data;
    }

    async checkPromo(data) {
        const result = await this.send("methods/promoCheck", data, "post");

        return result?.data;
    }

    async createRequest(data) {
        const result = await this.send("methods/createRequest", data, "post");

        return result?.data;
    }

    async priceAsk(data) {
        const result = await this.send("methods/priceAsk", data, "post");

        return result?.data;
    }

    async fortune() {
        const result = await this.send("methods/fortune");

        return result?.data;
    }
}
export default new OrderApi();
