<template>
    <n-drawer v-model:show="show" :width="300" placement="left">
        <n-drawer-content>
            <div class="drawer-title">
                <span> Фильтр товаров </span>
                <div class="drawer-title-close" @click="show = false">
                    <ph-x :size="16" color="var(--accent)" />
                </div>
            </div>
            <div class="drawer-body">
                <n-scrollbar style="height: calc(100% - 129px)" trigger="none">
                    <div class="drawer-body-list">
                        <template v-for="(item, key) in filters">
                            <div
                                class="drawer-body-list-item"
                                @click="openFilter(key)"
                                v-if="item.name == 'price' || item?.children"
                            >
                                {{ item.label }}
                                <ph-caret-right
                                    :size="16"
                                    color="var(--dark-gray)"
                                />
                            </div>
                            <div class="drawer-body-list-item" v-else>
                                <n-checkbox-group
                                    v-model:value="applied_filters.others"
                                >
                                    <div class="filters-list">
                                        <n-checkbox
                                            :value="String(item.name)"
                                            :label="item.label"
                                        />
                                    </div>
                                </n-checkbox-group>
                            </div>
                        </template>
                    </div>
                </n-scrollbar>
                <div class="drawer-footer">
                    <button class="dark-btn" @click="submit">Применить</button>
                    <button @click="clearAll">Сбросить</button>
                </div>
            </div>
        </n-drawer-content>
    </n-drawer>
    <n-drawer
        v-model:show="show_values"
        :width="300"
        placement="left"
        :show-mask="false"
    >
        <n-drawer-content>
            <div class="drawer-title">
                <div class="drawer-title-back" @click="show_values = false">
                    <ph-caret-left :size="16" color="var(--accent)" />
                </div>
                <span>
                    {{ active_filter.label }}
                </span>
                <template v-if="active_filter.name != 'price'">
                    <div
                        class="drawer-title-btn"
                        v-if="is_active_empty"
                        @click="setAll"
                    >
                        Выбрать все
                    </div>
                    <div class="drawer-title-btn" v-else @click="clearActive">
                        Сбросить
                    </div>
                </template>
            </div>
            <div class="drawer-body">
                <n-scrollbar style="height: calc(100% - 129px)" trigger="none">
                    <template v-if="active_filter.name == 'price'">
                        <n-input
                            class="catalog-filter-pair"
                            pair
                            separator="-"
                            :placeholder="['от', 'до']"
                            :allow-input="onlyAllowNumber"
                            v-model:value="applied_filters['price']"
                            @update:value="updatePrice"
                        />
                        <n-slider
                            v-model:value="applied_filters['price']"
                            range
                            :min="active_filter.min"
                            :max="active_filter.max"
                            @update:value="updatePrice"
                        />
                    </template>
                    <template v-else>
                        <n-checkbox-group
                            v-model:value="applied_filters[active_filter.name]"
                        >
                            <div class="filters-list">
                                <n-checkbox
                                    :value="String(item.value)"
                                    :label="item.label"
                                    v-for="(
                                        item, key
                                    ) in active_filter.children"
                                    :key="key"
                                />
                            </div>
                        </n-checkbox-group>
                    </template>
                </n-scrollbar>
                <div class="drawer-footer">
                    <button class="dark-btn" @click="submit">Применить</button>
                    <button @click="clearAll">Сбросить</button>
                </div>
            </div>
        </n-drawer-content>
    </n-drawer>
</template>
<script setup>
import { PhCaretLeft, PhCaretRight, PhX } from "@phosphor-icons/vue";
import {
    NDrawer,
    NDrawerContent,
    NScrollbar,
    NInput,
    NSlider,
    NCheckboxGroup,
    NCheckbox,
} from "naive-ui";
import { ref, defineExpose, computed, reactive, watch, defineEmits } from "vue";

const props = defineProps({
    filters: Array,
    default_filters: Object,
    loading: Boolean,
});

const applied_filters = reactive({});

const emit = defineEmits(["update-filters"]);

function submit() {
    emit("update-filters", applied_filters);
    show.value = false;
    show_values.value = false;
}

const show = ref(false);
const show_values = ref(false);
const active_filter = ref({});

function open() {
    show.value = true;
    if (props.default_filters?.others) {
        applied_filters.others = props.default_filters.others;
    }
}

function openFilter(key) {
    show_values.value = true;
    active_filter.value = props.filters[key];

    if (!applied_filters[props.filters[key].name]) {
        if (props.filters[key].name == "price") {
            if (props.default_filters[props.filters[key].name]) {
                applied_filters[props.filters[key].name] = Array.isArray(
                    props.default_filters[props.filters[key].name]
                )
                    ? props.default_filters[props.filters[key].name]
                    : [props.default_filters[props.filters[key].name]];
            } else {
                applied_filters[props.filters[key].name] = [
                    props.filters[key].min,
                    props.filters[key].max,
                ];
            }
        } else {
            if (props.default_filters[props.filters[key].name]) {
                applied_filters[props.filters[key].name] = Array.isArray(
                    props.default_filters[props.filters[key].name]
                )
                    ? props.default_filters[props.filters[key].name]
                    : [props.default_filters[props.filters[key].name]];
            } else {
                applied_filters[props.filters[key].name] = [];
            }
        }
    }
}

function onlyAllowNumber(value) {
    return !value || /^\d+$/.test(value);
}

const is_active_empty = computed(() => {
    const key = active_filter.value.name;

    if (applied_filters[key]?.length == 0) {
        return true;
    }

    return false;
});

function setAll() {
    const key = active_filter.value.name;

    active_filter.value.children.forEach((item) => {
        applied_filters[key].push(item.value);
    });
}

function clearActive() {
    const key = active_filter.value.name;
    applied_filters[key] = [];
}

function clearAll() {
    for (let key in applied_filters) {
        if (key == "price") {
            const price_template = props.filters.find(
                (filter) => filter.name == "price"
            );
            applied_filters[key] = [price_template.min, price_template.max];
        } else {
            applied_filters[key] = [];
        }
    }

    submit();
}

function updatePrice(value) {
    const price_template = props.filters.find(
        (filter) => filter.name == "price"
    );

    if (value[0] > value[1] || value[1] < value[0]) {
        value[1] = value[0];
    }

    if (value[0] < price_template.min) {
        value = [price_template.min, price_template.min];
    }

    if (value[1] > price_template.max) {
        value = [price_template.max, price_template.max];
    }

    applied_filters.price = value;
}

defineExpose({ open });
</script>

<style>
.drawer-title-btn {
    font-family: Manrope;
    font-size: 10px;
    font-weight: 400;
    line-height: 13.66px;
    letter-spacing: 0.045em;
    text-align: left;
    color: var(--dark_gray);
    white-space: nowrap;
}

.drawer-footer {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-top: 40px;
}

.drawer-footer button {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.07px;
    letter-spacing: 0.045em;
    text-align: center;
    border: none;
    outline: none;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--gray);
    background-color: #fff;
    color: var(--black);
    transition: 0.5s;
}

.drawer-footer button:active {
    background-color: var(--gray);
    border: 1px solid var(--dark_gray);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.drawer-footer button.dark-btn {
    background-color: var(--black);
    border: 1px solid var(--black);
    color: #fff;
}

.drawer-footer button.dark-btn:active {
    background-color: var(--dark_gray);
    border: 1px solid var(--black);
}

@media screen and (max-width: 1200px) {
    .catalog-filter-pair.n-input {
        margin-bottom: 20px;
    }

    .catalog-filter-pair.n-input .n-input__input-el {
        padding: 5px 10px;
        font-size: 12px;
        line-height: 18px;
    }

    .filters-list {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }

    .filters-list .n-checkbox {
        min-height: 20px;
        display: flex;
        align-items: center;
    }
    .filters-list .n-checkbox .n-checkbox-box-wrapper {
        margin-top: 0px !important;
    }

    .filters-list .n-checkbox .n-checkbox__label {
        font-size: 14px;
    }

    .n-slider .n-slider-handles .n-slider-handle-wrapper .n-slider-handle {
        height: 10px;
        width: 10px;
    }

    .n-slider .n-slider-handles {
        right: calc(10px / 2);
        left: calc(10px / 2);
    }
}
</style>
