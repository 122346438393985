<template>
    <section id="not-found" class="first">
        <div
            class="not-found-bg"
            :style="
                'background-image: linear-gradient(48.37deg, #1C1C1C -88.63%, rgba(0, 0, 0, 0) 161.56%), url(' +
                bg +
                ')'
            "
        >
            <div class="container not-found__wrapper">
                <h1>
                    404
                    <h5>Извините, нам не удалось найти эту страницу</h5>
                </h1>
                <Button type="white" link="/"> На главную </Button>
            </div>
        </div>
    </section>
</template>

<script setup>
import { useStore } from "vuex";
import Button from "@/components/UI/Button.vue";

const bg = require("@/assets/img/404.jpg");

const store = useStore();
store.commit("setBreadcrumb", null);

store.commit("setLoading", true);
setTimeout(() => {
    store.commit("setLoading", false);
}, 1500);
</script>

<style scoped>
#not-found {
    margin-bottom: 0;
    height: 70vh;
}
.not-found-bg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}
.not-found__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    height: 100%;
}
.not-found__wrapper h1 {
    font-family: Montserrat;
    font-size: 100px;
    font-weight: 600;
    line-height: 122px;
    letter-spacing: 0.045em;
    text-align: center;
    color: var(--white);
}
.not-found__wrapper h5 {
    color: var(--white);
}
.not-found__wrapper button {
    margin-top: 10px;
    width: max-content;
}
@media screen and (max-width: 800px) {
    .not-found__wrapper h1 {
        font-size: 60px;
        line-height: 73.14px;
    }
    .not-found__wrapper h5 {
        font-size: 14px;
        line-height: 17.07px;
    }
    .not-found__wrapper {
        gap: 40px;
    }
    .not-found__wrapper button {
        width: 100%;
    }
}
</style>
