<template>
    <MainDelivery :delivery="page_data?.data?.delivery" />
    <OrderSection />
</template>
<script setup>
import OrderSection from "@/components/UI/OrderSection.vue";
import MainDelivery from "@/components/delivery/MainDelivery.vue";
import { useStore } from "vuex";
import pagesApi from "@/assets/js/api/pages.js";
import { onMounted, ref } from "vue";

const store = useStore();

store.commit("setBreadcrumb", [
    { name: "Главная", link: "/" },
    { name: "Услуги" },
    { name: "Доставка" },
]);

const page_data = ref(null);

onMounted(async () => {
    store.commit("setLoading", true);
    const result = await pagesApi.getPageData("page/delivery");
    page_data.value = result;

    if (result) {
        store.commit("setLoading", false);
    }
});
</script>
<style>
.delivery-container h1 {
    margin-bottom: 60px;
}
.delivery-container .sub-title {
    margin-bottom: 20px;
}
@media screen and (max-width: 800px) {
    .delivery-container h1 {
        margin-bottom: 20px;
    }
}
</style>
