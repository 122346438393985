<template>
    <div class="header__wrapper-left">
        <div
            class="header-navbar-item"
            :on-update:show="
                (show) => {
                    if (!show) closeAll();
                }
            "
            @click="showDrawer()"
        >
            <ph-list :size="20" />
        </div>
        <div class="header-navbar-item" @click="emit('open-search')">
            <ph-magnifying-glass :size="20" />
        </div>
        <a
            class="header-navbar-item"
            :href="'tel:' + phone"
            @click="YMTargetSend('Nomer')"
        >
            <ph-phone :size="20" />
        </a>
    </div>
    <a href="/" class="header-logo">
        <img :src="store.state?.site_info?.header?.logo" alt="" />
    </a>
    <div class="header__wrapper-right">
        <a class="header-navbar-item" href="/favourites">
            <n-badge :value="store.state.itemsInFavourites?.length" :max="9">
                <ph-heart-straight :size="20" />
            </n-badge>
        </a>
        <a class="header-navbar-item" href="/basket">
            <n-badge :value="store.state.itemsInBasket?.length" :max="9">
                <ph-shopping-cart-simple :size="20" />
            </n-badge>
        </a>
        <div class="header-navbar-item" @click="profile_show = true">
            <ph-user :size="20" />
        </div>
    </div>

    <n-drawer
        v-model:show="drawers[key].show"
        :width="300"
        placement="left"
        v-for="(drawer, key) in drawers"
        :key="key"
        :show-mask="key == 0"
    >
        <n-drawer-content>
            <div class="drawer-title">
                <div
                    class="drawer-title-back"
                    v-if="key > 0"
                    @click="close(key)"
                >
                    <ph-caret-left :size="16" color="var(--accent)" />
                </div>
                <span>
                    {{
                        key == 0
                            ? "Меню"
                            : drawers[key - 1].items[active[key]].label
                    }}
                </span>
                <div class="drawer-title-close" @click="closeAll">
                    <ph-x :size="16" color="var(--accent)" />
                </div>
            </div>
            <div class="drawer-body">
                <n-scrollbar>
                    <div class="drawer-body-list">
                        <template v-for="(item, item_key) in drawer.items">
                            <template
                                v-if="key == 0 || item.parent == active[key]"
                            >
                                <div
                                    class="drawer-body-list-item"
                                    v-if="item?.has_child"
                                    @click="
                                        () => {
                                            if (!item?.link) {
                                                (drawers[key + 1].show = true),
                                                    (active[key + 1] =
                                                        item_key);
                                            }
                                        }
                                    "
                                >
                                    <a v-if="item?.link" :href="item?.link">
                                        {{ item.label }}
                                    </a>
                                    <template v-else>
                                        {{ item.label }}
                                    </template>

                                    <ph-caret-right
                                        :size="16"
                                        color="var(--dark-gray)"
                                        @click="
                                            () => {
                                                (drawers[key + 1].show = true),
                                                    (active[key + 1] =
                                                        item_key);
                                            }
                                        "
                                    />
                                </div>
                                <div class="drawer-body-list-item" v-else>
                                    <a :href="item?.link">{{ item.label }}</a>
                                </div>
                            </template>
                        </template>
                    </div>
                </n-scrollbar>
            </div>
        </n-drawer-content>
    </n-drawer>

    <n-drawer v-model:show="profile_show" :width="300" placement="left">
        <n-drawer-content>
            <div class="drawer-title">
                <span> Профиль </span>
                <div class="drawer-title-close" @click="profile_show = false">
                    <ph-x :size="16" color="var(--accent)" />
                </div>
            </div>
            <div class="drawer-body">
                <profile-card />
            </div>
        </n-drawer-content>
    </n-drawer>
</template>

<script setup>
import {
    PhList,
    PhMagnifyingGlass,
    PhPhone,
    PhShoppingCartSimple,
    PhHeartStraight,
    PhUser,
    PhCaretLeft,
    PhCaretRight,
    PhX,
} from "@phosphor-icons/vue";
import { NBadge, NDrawer, NDrawerContent, NScrollbar } from "naive-ui";
import { ref, defineEmits, watch, computed } from "vue";
import { useStore } from "vuex";
import ProfileCard from "@/components/UI/header/ProfileCard.vue";
import { YMTargetSend, phoneSymbolsRemove } from "@/assets/js/helper";

const store = useStore();
let menu = [];
let catalog_menu = [];
const all_menu = ref([]);
const drawers = ref([]);

watch(
    () => store.state.site_info,
    (new_value) => {
        if (new_value) {
            drawers.value = [];
            menu = new_value?.header?.menu;
            catalog_menu = new_value?.header?.catalog;

            if (menu?.length > 0) {
                all_menu.value = menu.concat(catalog_menu);

                rec(all_menu.value, 0);
            }
        }
    }
);

function rec(array, lvl, parent) {
    array.forEach((item) => {
        if (!drawers.value[lvl]) {
            drawers.value[lvl] = { show: false, items: [] };
        }

        drawers.value[lvl].items.push({
            label: item.label,
            link: item?.link,
            parent,
            has_child: item?.children ? true : false,
        });

        const index = drawers.value[lvl].items.length - 1;

        if (item?.children) {
            rec(item?.children, lvl + 1, index);
        }
    });
}

const active = ref([0]);

function close(index) {
    drawers.value[index].show = false;
}

function closeAll() {
    drawers.value.forEach((drawer) => {
        drawer.show = false;
    });
}

const profile_show = ref(false);

function showDrawer() {
    drawers.value[0].show = true;
}

const emit = defineEmits(["open-search"]);

const phone = computed(() => {
    if (store.state?.site_info?.contacts?.phone) {
        return phoneSymbolsRemove(store.state?.site_info?.contacts?.phone);
    }
});
</script>

<style>
.drawer-title {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.07px;
    letter-spacing: 0.045em;
    text-align: left;
    color: var(--accent);
    display: flex;
    column-gap: 10px;
    padding: 20px 0;
}

.drawer-title span {
    width: 100%;
}

.drawer-body {
    height: calc(100dvh - 100px);
}

.drawer-body-list {
    padding-right: 20px;
}

.drawer-body-list-item {
    font-family: Manrope;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.045em;
    text-align: left;
    padding: 10px 0;
    color: var(--black);
    display: flex;
    column-gap: 10px;
    justify-content: space-between;
    outline: none;
}

.drawer-body-list-item a {
    text-decoration: none;
    outline: none;
    color: var(--black);
}

@media screen and (max-width: 1200px) {
    .header__wrapper-left,
    .header__wrapper-right {
        width: max-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }
}
</style>
