<template>
    <div class="order-popup" :class="store.state.order_popup ? 'open' : ''">
        <div class="order-popup-bg" @click="closePopup"></div>
        <div class="order-popup-container">
            <svg
                class="order-popup-close"
                @click="closePopup"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6 17L17 6"
                    stroke="#6D6C6C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
                <path
                    d="M6 6L17 17"
                    stroke="#6D6C6C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
            </svg>
            <div class="order-form__wrapper">
                <h2>Оставить заявку</h2>
                <n-scrollbar style="max-height: 50vh">
                    <n-form
                        class="order-form"
                        ref="formRef"
                        :model="form_data"
                        :rules="rules"
                        :show-label="false"
                    >
                        <n-form-item path="name">
                            <n-input
                                v-model:value="form_data.name"
                                placeholder="Имя *"
                            />
                        </n-form-item>
                        <n-form-item path="phone">
                            <n-input
                                v-model:value="form_data.phone"
                                placeholder="Телефон *"
                            />
                        </n-form-item>
                        <n-form-item path="message">
                            <n-input
                                rows="10"
                                type="textarea"
                                v-model:value="form_data.message"
                                placeholder="Сообщение"
                            />
                        </n-form-item>
                        <n-form-item>
                            <n-checkbox v-model:checked="form_data.confirm">
                                я даю согласие на обработку персональных данных
                            </n-checkbox>
                            <div class="autho-form-desc">
                                Нажимая кнопку зарегистрироваться подтверждаю,
                                что я ознакомлен и согласен с
                                <a href="/policy/personal">условиями оферты</a>
                                и
                                <a href="/policy/privacy">
                                    политики конфиденциальности </a
                                >.
                            </div>
                        </n-form-item>
                        <Button @click="create" class="order-btn">
                            Оставить заявку
                        </Button>
                    </n-form>
                </n-scrollbar>
            </div>
        </div>
    </div>
</template>
<script setup>
import {
    NForm,
    NFormItem,
    NInput,
    NCheckbox,
    NAlert,
    useMessage,
    NScrollbar,
} from "naive-ui";
import { h, ref } from "vue";
import { useStore } from "vuex";
import { replaceNumberForPaste } from "@/assets/js/helper";
import Button from "./Button.vue";
import orderApi from "@/assets/js/api/order.js";

const message_error = useMessage();
const store = useStore();

function closePopup() {
    store.commit("orderPopupOpen", false);
}

const formRef = ref(null);
const form_data = ref({
    name: "",
    phone: "",
    message: "",
    confirm: false,
});

const rules = {
    name: {
        required: true,
        message: "Обязательное поле",
        trigger: ["input", "blur"],
    },
    phone: {
        required: true,
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (!value) {
                return new Error("Обязательное поле");
            } else {
                form_data.value.phone = replaceNumberForPaste(value);
                if (value.length < 18) {
                    return new Error("Введите существующий телефон");
                }
            }
        },
    },
};

function resetForm() {
    form_data.value.name = "";
    form_data.value.phone = "";
    form_data.value.message = "";
    form_data.value.confirm = false;
}

async function create() {
    const data = {
        name: form_data.value?.name,
        phone: form_data.value?.phone,
        message: form_data.value?.message,
    };

    await formRef.value.validate(async (errors) => {
        if (!errors) {
            if (!form_data.value.confirm) {
                message_error.error("Подтвердите согласие, нажмите галочку", {
                    render: (props) => {
                        return h(
                            NAlert,
                            {
                                closable: props.closable,
                                onClose: props.onClose,
                                type: "error",
                                style: {
                                    boxShadow: "var(--n-box-shadow)",
                                    maxWidth: "calc(100vw - 32px)",
                                    padding: "14px",
                                    width: "480px",
                                },
                            },
                            {
                                default: () =>
                                    h("div", { innerHTML: props.content }),
                            }
                        );
                    },
                    closable: true,
                    duration: 5000,
                });
                return;
            }

            const result = await orderApi.createRequest(data);
            if (result?.success) {
                resetForm();
                store.commit("orderPopupOpen", false);
                store.commit("successPopupOpen", true);
                YMTargetSend("zayavka");
            } else {
                message_error.error("Ошибка сервера", {
                    render: (props) => {
                        return h(
                            NAlert,
                            {
                                closable: props.closable,
                                onClose: props.onClose,
                                type: "error",
                                style: {
                                    boxShadow: "var(--n-box-shadow)",
                                    maxWidth: "calc(100vw - 32px)",
                                    padding: "14px",
                                    width: "480px",
                                },
                            },
                            {
                                default: () =>
                                    h("div", { innerHTML: props.content }),
                            }
                        );
                    },
                    closable: true,
                    duration: 5000,
                });
                return;
            }
        }
    });
}
</script>
<style>
.order-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    z-index: 100;
}

.order-popup.open {
    opacity: 1;
    pointer-events: all;
}

.order-popup-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.order-popup-container {
    max-height: 90vh;
    max-width: 680px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 60px;
    background-color: white;
    border-radius: 5px;
    padding: 80px;
}
.order-popup-container .order-form__wrapper {
    padding: 0;
    width: 100%;
}

.order-popup-close {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
    z-index: 10;
    transition: 0.5s;
}
.order-popup-close path {
    transition: 0.5s;
}
.order-popup-close:hover path {
    stroke: black;
}

.order-form {
    padding-right: 20px;
}
.order-form.order-form .n-checkbox {
    margin: 0;
}
.order-form.order-form .n-checkbox.n-checkbox--show-label {
    line-height: var(--n-size);
}
.order-form.order-form .n-form-item .n-form-item-feedback-wrapper {
    top: calc(100% + 2px);
}
.order-popup-message {
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.order-form-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
@media screen and (max-width: 1200px) {
    .order-popup-close {
        top: 20px;
        right: 20px;
    }

    .order-popup-container {
        padding: 20px;
        gap: 40px;
    }

    .order-popup-container .custom-btn {
        padding: 10px;
    }
}
</style>
