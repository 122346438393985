import Api from "./index.js";
import store from "@/store/index.js";

class UserApi extends Api {
    setUser(data, token) {
        if (token) {
            localStorage.setItem("token", token);
        }
        sessionStorage.setItem("user", JSON.stringify(data));
        let session = sessionStorage.getItem("user");
        store.commit("setUser", JSON.parse(session));
    }

    async getUser() {
        const result = await this.send("methods/user");

        if (result?.data?.success) {
            this.setUser(result?.data?.userdata);
        }
        return result?.data;
    }

    async login(data) {
        if (data.login == "" || data.password == "") {
            return { success: false, message: "Пустые логин/пароль" };
        }

        const result = await this.send("jwt-auth/v1/token", data, "post");

        if (result?.data?.success) {
            this.setUser(result?.data?.userdata, result?.data?.token);
        }

        return result?.data;
    }

    async signup(data) {
        if (data.login == "" || data.password == "" || data.name == "") {
            return { success: false, message: "Пустые поля" };
        }

        const result = await this.send("methods/register", data, "post");

        if (result?.data?.success) {
            this.setUser(result?.data?.userdata, result?.data?.token);
        }

        return result?.data;
    }

    async reset_password(data) {
        if (data == "") {
            return { success: false, message: "Пустое поле" };
        }

        const result = await this.send("methods/password_reset ", data, "post");

        // if (result?.data?.success) {
        //     this.setUser(
        //         result?.data?.user?.auth?.userdata,
        //         result?.data?.user?.auth?.token
        //     );
        // }

        return result;
        // return result?.data;
    }

    async logout() {
        localStorage.setItem("token", "");
        sessionStorage.setItem("user", "");
        store.commit("setUser", null);
    }

    async set_avatar(file) {
        const result = await this.send("methods/avatar", file, "post");

        return result;
    }

    async orderHistory(data) {
        const result = await this.send("methods/orderHistory", data, "post");

        return result?.data;
    }

    async userUpdate(data) {
        const result = await this.send("methods/user_update", data, "post");

        return result?.data;
    }

    async getSettings(type) {
        const result = await this.send("methods/getSettings/?type=" + type);

        return result?.data;
    }
}
export default new UserApi();
