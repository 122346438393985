<template>
    <div class="authorization">
        <section id="autho">
            <div class="autho__wrapper">
                <div class="autho-img">
                    <img :src="require('@/assets/img/half_section.png')" />
                </div>
                <div class="autho-content">
                    <n-form
                        class="autho-form"
                        ref="formRef"
                        :label-width="80"
                        :model="form_data"
                        :rules="rules"
                        :show-label="false"
                    >
                        <h1>Авторизация</h1>
                        <n-form-item path="email">
                            <n-input
                                v-model:value="form_data.email"
                                placeholder="Email *"
                            >
                            </n-input>
                        </n-form-item>
                        <n-form-item path="password" class="autho-forgot">
                            <n-input
                                show-password-on="click"
                                type="password"
                                v-model:value="form_data.password"
                                placeholder="Пароль *"
                            >
                            </n-input>
                            <a href="/password_reset">Забыли пароль?</a>
                        </n-form-item>
                        <n-form-item class="autho-confirm">
                            <n-checkbox v-model:checked="form_data.confirm">
                                я даю согласие на обработку персональных данных
                            </n-checkbox>
                            <div class="autho-form-desc">
                                Нажимая кнопку зарегистрироваться подтверждаю,
                                что я ознакомлен и согласен с
                                <a href="/policy/personal">условиями оферты</a>
                                и
                                <a href="/policy/privacy">
                                    политики конфиденциальности </a
                                >.
                            </div>
                        </n-form-item>
                        <div class="autho-form-btns">
                            <Button @click="authorization"> Войти </Button>
                        </div>
                    </n-form>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>
import {
    NForm,
    NFormItem,
    NInput,
    NCheckbox,
    useMessage,
    NAlert,
} from "naive-ui";
import { h, ref } from "vue";
import Button from "@/components/UI/Button.vue";
import userApi from "@/assets/js/api/user.js";
import { validateEmail } from "@/assets/js/helper.js";
import { useStore } from "vuex";

const store = useStore();

store.commit("setBreadcrumb", [
    { name: "Главная", link: "/" },
    { name: "Войти" },
]);

const message_error = useMessage();

const pattern = /[а-яА-ЯЁёa-zA-Z@ ]*$/g;

const form_data = ref({
    email: "",
    password: "",
    confirm: false,
});

const rules = {
    email: {
        required: true,
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (!value) {
                return new Error("Обязательное поле");
            }
            if (value.charAt().match(pattern) != null) {
                if (!validateEmail(value)) {
                    return new Error("Введите существующую почту");
                }
            }
        },
    },
    password: {
        required: true,
        message: "Обязательное поле",
        trigger: ["input", "blur"],
    },
};

function resetForm() {
    form_data.value.email = "";
    form_data.value.password = "";
    form_data.value.confirm = false;
}

async function authorization() {
    const data = {
        username: form_data.value.email,
        password: form_data.value.password,
    };

    if (!form_data.value.confirm) {
        message_error.error("Подтвердите согласие, нажмите галочку", {
            render: (props) => {
                return h(
                    NAlert,
                    {
                        closable: props.closable,
                        onClose: props.onClose,
                        type: "error",
                        style: {
                            boxShadow: "var(--n-box-shadow)",
                            maxWidth: "calc(100vw - 32px)",
                            padding: "14px",
                            width: "480px",
                        },
                    },
                    {
                        default: () => h("div", { innerHTML: props.content }),
                    }
                );
            },
            closable: true,
            duration: 5000,
        });
        return;
    }

    const result = await userApi.login(data);

    if (result?.token) {
        resetForm();
        window.location.href = "/";
    } else {
        let message = "";
        if (
            result.message ==
            "Неизвестный адрес email. Перепроверьте или попробуйте ваше имя пользователя."
        ) {
            message = "Неизвестный адрес email.";
        } else if (
            result.message ==
            `<strong>Ошибка</strong>: Введённый вами пароль для адреса <strong>${form_data.value.email}</strong> неверен. <a href="https://slavyanskie-dveri.ru/wp-login.php?action=lostpassword">Забыли пароль?</a>`
        ) {
            message = "Неверный пароль.";
        }
        message_error.error(message, {
            render: (props) => {
                return h(
                    NAlert,
                    {
                        closable: props.closable,
                        onClose: props.onClose,
                        type: "error",
                        style: {
                            boxShadow: "var(--n-box-shadow)",
                            maxWidth: "calc(100vw - 32px)",
                            padding: "14px",
                            width: "480px",
                        },
                    },
                    {
                        default: () =>
                            h("div", {
                                innerHTML:
                                    props.content ??
                                    "Ошибка сервера, попробуйте позже.",
                            }),
                    }
                );
            },
            closable: true,
            duration: 5000,
        });
    }
}

store.commit("setLoading", true);
setTimeout(() => {
    store.commit("setLoading", false);
}, 1500);
</script>
<style>
.autho__wrapper {
    display: flex;
    height: 700px;
}
.autho-img {
    width: 100%;
}
.autho-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.autho-content {
    background-color: var(--light_gray);
    width: 100%;
    display: grid;
    place-items: center;
}
.autho-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 550px;
    margin: 0 auto;
}
.autho-form h1 {
    margin-bottom: 40px;
}
.autho-form .n-form-item.n-form-item--top-labelled {
    grid-template-areas:
        "blank"
        "feedback";
}
.autho-form .n-form-item {
    position: relative;
}
.autho-form .n-input:not(.n-input--textarea) .n-input__placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
}
.autho-form .n-input .n-input__input-el {
    height: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
    width: 100%;
    line-height: normal;
}
.autho-form .n-input .n-input-wrapper {
    padding: 16px;
}

.autho-form .n-input .n-input__suffix {
    flex-direction: row-reverse;
    line-height: normal;
    gap: 10px;
}
.autho-form .n-input .n-input__suffix .n-base-icon {
    width: 24px;
    height: 24px;
}
.autho-form .n-input .n-input__suffix .n-base-icon svg {
    width: 24px;
    height: 24px;
}
.autho-form .n-input .n-input__suffix svg {
    cursor: pointer;
}
.autho-confirm .n-form-item-blank {
    margin-top: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}
.autho-form .n-form-item.autho-confirm .n-form-item-feedback-wrapper {
    display: none;
}
.autho-form-desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
}
.autho-form-desc a {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
    color: currentColor;
}

.autho-form-btns {
    margin-top: 60px;
}
.autho-form-btns .n-divider:not(.n-divider--vertical) {
    margin: 40px 0;
}
.autho-form-btns.n-form-item .n-form-item .n-form-item-blank {
    flex-direction: column;
}
.autho-form-other-methods {
    display: flex;
    justify-content: center;
    gap: 20px;
}
.autho-forgot.n-form-item .n-form-item-blank {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
}
.autho-forgot.n-form-item .n-form-item-blank a {
    font-family: Manrope;
    position: absolute;
    top: calc(100% + 10px);
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black);
    text-decoration: none;
}
.autho-form .n-form-item .n-form-item-label {
    position: absolute;
    align-items: center;
    grid-area: blank;
    z-index: 10;
    pointer-events: none;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
    color: var(--gray);
    top: 50%;
    left: 16px;
    translate: 0 -50%;
    min-height: auto;
    padding: 0;
    transition: 0.5s;
}
@media screen and (max-width: 1200px) {
    .autho-form {
        gap: 10px;
    }
    .autho-form .n-form-item .n-form-item-label {
        font-size: 14px;
    }
    .autho-form .n-input .n-input__suffix svg,
    .autho-form .n-input .n-input__suffix .n-base-icon svg,
    .autho-form .n-input .n-input__suffix .n-base-icon {
        width: 20px;
        height: 20px;
    }
    .autho-form h1 {
        margin-bottom: 30px;
    }
    .autho-img {
        display: none;
    }
    .autho-form-desc,
    .autho-form-desc a {
        font-family: Manrope;
        font-size: 12px;
        font-weight: 400;
        line-height: 16.39px;
        letter-spacing: 0.045em;
        text-align: left;
    }
    .autho-confirm .n-form-item-blank {
        gap: 10px;
    }
    .autho-form-btns {
        margin-top: 20px;
    }
    .autho__wrapper {
        padding: 0;
        height: auto;
    }
    .autho-content {
        padding: 40px 20px;
    }
    .autho-forgot.n-form-item .n-form-item-blank a {
        font-size: 12px;
        font-weight: 400;
        line-height: 16.39px;
        letter-spacing: 0.045em;
    }
    .autho-form .n-form-item .n-form-item-label {
        position: static;
        display: none;
    }
    .autho-form .n-form-item .n-form-item-label {
        position: static;
        display: none;
    }
}
</style>
