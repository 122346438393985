<template>
    <section id="main-catalog">
        <div class="container catalog__wrapper">
            <h2>{{ props?.catalog?.title }}</h2>
            <n-tabs type="line" animated class="main-catalog-tabs">
                <n-tab-pane name="hits" tab="Хиты">
                    <div class="main-catalog-list">
                        <CatalogCard
                            v-for="item in props?.catalog?.tabs[0]?.list"
                            :card="item"
                        />
                    </div>
                </n-tab-pane>
                <n-tab-pane
                    name="new"
                    tab="Новинки"
                    v-if="props?.catalog?.tabs[1]"
                >
                    <div class="main-catalog-list">
                        <CatalogCard
                            v-for="item in props?.catalog?.tabs[1]?.list"
                            :card="item"
                        />
                    </div>
                </n-tab-pane>
                <n-tab-pane
                    name="sale"
                    tab="Распродажа"
                    v-if="props?.catalog?.tabs[1]"
                >
                    <div class="main-catalog-list">
                        <CatalogCard
                            v-for="item in props?.catalog?.tabs[2]?.list"
                            :card="item"
                        />
                    </div>
                </n-tab-pane>
                <template #suffix>
                    <a class="main-catalog-all-products" href="/catalog">
                        все товары
                        <ph-arrow-right :size="20" />
                    </a>
                </template>
            </n-tabs>
            <a class="main-catalog-all-products mob" href="/catalog">
                все товары
                <ph-arrow-right :size="20" />
            </a>
        </div>
    </section>
</template>
<script setup>
import { NTabs, NTabPane } from "naive-ui";
import CatalogCard from "@/components/UI/CatalogCard.vue";
import { PhArrowRight } from "@phosphor-icons/vue";

const props = defineProps({
    catalog: Object,
});

const catalog = [
    {
        hit: true,
        link: "/product/test",
        img: require("@/assets/img/catalog.png"),
        status: "хит продаж",
        name: "Межкомнатная дверь Скинни-14 Whitey эмаль Bravo",
        old_price: "6 832",
        current_price: "6 341",
        // fields: [
        //     { name: "Артикул", value: "03129371" },
        //     { name: "бренд", value: "" },
        //     { name: "24", value: "" },
        //     { name: "уцк", value: "03129371" },
        // ],
    },
    {
        hit: true,
        link: "/product/test",
        img: require("@/assets/img/catalog.png"),
        status: "хит продаж",
        name: "Межкомнатная дверь Скинни-14 Whitey эмаль Bravo",
        old_price: "6 832",
        current_price: "6 341",
    },
    {
        hit: true,
        link: "/product/test",
        img: require("@/assets/img/catalog.png"),
        status: "хит продаж",
        name: "Межкомнатная дверь Скинни-14 Whitey эмаль Bravo",
        old_price: "6 832",
        current_price: "6 341",
    },
    {
        hit: true,
        link: "/product/test",
        img: require("@/assets/img/catalog.png"),
        status: "хит продаж",
        name: "Межкомнатная дверь Скинни-14 Whitey эмаль Bravo",
        old_price: "6 832",
        current_price: "6 341",
    },
    {
        hit: true,
        link: "/product/test",
        img: require("@/assets/img/catalog.png"),
        status: "хит продаж",
        name: "Межкомнатная дверь Скинни-14 Whitey эмаль Bravo",
        old_price: "6 832",
        current_price: "6 341",
    },
    {
        hit: true,
        link: "/product/test",
        img: require("@/assets/img/catalog.png"),
        status: "хит продаж",
        name: "Межкомнатная дверь Скинни-14 Whitey эмаль Bravo",
        old_price: "6 832",
        current_price: "6 341",
    },
    {
        hit: true,
        link: "/product/test",
        img: require("@/assets/img/catalog.png"),
        status: "хит продаж",
        name: "Межкомнатная дверь Скинни-14 Whitey эмаль Bravo",
        old_price: "6 832",
        current_price: "6 341",
    },
    {
        hit: true,
        link: "/product/test",
        img: require("@/assets/img/catalog.png"),
        status: "хит продаж",
        name: "Межкомнатная дверь Скинни-14 Whitey эмаль Bravo",
        old_price: "6 832",
        current_price: "6 341",
    },
];
</script>
<style>
.container.catalog__wrapper {
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.main-catalog-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
}

.main-catalog-tabs .n-tabs-wrapper {
    min-width: auto;
}
.main-catalog-tabs .n-tabs .n-tabs-nav-scroll-content {
    min-width: auto;
}

.main-catalog-tabs .n-tabs-tab-wrapper:last-of-type {
    margin-left: auto;
}

.n-tabs
    .n-tabs-nav.n-tabs-nav--line-type.n-tabs-nav--top
    .n-tabs-nav__suffix:has(.main-catalog-all-products) {
    border: none;
}

.main-catalog-all-products {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--black);
}
.main-catalog-all-products.mob {
    align-self: flex-end;
    display: none;
}
@media screen and (max-width: 1400px) {
    .main-catalog-list {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (max-width: 1200px) {
    .main-catalog-all-products {
        font-size: 14px;
        line-height: 16px;
    }
    .main-catalog-list .catalog-item .catalog-item-btn {
        display: inline-block;
    }
    .main-catalog-list .catalog-item .catalog-item-btns {
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: absolute;
        right: 20px;
        top: 20px;
        width: auto;
        margin: 0;
    }
    .main-catalog-list .catalog-item .catalog-item-favourites.mobile {
        display: none;
    }
}
@media screen and (max-width: 1000px) {
    .main-catalog-list {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
    .container.catalog__wrapper {
        gap: 20px;
    }
}
@media screen and (max-width: 800px) {
    .main-catalog-all-products {
        display: none;
    }

    .main-catalog-all-products.mob {
        display: inline-flex;
    }

    .main-catalog-list {
        grid-template-columns: repeat(1, 1fr);
    }
    .favourites__wrapper {
        gap: 20px;
    }
    .main-catalog-tabs.n-tabs .n-tabs-wrapper {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .main-catalog-tabs.n-tabs .n-tabs-nav-scroll-content {
        justify-content: center;
    }
    .main-catalog-tabs.n-tabs .n-tabs-tab-wrapper {
        width: 100%;
    }
    .main-catalog-tabs.n-tabs
        .n-tabs-nav.n-tabs-nav--line-type.n-tabs-nav--top
        .n-tabs-bar {
        display: none;
    }
    .main-catalog-tabs.n-tabs.n-tabs--line-type .n-tabs-tab.n-tabs-tab--active {
        border-bottom: 1px solid var(--accent);
    }
    .main-catalog-tabs.n-tabs.n-tabs--line-type .n-tabs-tab {
        border-bottom: 1px solid transparent;
        width: 100%;
        justify-content: center;
    }
    .main-catalog-tabs.n-tabs.n-tabs--top .n-tab-pane {
        padding-top: 20px;
    }
}
</style>
