<template>
    <div class="basket">
        <section id="basket">
            <div class="container basket__wrapper">
                <h1>Корзина</h1>
                <div class="basket-content" v-if="basket?.length > 0">
                    <div class="basket-products">
                        <div class="basket-products-filters">
                            <n-checkbox
                                class="basket-products-choose-all"
                                @update:checked="checkAll"
                                v-model:checked="choose_all"
                            >
                                Выбрать все
                            </n-checkbox>
                            <button
                                class="remove_all-pc"
                                v-if="choose_all || one_is_checked"
                                @click="deleteAllChecked()"
                            >
                                Удалить выбранные
                            </button>
                            <button
                                class="remove_all-mob"
                                v-if="choose_all || one_is_checked"
                                @click="deleteAllChecked()"
                            >
                                <ph-trash :size="20" />
                            </button>
                        </div>
                        <div class="basket-products-list">
                            <HorizontalCard
                                @update_count="updateCount"
                                @update_checked="updateChecked"
                                @delete_item="deleteProduct"
                                v-for="item in basket"
                                :card="item"
                                :checked="checked_products[item.uniq_id]"
                            />
                        </div>
                    </div>
                    <div class="basket-total">
                        <div class="basket-total-header">
                            <h3>Условия заказа</h3>
                            <div class="basket-total-header-info">
                                Итого:
                                <div class="basket-total-header-info-order">
                                    <h4
                                        v-html="
                                            count_products +
                                            ' ' +
                                            num_word(count_products, words)
                                        "
                                    ></h4>
                                    <h4>{{ all_price + " ₽" }}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="basket-total-footer">
                            <Button
                                class="basket-total-btn"
                                type="white"
                                @click="sendBasket()"
                            >
                                Перейти к оформлению
                            </Button>
                            <div class="basket-total-desc">
                                Дата и стоимость доставки или самовывоза
                                определяются при оформлении заказа
                            </div>
                        </div>
                    </div>
                </div>
                <div class="empty-basket" v-else>
                    <h3>Корзина пуста</h3>
                    <Button link="/catalog">В каталог</Button>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>
import { NCheckbox } from "naive-ui";
import HorizontalCard from "@/components/UI/HorizontalCard.vue";
import Button from "@/components/UI/Button.vue";
import { PhTrash } from "@phosphor-icons/vue";
import productApi from "@/assets/js/api/products.js";
import userApi from "@/assets/js/api/user.js";
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { numberWithSpaces, num_word } from "@/assets/js/helper";

const store = useStore();

const words = ["товар", "товара", "товаров"];

const checked_products = ref({});
const choose_all = ref(false);
const basket = ref([]);

async function init() {
    await userApi.getUser();
    const result = await productApi.get_products("basket");
    await productApi.get_products("favourites");
    await productApi.get_products("compare");

    if (result?.success) {
        basket.value = result?.products;

        basket.value.forEach((item) => {
            checked_products.value[item.uniq_id] = false;
        });
    }

    store.commit("setLoading", false);
}

init();

function updateCount(data) {
    const product = basket.value.find((product) => product.uniq_id == data.id);
    product.count = data.count;

    if (product?.info?.complect) {
        if (product.price_kit && product.price_kit != "0") {
            product.total_cost = product.count * product.price_kit;
        }
    } else {
        if (product.price) {
            product.total_cost = product.count * product.price;
        }
        if (product.price_stock && product.price_stock != "0") {
            product.total_cost = product.count * product.price_stock;
        }
    }
}

function checkAll(value) {
    for (const key in checked_products.value) {
        checked_products.value[key] = value;
    }
    choose_all.value = value;
}

function updateChecked(data) {
    checked_products.value[data.id] = data.value;

    choose_all.value = is_all_checked.value;
}

const all_price = computed(() => {
    let total_price = 0;

    if (one_is_checked.value) {
        for (const key in checked_products.value) {
            if (checked_products.value[key] == true) {
                const finded = basket.value.find((item) => item.uniq_id == key);

                if (!finded) {
                    continue;
                }

                if (finded.total_cost) {
                    total_price += finded.total_cost;
                } else {
                    if (finded?.info?.complect) {
                        if (finded.price_kit && finded.price_kit != "0") {
                            total_price += finded.count * finded.price_kit;
                        }
                    } else {
                        if (finded.price_stock && finded.price_stock != "0") {
                            total_price += finded.count * finded.price_stock;
                        } else {
                            total_price += finded.count * finded.price;
                        }
                    }
                }
            }
        }
    } else {
        if (basket.value?.length > 0) {
            basket.value.forEach((product) => {
                if (product.total_cost) {
                    total_price += product.total_cost;
                } else {
                    if (product?.info?.complect) {
                        if (product.price_kit && product.price_kit != "0") {
                            total_price += product.count * product.price_kit;
                        }
                    } else {
                        if (product.price_stock && product.price_stock != "0") {
                            total_price += product.count * product.price_stock;
                        } else {
                            total_price += product.count * product.price;
                        }
                    }
                }
            });
        }
    }

    return numberWithSpaces(total_price);
});

const count_products = computed(() => {
    let total_count = 0;

    if (one_is_checked.value) {
        for (const key in checked_products.value) {
            if (checked_products.value[key] == true) {
                const finded = basket.value.find((item) => item.uniq_id == key);

                total_count += finded?.count || 0;
            }
        }
    } else {
        if (basket.value?.length > 0) {
            basket.value.forEach((product) => {
                total_count += product.count;
            });
        }
    }

    return total_count;
});

const one_is_checked = computed(() => {
    for (const key in checked_products.value) {
        if (checked_products.value[key] == true) {
            return true;
        }
    }

    return false;
});

const is_all_checked = computed(() => {
    for (const key in checked_products.value) {
        if (checked_products.value[key] == false) {
            return false;
        }
    }

    return true;
});

async function deleteAllChecked() {
    let products = [];

    for (const key in checked_products.value) {
        if (checked_products.value[key] == true) {
            products.push(key);
        }
    }

    const data = {
        date: new Date().getTime(),
        products: products,
    };

    const result = await productApi.delete_from("basket", data);
    if (result?.success) {
        for (const key in checked_products.value) {
            if (checked_products.value[key] == true) {
                delete checked_products.value[key];
            }
        }

        basket.value = basket.value.filter((item) => {
            return !Boolean(
                products.find((product) => product == item.uniq_id)
            );
        });
    }
}

async function deleteProduct(id) {
    const data = {
        date: new Date().getTime(),
        id: id,
    };

    const result = await productApi.delete_from("basket", data);
    if (result?.success) {
        for (const key in checked_products.value) {
            if (checked_products.value[key] == true) {
                delete checked_products.value[key];
            }
        }

        basket.value = basket.value.filter((item) => item.uniq_id != id);
    }
}

async function sendBasket() {
    let products = [];
    let product;
    if (one_is_checked.value) {
        for (const key in checked_products.value) {
            if (checked_products.value[key] == true) {
                product = basket.value.find((el) => el.uniq_id == key);
                products.push(product);
            }
        }
    } else {
        basket.value.forEach((item) => {
            product = item;
            products.push(product);
        });
    }

    const result = await productApi.createDraft({ products: products });

    sessionStorage.setItem("draft_id", result.id);

    window.location.href = "/making_order";
}

store.commit("setBreadcrumb", [
    { name: "Главная", link: "/" },
    { name: "Корзина" },
]);
</script>
<style>
.basket__wrapper {
    display: flex;
    flex-direction: column;
    gap: 60px;
}
.basket-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}
.basket-products {
    grid-column: 1 / span 2;
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.basket-products-list {
    display: flex;
    flex-direction: column;
}
.basket-products-filters .n-checkbox .n-checkbox-box-wrapper {
    margin: 0 !important;
}
.basket-products-filters {
    height: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.basket-products-filters button {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: right;
    color: var(--black);
    cursor: pointer;
    border: none;
    background-color: transparent;
}
.basket-total {
    position: sticky;
    top: 100px;
    background-color: var(--black);
    border-radius: 5px;
    height: max-content;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 60px;
}
.basket-total-header {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.basket-total-header > * {
    color: var(--white);
}
.basket-total-header-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.basket-total-header-info-order {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.basket-total-header-info-order-count-product,
.basket-total-header-info-order-price {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.045em;
    text-align: left;
}
.basket-total-footer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.basket-total-desc {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: var(--white);
}
.remove_all-mob {
    display: none;
}

.empty-basket {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}
.empty-basket button {
    width: max-content;
}
@media screen and (max-width: 1500px) {
    .basket-content .custom-btn {
        padding: 20px;
    }
}

@media screen and (max-width: 1300px) {
    .basket-total-header-info,
    .basket-total-desc {
        font-size: 12px;
        line-height: 100%;
    }
    .basket-total-desc {
        font-weight: 400;
    }
    .basket-products {
        gap: 20px;
    }
    .basket-content {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
    .remove_all-pc {
        display: none;
    }
    .remove_all-mob {
        display: block;
    }
    .basket__wrapper {
        gap: 20px;
    }
}

@media screen and (max-width: 800px) {
    .basket-total {
        padding: 20px;
        gap: 40px;
        position: static;
    }
}
</style>
