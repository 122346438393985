<template>
    <div class="counter">
        <n-input-number
            v-model:value="current_value"
            @update:value="change"
            button-placement="both"
            :max="99"
            :min="min"
            :bordered="false"
            :default-value="Number(props?.default)"
            :disabled="disabled"
        >
        </n-input-number>
    </div>
</template>
<script setup>
import { NInputNumber } from "naive-ui";
import { ref, defineEmits, defineExpose } from "vue";

const props = defineProps({
    default: "",
});

const min = 1;
const current_value = ref(1);
current_value.value = Number(props?.default);

const emit = defineEmits(["update:value"]);

const disabled = ref(false);

function change(value) {
    if (value == null) {
        current_value.value = 1;
        emit("update:value", 1);
        return;
    }
    emit("update:value", value);
    setTimeout(() => {
        disabled.value = false;
    }, 200);
}

function reset() {
    current_value.value = 1;
    emit("update:value", 1);
}

defineExpose({
    reset,
});
</script>
<style>
.counter {
    width: max-content;
}
.counter .n-button {
    width: 20px;
    height: 20px;
}
.counter .n-input .n-input__suffix,
.counter .n-input .n-input__prefix {
    margin: 0;
}
.counter .n-input .n-input-wrapper {
    padding: 0;
    gap: 10px;
}
.counter .n-input .n-input__input {
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--black);
}
.counter .n-input .n-input__input-el {
    border: 1px solid var(--gray);
    width: 80px;
    height: 50px;
    border-radius: 5px;
}

@media screen and (max-width: 1200px) {
    .counter .n-input .n-input__input-el {
        height: 27px;
    }
    .counter .n-input .n-input__input {
        font-size: 14px;
        line-height: 17.07px;
        letter-spacing: 0.045em;
    }
}
</style>
