<template>
    <div class="profile-avatar">
        <input
            name="avatar"
            type="file"
            id="avatar"
            @change="showFile"
            accept="image/png, image/jpg, image/jpeg"
        />
        <label for="avatar" class="profile-avatar-btn">
            <ph-user :size="36" v-if="!store.state.user.avatar" />
            <img :src="store.state.user.avatar" v-else />
        </label>
    </div>
</template>
<script setup>
import { PhUser } from "@phosphor-icons/vue";
import { useStore } from "vuex";
import userApi from "@/assets/js/api/user.js";

const store = useStore();

async function showFile(e) {
    let file_avatar = e.target.files[0];
    if (file_avatar) {
        store.state.user.avatar = URL.createObjectURL(file_avatar);

        const formdata = new FormData();
        formdata.append("file", file_avatar);

        await userApi.set_avatar(formdata);
    }
}
</script>
<style>
.profile-avatar {
    width: max-content;
    align-self: flex-end;
}

.profile-avatar input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
}

.profile-avatar-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--gray);
    width: 76px;
    height: 76px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    cursor: pointer;
    color: var(--black);
    transition: 0.5s;
}

.profile-avatar-btn:hover {
    color: var(--hover);
}

.profile-avatar-btn img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: top center;
}

@media screen and (max-width: 1200px) {
    .profile-inp-file-text {
        font-size: 9px;
        line-height: 100%;
    }

    .profile-inp-file-button {
        padding: 7px 15px;
        border-radius: 8px;
    }

    .profile-inp-file-button img {
        width: 10px;
        height: 10px;
    }
}
</style>
