<template>
    <section id="comparison" v-if="store.state.itemsInComparison?.length > 0">
        <div class="container comparison__wrapper">
            <h1>Сравнение товаров</h1>
            <n-scrollbar x-scrollable style="padding-bottom: 15px">
                <div class="comparison-grid">
                    <div
                        class="comparison-grid-row"
                        :style="
                            'grid-template-columns: repeat(' +
                            (store.state.itemsInComparison?.length + 1) +
                            ',minmax(280px, 1fr))'
                        "
                    >
                        <div
                            class="comparison-grid-row-filters"
                            style="padding-top: 5px"
                        >
                            <n-radio
                                :checked="
                                    specifications_filter ==
                                    'Все характеристики'
                                "
                                value="Все характеристики"
                                name="specification"
                                @change="setFilter"
                                v-if="store.state.itemsInComparison?.length > 1"
                            >
                                Все характеристики
                            </n-radio>
                            <n-radio
                                :checked="
                                    specifications_filter == 'Только различия'
                                "
                                value="Только различия"
                                name="specification"
                                @change="setFilter"
                                v-if="store.state.itemsInComparison?.length > 1"
                            >
                                Только различия
                            </n-radio>
                        </div>
                        <CatalogCard
                            class="comparison-grid-row-item"
                            v-for="item in comparison"
                            :card="item"
                            :type="'delete'"
                        />
                    </div>
                    <div class="comparison-specifictations">
                        <div
                            class="comparison-specifictations-row"
                            :style="
                                'grid-template-columns: repeat(' +
                                (store.state.itemsInComparison?.length + 1) +
                                ',minmax(280px, 1fr))'
                            "
                            v-for="row in props_specifications"
                        >
                            <div class="comparison-specifictations-row-item">
                                {{ row.name }}
                            </div>
                            <div
                                class="comparison-specifictations-row-item"
                                v-for="item in row.values"
                            >
                                {{ item.value }}
                            </div>
                        </div>
                    </div>
                </div>
            </n-scrollbar>
        </div>
    </section>
    <section v-else>
        <div class="container favourites__wrapper">
            <h1>Сравнение товаров</h1>
            <div class="comparison-empty">
                <svg
                    width="220"
                    height="220"
                    viewBox="0 0 220 220"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M165 183.334V91.667"
                        stroke="#E8E8E8"
                        stroke-width="12"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M110 183.334V36.667"
                        stroke="#E8E8E8"
                        stroke-width="12"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M55 183.333V128.333"
                        stroke="#E8E8E8"
                        stroke-width="12"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
                <h3>Товары для сравнения не выбраны</h3>
                <div class="comparison-empty-btns">
                    <Button link="/catalog">В каталог</Button>
                    <Button type="border" link="/">На главную</Button>
                </div>
            </div>
        </div>
    </section>
</template>
<script setup>
import CatalogCard from "../UI/CatalogCard.vue";
import Button from "../UI/Button.vue";
import { NRadio, NScrollbar } from "naive-ui";
import { ref, watch } from "vue";
import { useStore } from "vuex";

const store = useStore();
const props = defineProps({
    comparison: Array,
    specifications: Array,
});

const old_specifications = ref(null);
const props_specifications = ref(null);
old_specifications.value = props?.specifications?.list;
props_specifications.value = props?.specifications?.list;

const comparison = ref(null);
comparison.value = props?.comparison;

watch(
    () => store.state.itemsInComparison,
    (new_value) => {
        if (new_value) {
            comparison.value = new_value;
        }
    }
);

watch(props, (new_value) => {
    if (new_value.specifications) {
        props_specifications.value = props?.specifications?.list;
    }
});

const specifications_filter = ref("Все характеристики");

function setFilter(e) {
    specifications_filter.value = e.target.value;
    if (e.target.value == "Только различия") {
        props_specifications.value = props_specifications.value.filter(
            (row) => {
                const values = row.values.map((row_values) => row_values.value);
                const is_equals = values.every((value) => value == values[0]);
                return !is_equals;
            }
        );
    }
    if (e.target.value == "Все характеристики") {
        props_specifications.value = props.specifications.list.slice();
    }
}
</script>
<style>
.comparison__wrapper {
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.comparison-grid {
    display: grid;
    row-gap: 60px;
}
.comparison-grid-row {
    display: grid;
}
.comparison-grid-row-filters {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.comparison-specifictations {
    display: flex;
    flex-direction: column;
}
.comparison-specifictations-row-item:first-of-type {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    letter-spacing: 0.045em;
    text-align: left;
}
.comparison-specifictations-row-item {
    max-width: calc(100% - 30px);
    word-break: break-word;
}
.comparison-specifictations-row {
    display: grid;
    align-items: center;
    height: 100%;
    padding: 20px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    letter-spacing: 0.045em;
    text-align: left;
}

.comparison-specifictations-row:nth-child(even) {
    background-color: var(--light_gray);
}

.comparison-grid-row-item {
    max-width: calc(100% - 25%);
}

.comparison-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
}

.comparison-empty-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.comparison-empty-btns a {
    color: gray;
    text-decoration: none;
    padding: 20px 80px;
    border: 1px solid gray;
    border-radius: 5px;
    transition: 0.5s;
    grid-template-columns: repeat(3, 1fr);
}

.comparison-empty-btns a:first-of-type {
    color: gray;
}

.comparison-empty-btns a:hover:first-of-type {
    color: white;
    background-color: gray;
}

.comparison-empty-btns a:last-of-type {
    color: white;
    background-color: gray;
}

.comparison-empty-btns a:hover:last-of-type {
    color: gray;
    background-color: white;
}

@media screen and (max-width: 1200px) {
    .comparison__wrapper {
        gap: 40px;
    }
    .comparison-grid {
        gap: 40px;
    }
    .comparison-grid-row-filters {
        gap: 10px;
    }
    .comparison-specifictations-row-item:first-of-type {
        font-size: 14px;
        font-weight: 600;
        line-height: 17.07px;
        letter-spacing: 0.045em;
        text-align: left;
    }
    .comparison-specifictations-row {
        font-size: 12px;
        font-weight: 400;
        line-height: 16.39px;
        letter-spacing: 0.045em;
        text-align: left;
    }
}
</style>
