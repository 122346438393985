<template>
    <div class="catalog-list-sort">
        <div
            class="mobile-filter-btn"
            @click="emit('open-filter')"
            v-if="!is_results"
        >
            <ph-faders :size="20" />
            Фильтр товаров
        </div>
        <div class="desktop" v-if="!is_results">Сортировка:</div>
        <n-select
            v-if="!is_results"
            class="catalog-sort-select list"
            :options="options"
            v-model:value="currentValue"
            :menu-props="menu_styles"
            :placement="placement_list"
            @update:value="
                (value) => {
                    emit('update-sort', value);
                }
            "
            :disabled="loading"
        >
            <template #arrow>
                <transition class="desktop" name="slide-left">
                    <ph-caret-down :size="20" />
                </transition>
                <transition class="mobile" name="slide-left">
                    <ph-arrows-down-up :size="20" />
                </transition>
            </template>
        </n-select>
    </div>
    <div class="catalog-list-wrapper" :class="loading ? 'loading' : ''">
        <CatalogCard
            v-for="(item, index) in props?.catalog"
            :card="item"
            :key="index"
        />
    </div>
</template>
<script setup>
import { ref, defineEmits } from "vue";
import CatalogCard from "../UI/CatalogCard.vue";
import { NSelect } from "naive-ui";
import { PhCaretDown, PhFaders, PhArrowsDownUp } from "@phosphor-icons/vue";

const placement_list = ref(null);
if (window.innerWidth < 1200) {
    placement_list.value = "bottom-end";
} else {
    placement_list.value = "bottom-start";
}

const props = defineProps({
    catalog: Array,
    is_results: Boolean,
    loading: Boolean,
    sort: String,
});

const options = [
    {
        label: "По популярности",
        value: "popular",
    },
    {
        label: "Сначала новинки",
        value: "new",
    },
    {
        label: "Сначала дешевые",
        value: "low",
    },
    {
        label: "Сначала дорогие",
        value: "expensive",
    },
];

const currentValue = ref(null);
currentValue.value = props?.sort;

const menu_styles = { class: "catalog-sort-select-menu" };

const emit = defineEmits(["open-filter", "update-sort"]);
</script>
<style>
.catalog {
    position: relative;
}
.catalog-list {
    display: flex;
    flex-direction: column;
    gap: 30px 0;
    width: 100%;
}
.catalog-list .n-pagination {
    justify-content: center;
}
.catalog-list-sort {
    display: flex;
    gap: 10px;
    align-items: center;
    white-space: nowrap;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
}
.catalog-sort-select.list .n-base-selection .n-base-selection-label {
    height: auto;
}
.catalog-sort-select .n-base-selection .n-base-suffix .n-base-suffix__arrow {
    font-size: 20px;
}
.catalog-sort-select .n-base-selection {
    min-height: auto;
}
.catalog-sort-select
    .n-base-selection
    .n-base-selection-label
    .n-base-selection-input {
    padding: 0;
    padding-right: 10px;
}
.catalog-sort-select
    .n-base-selection
    .n-base-selection-label
    .n-base-selection-input
    .n-base-selection-input__content {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
    color: var(--dark_gray);
}
.catalog-sort-select.list > * {
    --n-border: none !important;
    --n-border-active: none !important;
    --n-border-focus: none !important;
    --n-border-hover: none !important;
    --n-border-radius: none !important;
    --n-box-shadow-active: none !important;
    --n-box-shadow-focus: none !important;
    --n-box-shadow-hover: none !important;
    --n-padding-single-right: 40px !important;
    width: max-content;
}
.catalog-sort-select .n-base-selection .n-base-selection__border,
.catalog-sort-select .n-base-selection .n-base-selection__state-border,
.catalog-sort-select
    .n-base-selection:not(.n-base-selection--disabled):hover
    .n-base-selection__state-border,
.catalog-sort-select
    .n-base-selection:not(.n-base-selection--disabled).n-base-selection--active
    .n-base-selection__state-border,
.catalog-sort-select
    .n-base-selection:not(.n-base-selection--disabled).n-base-selection--focus
    .n-base-selection__state-border {
    border: none;
    box-shadow: none;
}

.catalog-list-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
}

.catalog-list-wrapper.loading::before {
    content: "";
    position: absolute;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 100%;
    cursor: progress;
}

.catalog-list-wrapper .catalog-item-btn {
    font-size: 18px;
}

.catalog-sort-select-menu {
    margin: 0;
}
.catalog-sort-select-menu .v-vl-items {
    padding: 10px !important;
}
.catalog-sort-select-menu .v-vl-items .v-vl-visible-items {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.catalog-sort-select-menu .v-vl-items .n-base-select-option::before {
    content: none;
}
.catalog-sort-select-menu .v-vl-items .n-base-select-option {
    padding: 0;
    min-height: auto;
    transition: 0.5s;
}
.catalog-sort-select-menu .v-vl-items .n-base-select-option:hover {
    color: var(--hover);
}
.v-binder-follower-content:has(.catalog-sort-select-menu) {
    width: auto !important;
}

.catalog-sort-select-menu.n-base-select-menu
    .n-base-select-option
    .n-base-select-option__check {
    display: none;
}

.catalog-list-sort.desktop {
    display: block;
}

.catalog-list-sort.mobile {
    display: none;
}
.catalog-pagination {
}
.catalog-pagination.n-pagination .n-pagination-item {
    font-weight: 600;
}

.catalog-list-sort .desktop {
    display: block;
}

.catalog-list-sort .mobile {
    display: none;
}

.mobile-filter-btn {
    display: none;
}

.catalog-sort-select .desktop {
    display: block;
}

.catalog-sort-select .mobile {
    display: none;
}

@media screen and (max-width: 1640px) {
    .catalog-filters {
        width: 400px;
        min-width: 400px;
    }
}

@media screen and (max-width: 1440px) {
    .catalog-list-wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 1200px) {
    .catalog:has(.not-found-catalog) .catalog-list-sort.mobile {
        display: flex;
    }

    .n-space.catalog-list {
        gap: 20px 0 !important;
    }
    .catalog-list-sort .desktop {
        display: none;
    }

    .catalog-list-wrapper {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        max-width: 100%;
    }
    .catalog-list-wrapper .catalog-item {
        padding: 10px;
    }

    .catalog-filters {
        position: absolute;
        top: 0px;
        left: 0px;
        transform: translateX(calc(-100% - 20px));
    }

    .catalog-list-sort {
        display: flex;
        justify-content: space-between;
    }

    .n-select.catalog-sort-select {
        width: max-content;
    }

    .mobile-filter-btn {
        display: flex;
        gap: 5px;
        font-family: Manrope;
        font-size: 14px;
        font-weight: 700;
        line-height: 19.12px;
        letter-spacing: 0.045em;
        text-align: left;
    }
    .catalog-sort-select-menu .v-vl-items .v-vl-visible-items {
        gap: 10px;
    }
    .catalog-sort-select .desktop {
        display: none;
    }

    .catalog-sort-select .mobile {
        display: block;
    }
    .catalog-pagination {
        margin-top: 0;
    }
}
</style>
