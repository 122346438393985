<template>
    <div
        class="success-popup"
        :class="$store.state.success_popup ? 'open' : ''"
    >
        <div class="success-popup-bg" @click="closePopup"></div>
        <div class="success-popup-container">
            <svg
                class="success-popup-close"
                @click="closePopup"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6 17L17 6"
                    stroke="var(--dark_gray)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
                <path
                    d="M6 6L17 17"
                    stroke="var(--dark_gray)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
            </svg>
            <div class="success-popup-content">
                <h3>Спасибо за заявку</h3>
                <p>Мы перезвоним вам в течении 30 минут</p>
                <Button link="/" class="success-popup-btn" @click="closePopup">
                    На главную
                </Button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import Button from "./Button.vue";

const store = useStore();
function closePopup() {
    store.commit("successPopupOpen", false);
}
</script>
<style>
.success-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    z-index: 100;
}

.success-popup.open {
    opacity: 1;
    pointer-events: all;
}

.success-popup-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.success-popup-container {
    max-width: 1052px;
    position: relative;
    background-color: var(--white);
    border-radius: 5px;
    padding: 80px;
}

.success-popup-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 10;
    transition: 0.5s;
}
.success-popup-close path {
    transition: 0.5s;
}
.success-popup-close:hover path {
    stroke: var(--black);
}
.success-popup-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.success-popup-content h3 {
    text-align: center;
}
.success-popup-content p {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: center;
}
.success-popup-content .success-popup-btn {
    margin-top: 40px;
}
@media screen and (max-width: 1200px) {
    .success-popup-container {
        padding: 40px 20px;
        max-width: 340px;
    }
    .success-popup-content {
        align-items: center;
        gap: 10px;
    }
    .success-popup-content .success-popup-btn {
        margin-top: 30px;
    }
    .success-popup-content p {
        font-size: 14px;
        line-height: 19.12px;
    }
}
</style>
