<template>
    <div class="product">
        <Product
            :data="data"
            :comments="comments"
            :tabs="tabs"
            :variations="variations"
        />
    </div>
</template>
<script setup>
// import { mapMutations } from "vuex";
import Product from "@/components/product/Product.vue";
import productsApi from "@/assets/js/api/products.js";
import router from "@/router";
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
const data = ref(null);
const comments = ref([]);
const tabs = ref([]);
const variations = ref([]);

const type = computed(() => {
    if (data.value) {
        return data.value.type;
    }
});

const last_item = computed(() => {
    return data.value?.title;
});

const store = useStore();

watch(type, (new_value) => {
    if (new_value == "enter_doors") {
        store.commit("setBreadcrumb", [
            { name: "Главная", link: "/" },
            { name: "Каталог", link: "/catalog" },
            { name: "Входные двери", link: "/catalog?type=" + new_value },
            { name: last_item },
        ]);
    }
    if (new_value == "doors") {
        store.commit("setBreadcrumb", [
            { name: "Главная", link: "/" },
            { name: "Каталог", link: "/catalog" },
            { name: "Межкомнатные двери", link: "/catalog?type=" + new_value },
            { name: last_item },
        ]);
    }
    if (new_value == "special_doors") {
        store.commit("setBreadcrumb", [
            { name: "Главная", link: "/" },
            { name: "Каталог", link: "/catalog" },
            { name: "Специальные двери", link: "/catalog?type=" + new_value },
            { name: last_item },
        ]);
    }
});

const slug = router.currentRoute.value.params.slug;

async function getProduct() {
    store.commit("setLoading", true);
    const result = await productsApi.get_by_id(slug, true);
    data.value = result.data;
    comments.value = result.comments;
    tabs.value = result.tabs;
    variations.value = result.variations;

    if (result) {
        store.commit("setLoading", false);
    }
}
getProduct();
</script>
