<template>
    <section v-if="store.state.itemsInFavourites?.length > 0">
        <div class="container favourites__wrapper">
            <n-alert
                type="warning"
                class="mobile-alert"
                v-if="!store.state.user"
            >
                Обратите внимание! Добавить в избранное можно не более 20
                товаров. Чтобы сохранить подборку, авторизуйтесь в личном
                кабинете.
            </n-alert>
            <h1>Избранное</h1>
            <n-alert type="warning" class="pc-alert" v-if="!store.state.user">
                Обратите внимание! Добавить в избранное можно не более 20
                товаров. Чтобы сохранить подборку, авторизуйтесь в личном
                кабинете.
            </n-alert>
            <div class="favourites__list">
                <CatalogCard v-for="item in favourites" :card="item" />
            </div>
        </div>
    </section>
    <section v-else>
        <div class="container favourites__wrapper">
            <n-alert
                type="warning"
                class="mobile-alert"
                v-if="!store.state.user"
            >
                Обратите внимание! Добавить в избранное можно не более 20
                товаров. Чтобы сохранить подборку, авторизуйтесь в личном
                кабинете.
            </n-alert>
            <h1>Избранное</h1>
            <n-alert type="warning" class="pc-alert" v-if="!store.state.user">
                Обратите внимание! Добавить в избранное можно не более 20
                товаров. Чтобы сохранить подборку, авторизуйтесь в личном
                кабинете.
            </n-alert>
            <div class="favourites-empty">
                <PhHeartStraight />
                <h3>В вашем избранном пока пусто</h3>
                <div class="favourites-empty-btns">
                    <Button link="/catalog">В каталог</Button>
                    <Button link="/" type="border">На главную</Button>
                </div>
            </div>
        </div>
    </section>
</template>
<script setup>
import { NAlert, NIcon } from "naive-ui";
import { PhWarningCircle, PhHeartStraight } from "@phosphor-icons/vue";
import CatalogCard from "../UI/CatalogCard.vue";
import Button from "../UI/Button.vue";
import { ref, watch } from "vue";
import { useStore } from "vuex";

const store = useStore();

const props = defineProps({
    favourites: Array,
});

const favourites = ref(null);
favourites.value = props?.favourites;

watch(() => store.state.itemsInFavourites, (new_value) => {
    if (new_value) {
        favourites.value = new_value;
    }
});
</script>
<style>
.favourites__wrapper {
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.favourites__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
}

.favourites-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
}
.favourites-empty svg {
    width: 220px;
    height: 220px;
    color: var(--light_gray);
}
.favourites-empty-btns {
    display: flex;
    align-items: center;
    gap: 30px;
}
.favourites-empty-btns button {
    padding: 20px 120px;
}

.mobile-alert {
    display: none;
}

.pc-alert,
.mobile-alert {
    padding: 20px;
}
.pc-alert.n-alert .n-alert__icon,
.mobile-alert.n-alert .n-alert__icon {
    margin: 0;
    top: 20px;
    left: 20px;
}

.pc-alert.n-alert .n-alert__icon,
.pc-alert.n-alert .n-base-icon,
.pc-alert.n-alert .n-base-icon svg,
.mobile-alert.n-alert .n-alert__icon,
.mobile-alert.n-alert .n-base-icon,
.mobile-alert.n-alert .n-base-icon svg {
    width: 20px;
    height: 20px;
}
.pc-alert.n-alert .n-base-icon,
.mobile-alert.n-alert .n-base-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.pc-alert.n-alert.n-alert--show-icon .n-alert-body,
.mobile-alert.n-alert.n-alert--show-icon .n-alert-body {
    font-family: Manrope;
    padding: 0 0 0 30px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    letter-spacing: 0.045em;
}

@media screen and (max-width: 1400px) {
    .favourites__list {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (max-width: 1200px) {
    .pc-alert,
    .mobile-alert {
        padding: 10px;
    }
    .pc-alert.n-alert .n-alert__icon,
    .mobile-alert.n-alert .n-alert__icon {
        margin: 0;
        top: 10px;
        left: 10px;
    }
    .favourites__list .catalog-item .catalog-item-btn {
        display: inline-block;
    }
    .favourites__list .catalog-item .catalog-item-btns {
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: absolute;
        right: 20px;
        top: 20px;
        width: auto;
    }
    .favourites__list .catalog-item .catalog-item-favourites.mobile {
        display: none;
    }
}
@media screen and (max-width: 1000px) {
    .favourites__list {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
}
@media screen and (max-width: 800px) {
    .favourites__list {
        grid-template-columns: repeat(1, 1fr);
    }
    .favourites__wrapper {
        gap: 20px;
    }
    .pc-alert {
        display: none;
    }
    .mobile-alert {
        display: block;
    }
    .mobile-alert.n-alert .n-alert-body .n-alert-body__content {
        font-size: 12px;
        line-height: 16.39px;
        letter-spacing: 0.045em;
        text-align: left;
    }
    .favourites-empty svg {
        width: 172px;
        height: 172px;
    }
    .favourites-empty {
        gap: 40px;
    }
    .favourites-empty-btns {
        width: 100%;
        flex-direction: column;
        gap: 10px;
    }
    .favourites-empty-btns button {
        padding: 10px;
    }
}
</style>
