<template>
    <div class="recover">
        <section id="recover">
            <div class="recover__wrapper">
                <div class="recover-img">
                    <img :src="require('@/assets/img/half_section.png')" />
                </div>
                <div class="recover-content">
                    <n-form
                        class="recover-form"
                        ref="formRef"
                        :show-label="false"
                        :model="form_data"
                        :rules="rules"
                    >
                        <h1>Восстановить пароль</h1>
                        <div class="recover-form-desc">
                            Введите адрес вашей электронной почты ниже в форме,
                            чтобы восстановить пароль.
                        </div>
                        <n-form-item path="email">
                            <n-input
                                v-model:value="form_data.email"
                                placeholder="Email *"
                            />
                        </n-form-item>
                        <n-form-item class="reg-confirm">
                            <n-checkbox v-model:checked="form_data.confirm">
                                я даю согласие на обработку персональных данных
                            </n-checkbox>
                            <div class="reg-form-desc">
                                Нажимая кнопку зарегистрироваться подтверждаю,
                                что я ознакомлен и согласен с
                                <a href="/policy/personal">условиями оферты</a>
                                и
                                <a href="/policy/privacy">
                                    политики конфиденциальности </a
                                >.
                            </div>
                        </n-form-item>
                        <div class="reg-form-btns">
                            <Button @click="reset()"> Восстановить </Button>
                        </div>
                    </n-form>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>
import {
    NForm,
    NFormItem,
    NInput,
    NCheckbox,
    useMessage,
    NAlert,
} from "naive-ui";
import { ref, h } from "vue";
import { useStore } from "vuex";
import Button from "@/components/UI/Button.vue";
import userApi from "@/assets/js/api/user.js";
import { validateEmail } from "@/assets/js/helper";

const store = useStore();

store.commit("setBreadcrumb", [
    { name: "Главная", link: "/" },
    { name: "Восстановить пароль" },
]);

const formRef = ref(null);
const form_data = ref({
    email: "",
    confirm: false,
});

const message_error = useMessage();
const pattern = /[а-яА-ЯЁёa-zA-Z@ ]*$/g;

const rules = {
    email: {
        required: true,
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (!value) {
                return new Error("Обязательное поле");
            }
            if (value.charAt().match(pattern) != null) {
                if (!validateEmail(value)) {
                    return new Error("Введите существующую почту");
                }
            }
        },
    },
};

async function reset() {
    const data = {
        email: form_data.value.email,
    };
    await formRef.value.validate(async (errors) => {
        if (!errors) {
            if (!form_data.value.confirm) {
                message_error.error("Подтвердите согласие, нажмите галочку", {
                    render: (props) => {
                        return h(
                            NAlert,
                            {
                                closable: props.closable,
                                onClose: props.onClose,
                                type: "error",
                                style: {
                                    boxShadow: "var(--n-box-shadow)",
                                    maxWidth: "calc(100vw - 32px)",
                                    padding: "14px",
                                    width: "480px",
                                },
                            },
                            {
                                default: () =>
                                    h("div", { innerHTML: props.content }),
                            }
                        );
                    },
                    closable: true,
                    duration: 5000,
                });
                return;
            }

            const result = await userApi.reset_password(data);

            if (result?.data?.success) {
                store.commit("recoverPopupOpen", true);
                store.commit(
                    "setRecoverPopupMessage",
                    `Письмо со ссылкой для сброса пароля было направлено на адрес <span>${form_data.value.email}</span>, привязанный к вашей учетной записи, доставка сообщения может занять несколько минут.`
                );
            } else {
                message_error.error(result?.data?.message, {
                    render: (props) => {
                        return h(
                            NAlert,
                            {
                                closable: props.closable,
                                onClose: props.onClose,
                                type: "error",
                                style: {
                                    boxShadow: "var(--n-box-shadow)",
                                    maxWidth: "calc(100vw - 32px)",
                                    padding: "14px",
                                    width: "480px",
                                },
                            },
                            {
                                default: () =>
                                    h("div", {
                                        innerHTML: props.content,
                                    }),
                            }
                        );
                    },
                    closable: true,
                    duration: 5000,
                });
                return;
            }
        }
    });
}
store.commit("setLoading", true);
setTimeout(() => {
    store.commit("setLoading", false);
}, 1500);
</script>
<style>
.recover__wrapper {
    display: flex;
    height: 700px;
}
.recover-img {
    width: 100%;
}
.recover-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.recover-content {
    background-color: var(--light_gray);
    width: 100%;
    display: grid;
    place-items: center;
}
.recover-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 550px;
    margin: 0 auto;
}
.recover-form h1 {
    margin-bottom: 20px;
}
.recover-form .n-form-item.n-form-item--top-labelled {
    grid-template-areas:
        "blank"
        "feedback";
    position: relative;
}
.recover-form .n-input:not(.n-input--textarea) .n-input__placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
}
.recover-form .n-input .n-input__input-el {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;

    width: 100%;
    height: auto;
    line-height: normal;
}
.recover-form .n-input .n-input-wrapper {
    padding: 16px;
}

.recover-form .n-input .n-input__suffix {
    flex-direction: row-reverse;
    line-height: normal;
    gap: 10px;
}
.recover-form .n-input .n-input__suffix .n-base-icon {
    width: 24px;
    height: 24px;
}
.recover-form .n-input .n-input__suffix .n-base-icon svg {
    width: 24px;
    height: 24px;
}
.recover-form .n-input .n-input__suffix svg {
    cursor: pointer;
}
.recover-confirm .n-form-item-blank {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}
.recover-form .n-form-item.recover-confirm .n-form-item-feedback-wrapper {
    display: none;
}
.recover-form-desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
    margin-bottom: 40px;
}

.recover-form-btns {
    margin-top: 60px;
}

.recover-form-btns .n-divider:not(.n-divider--vertical) {
    margin: 40px 0;
}
.recover-form-btns.n-form-item .n-form-item .n-form-item-blank {
    flex-direction: column;
}
.recover-form-other-methods {
    display: flex;
    justify-content: center;
    gap: 20px;
}
.recover-forgot.n-form-item .n-form-item-blank {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
}
.recover-form .n-form-item.reg-confirm .n-form-item-feedback-wrapper {
    display: none;
}
.recover-forgot.n-form-item .n-form-item-blank a {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--gray);
    text-decoration: none;
}
.recover-form .n-form-item .n-form-item-label {
    position: absolute;
    align-items: center;
    grid-area: blank;
    z-index: 1;
    pointer-events: none;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
    color: var(--gray);
    top: 50%;
    left: 16px;
    translate: 0 -50%;
    min-height: auto;
    padding: 0;
    transition: 0.5s;
}

@media screen and (max-width: 1200px) {
    .recover-form .n-form-item .n-form-item-label {
        font-size: 14px;
    }
    .recover__wrapper {
        padding: 0;
        height: auto;
    }
    .recover-content {
        padding: 40px 20px;
    }
    .recover-img {
        display: none;
    }
    .recover-form-desc {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.12px;
        letter-spacing: 0.045em;
        margin-bottom: 40px;
    }
}
</style>
