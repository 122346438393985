<template>
    <div class="zamer">
        <ZamerMain :zamer="page_data?.data?.zamer" />
        <OrderSection />
    </div>
</template>
<script setup>
import OrderSection from "@/components/UI/OrderSection.vue";
import ZamerMain from "@/components/zamer/ZamerMain.vue";
import { useStore } from "vuex";
import pagesApi from "@/assets/js/api/pages.js";
import { onMounted, ref } from "vue";
const store = useStore();

const page_data = ref(null);

onMounted(async () => {
    store.commit("setLoading", true);
    const result = await pagesApi.getPageData("page/measuring");
    page_data.value = result;

    if (result) {
        store.commit("setLoading", false);
    }
});

store.commit("setBreadcrumb", [
    { name: "Главная", link: "/" },
    { name: "Услуги" },
    { name: "Замер" },
]);
</script>
<style></style>
