import Api from "./index.js";

class SiteInfoApi extends Api {
    async get(is_forced = false) {
        if (this.params.site_info && !is_forced) {
            return this.params.site_info;
        }

        const result = await this.send("methods/site_info");

        if (result?.data) {
            sessionStorage.setItem("site_info", JSON.stringify(result.data));
        }

        return result?.data;
    }
}

export default new SiteInfoApi();
