<template>
    <n-config-provider
        :theme-overrides="themeOverrides"
        :locale="ruRU"
        :date-locale="dateRuRU"
    >
        <n-message-provider>
            <n-notification-provider placement="bottom-right">
                <n-dialog-provider>
                    <n-scrollbar style="max-height: 100vh" ref="scroll">
                        <Preloader />
                        <Header />
                        <Breadcrumb />
                        <main>
                            <router-view />
                        </main>
                        <Footer />
                        <RecoverPopup />
                        <FortuneWheel />
                        <SuccessPopup />
                        <PrizePopup />
                        <CardPopup />
                        <AddedProductPopup />
                        <AskPricePopup />
                        <FortuneAnchor />
                        <ReviewPopup />
                        <OrderPopup />
                    </n-scrollbar>
                </n-dialog-provider>
            </n-notification-provider>
        </n-message-provider>
    </n-config-provider>
</template>

<script setup>
import Header from "@/components/Header.vue";
import Breadcrumb from "./components/UI/Breadcrumb.vue";
import Footer from "@/components/Footer.vue";
import RecoverPopup from "./components/UI/RecoverPopup.vue";
import FortuneWheel from "./components/UI/FortuneWheel.vue";
import SuccessPopup from "./components/UI/SuccessPopup.vue";
import PrizePopup from "./components/UI/PrizePopup.vue";
import CardPopup from "./components/UI/CardPopup.vue";
import AddedProductPopup from "./components/UI/AddedProductPopup.vue";
import AskPricePopup from "./components/UI/AskPricePopup.vue";
import Preloader from "@/components/Preloader.vue";
import FortuneAnchor from "@/components/UI/FortuneWheelAnchor.vue";
import ReviewPopup from "@/components/UI/ReviewPopup.vue";
import OrderPopup from "@/components/UI/OrderPopup.vue";

import {
    ruRU,
    dateRuRU,
    NConfigProvider,
    NMessageProvider,
    NNotificationProvider,
    NDialogProvider,
    NScrollbar,
} from "naive-ui";

import { nextTick, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import userApi from "@/assets/js/api/user.js";
import productApi from "@/assets/js/api/products.js";
import { useRoute } from "vue-router";

const themeOverrides = {
    common: {
        primaryColor: "#0e797e",
        primaryColorHover: "var(--hover)",
        primaryColorPressed: "var(--accent)",
        primaryColorSuppl: "var(--accent)",
        borderRadius: "5px",
        borderColor: "var(--gray)",
        pressedColor: "var(--accent)",
        borderFocus: "1px solid var(--accent)",
        // heightMedium: "auto",
        lineHeight: "normal",
    },
    Input: {
        paddingMedium: "var(--input_font_size)",
        groupLabelBorder: "none",
        border: "1px solid var(--gray)",
        borderHover: "1px solid var(--hover)",
        borderDisabled: "none",
        boxShadowFocus: "none",
        borderFocus: "1px solid var(--accent)",
        boxShadowFocusError: "none",
        borderHoverError: "1px solid var(--error)",
        borderDisabledError: "none",
        borderFocusError: "1px solid var(--error)",
        fontSizeMedium: "var(--input_font_size)",
        placeholderColor: "var(--gray)",
        borderRadius: "5px",
        heightMedium: "auto",
    },
    Checkbox: {
        sizeMedium: "var(--ckeckbox_size)",
        fontSizeMedium: "var(--check_font_size)",
        labelLineHeight: "100%",
        borderRadius: "5px",
        colorChecked: "var(--accent)",
        borderChecked: "1px solid var(--accent)",
        borderFocus: "1px solid var(--gray)",
        boxShadowFocus: "none",
        border: "1px solid var(--gray)",
        labelPadding: "var(--check_label_padding)",
    },
    Badge: {
        color: "var(--accent)",
        fontSize: "12px",
        fontFamily: "inherit",
    },
    Tabs: {
        tabTextColorActiveLine: "var(--accent)",
        barColor: "var(--accent)",
        tabTextColorHoverLine: "var(--hover)",
        tabPaddingMediumLine: "20px 20px",
        tabGapMediumLine: "0px",
        tabBorderColor: "transparent",
        tabFontWeight: "600",
        tabFontWeightActive: "600",
        tabFontSizeMedium: "var(--tab_font_size)",
        // panePaddingMedium: "60px 0 0 0",
    },
    Drawer: {
        bodyPadding: "20px",
        borderRadius: "0",
        boxShadow: "none",
    },
    Dropdown: {
        padding: "20px 0",
        borderRadius: "5px",
        optionHeightMedium: "auto",
        fontSizeMedium: "16px",
        color: "var(--light_gray)",
        optionTextColor: "var(--black)",
        optionTextColorHover: "var(--hover)",
        optionTextColorActive: "var(--accent)",
        optionIconSuffixWidthMedium: "20px",
        optionIconSuffixWidthSmall: "20px",
        optionIconSuffixWidthMedium: "20px",
        optionIconSuffixWidthLarge: "20px",
        optionIconSuffixWidthHuge: "20px",
    },
    DatePicker: {
        calendarTitleTextColor: "var(--accent)",
        calendarTitleFontSize: "16px",
        calendarTitleFontWeight: "700",
        panelActionPadding: "10px 20px",
        panelActionDividerColor: "var(--gray)",
        panelDaysDividerColor: "var(--gray)",
        itemColorActive: "var(--accent)",
        itemFontSize: "12px",
        itemBorderRadius: "0px",
        itemSize: "32px",
        itemCellWidth: "32px",
        itemCellHeight: "32px",

        itemColorHover: "transparent",
        itemColorIncluded: "var(--light_gray)",
        itemColorDisabled: "transparent",
        itemTextColorDisabled: "var(--light_gray)",
        itemColorActive: "var(--black)",
    },
    Button: {
        paddingMedium: "10px 40px",
        heightMedium: "auto",
        fontSizeMedium: "16px",
        textColorHover: "var(--hover)",
        textColorPressed: "var(--accent)",
        textColorText: "var(--black)",
        border: "1px solid var(--gray)",
        borderHover: "1px solid var(--hover)",
        borderPressed: "1px solid var(--accent)",
        borderFocus: "1px solid var(--accent)",
        textColorFocus: "var(--accent)",
        waveOpacity: "0",
        textColor: "var(--black)",
    },
    Tooltip: {
        padding: "10px",
        borderRadius: "5px",
        boxShadow: "none",
        color: "var(--black)",
        textColor: "var(--white)",
        border: "1px solid var(--black)",
    },
    Select: {
        menuBoxShadow: "0px 4px 8px 0px #00000026",
    },
    AutoComplete: {
        menuBoxShadow: "0px 4px 8px 0px #00000026",
    },
    Collapse: {
        titleFontSize: "20px",
        titleFontWeight: "600",
        dividerColor: "var(--gray)",
        titleTextColor: "var(--black)",
        arrowColor: "var(--black)",
    },
    Slider: {
        dotBorder: "3px solid var(--white)",
        dotBorderActive: "3px solid var(--white)",
        handleColor: "var(--black)",
        handleBoxShadow: "0px 0px 0px 3px var(--white)",
        handleBoxShadowHover: "0px 0px 0px 3px var(--white)",
        handleBoxShadowActive: "0px 0px 0px 3px var(--white)",
        handleBoxShadowFocus: "0px 0px 0px 3px var(--white)",
        railHeight: "var(--rail-height)",
        railColor: "var(--gray)",
        railColorHover: "var(--gray)",
        fillColor: "var(--black)",
        fillColorHover: "var(--black)",
    },
    Pagination: {
        itemSizeMedium: "50px",
        itemFontSizeMedium: "18px",
        buttonColor: "transparent",
        buttonColorHover: "transparent",
        buttonColorPressed: "transparent",
        itemColorDisabled: "transparent",
        buttonBorder: "none",
        buttonBorderHover: "none",
        buttonBorderPressed: "none",
        itemBorder: "none",
        itemBorderHover: "none",
        itemBorderPressed: "none",
        itemBorderActive: "none",
        itemBorderDisabled: "none",
        itemColorActive: "var(--black)",
        itemColorActiveHover: "var(--black)",
        itemTextColorActive: "var(--white)",
        itemTextColor: "var(--black)",
    },
    Alert: {
        titleFontWeight: "600",
        titleTextColor: "var(--black)",
        padding: "0px",
    },
    Scrollbar: {
        color: "var(--gray)",
        colorHover: "var(--hover)",
    },
    Breadcrumb: {
        fontWeightActive: "500",
        fontSize: "var(--breadcrumb_fz)",
        itemLineHeight: "18px",
        itemTextColor: "var(--gray)",
        itemTextColorHover: "var(--hover)",
        itemTextColorPressed: "var(--accent)",
        itemTextColorActive: "var(--black)",
        itemBorderRadius: "0",
        itemColorHover: "transparent",
        itemColorPressed: "transparent",
        separatorColor: "var(--gray)",
    },
};

const store = useStore();
const scroll = ref(null);

function updateBadges() {
    if (!store.state.user) {
        let comparisonItems =
            JSON.parse(localStorage.getItem("comparisonItems")) || [];

        let favouritesItems =
            JSON.parse(localStorage.getItem("favouritesItems")) || [];

        if (comparisonItems.length > 0) {
            store.commit("setBadgeComparison", comparisonItems);
        }

        if (favouritesItems.length > 0) {
            store.commit("setBadgeFavourites", favouritesItems);
        }
    }
}

const route = useRoute();

watch(
    () => route.name,
    (new_value) => {
        init(new_value);
    }
);

async function init(name) {
    if (name && name != "basket") {
        await userApi.getUser();
        await productApi.products_actions("all");
    }

    updateBadges();
}

onMounted(async () => {
    await nextTick();
    await init();
    store.commit("setScrollItem", scroll.value);

    if (JSON.parse(localStorage.getItem("gotPrize")) == true) {
        return;
    } else {
        if (JSON.parse(localStorage.getItem("showPrizeBtn")) != true) {
            setTimeout(() => {
                store.commit("fortuneWheelOpen", true);
            }, 10000);
        }
    }
});
</script>

<style>
.n-upload-file-list
    .n-upload-file.n-upload-file--image-card-type
    .n-upload-file-info
    .n-upload-file-info__thumbnail
    img {
    object-fit: contain;
}
.n-breadcrumb ul {
    display: flex;
    flex-wrap: wrap;
    row-gap: 3px;
}
.n-breadcrumb ul li {
    max-width: 100%;
}
.n-breadcrumb .n-breadcrumb-item .n-breadcrumb-item__link {
    text-wrap: wrap;
    display: block;
    max-width: 100%;
    padding: 0;
}
.n-breadcrumb .n-breadcrumb-item .n-breadcrumb-item__separator {
    margin: 0 10px;
}
.n-scrollbar-rail__scrollbar:active {
    background-color: var(--accent) !important;
}

.n-form-item.n-form-item--top-labelled {
    grid-template-areas:
        "blank"
        "feedback" !important;
}

.n-input .n-input__suffix {
    margin: 0 !important;
}

.n-input.n-input--textarea .n-input__textarea-el,
.n-input.n-input--textarea .n-input__textarea-mirror,
.n-input.n-input--textarea .n-input__placeholder {
    padding: 0 !important;
}

.n-form-item .n-form-item-feedback-wrapper {
    min-height: auto;
}

.n-pagination .n-input .n-input__input-el {
    font-size: 18px;
    text-align: center;
}
@font-face {
    font-family: Montserrat;
    src: url("assets/fonts/Montserrat-Regular.ttf");
    font-display: swap;
}

@font-face {
    font-family: Manrope;
    src: url("assets/fonts/Manrope-Regular.ttf");
    font-display: swap;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --white: #ffffff;
    --black: #363636;
    --dark_gray: #8f8f8f;
    --gray: #dfdfdf;
    --light_gray: #f4f4f4;
    --accent: #0e797e;
    --hover: #00adb5;
    --yellow: #ffc93c;
    --error: #e32e2e;

    --rail-height: 5px;
    --ckeckbox_size: 24px;
    --check_font_size: 16px;
    --breadcrumb_fz: 14px;
    --tab_font_size: 20px;
    --input_font_size: 16px;
    --check_label_padding: 0 16px;
}

body {
    margin: 0;
    font-family: Montserrat;
    color: var(--black);
}

.container {
    max-width: 1820px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
}

h1,
.h1 {
    font-family: Montserrat;
    font-size: 60px;
    font-weight: 600;
    line-height: 70px;
    letter-spacing: 0.045em;
}

h2,
.h2 {
    font-family: Montserrat;
    font-size: 50px;
    font-weight: 600;
    line-height: 61px;
    letter-spacing: 0.045em;
}

h3,
.h3 {
    font-family: Montserrat;
    font-size: 26px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0.045em;
}

h4 {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.045em;
}

h5,
.h5 {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
}

section {
    margin-bottom: 150px;
}

@media screen and (max-width: 1200px) {
    h1,
    .h1 {
        font-size: 30px;
        font-weight: 600;
        line-height: 37px;
        letter-spacing: 0.045em;
    }

    h2,
    .h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 24.38px;
        letter-spacing: 0.045em;
    }

    h3,
    .h3 {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }

    h4 {
        font-size: 16px;
        line-height: 19.5px;
        letter-spacing: 0.045em;
    }

    h5,
    .h5 {
        font-size: 14px;
        font-weight: 600;
        line-height: 17.07px;
        letter-spacing: 0.045em;
    }

    section {
        margin-bottom: 80px;
    }

    :root {
        --rail-height: 3px;
        --ckeckbox_size: 16px;
        --check_font_size: 14px;
        --breadcrumb_fz: 12px;
        --tab_font_size: 16px;
        --input_font_size: 14px;
        --check_label_padding: 0 8px;
    }
    .n-checkbox .n-checkbox-box-wrapper {
        margin-top: 4px !important;
    }
}

@media screen and (max-width: 360px) {
    .container {
        padding: 0 10px;
    }
}
</style>
