<template>
    <div class="prize-popup" :class="$store.state.prize_popup ? 'open' : ''">
        <div class="prize-popup-bg" @click="closePopup"></div>
        <div class="prize-popup-container">
            <svg
                class="prize-popup-close"
                @click="closePopup"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.5675 15.1828C16.6256 15.2409 16.6717 15.3098 16.7031 15.3857C16.7345 15.4615 16.7507 15.5429 16.7507 15.625C16.7507 15.7071 16.7345 15.7884 16.7031 15.8643C16.6717 15.9402 16.6256 16.0091 16.5675 16.0672C16.5095 16.1252 16.4405 16.1713 16.3647 16.2027C16.2888 16.2342 16.2075 16.2503 16.1253 16.2503C16.0432 16.2503 15.9619 16.2342 15.886 16.2027C15.8102 16.1713 15.7412 16.1252 15.6832 16.0672L10.5003 10.8836L5.31753 16.0672C5.20026 16.1844 5.0412 16.2503 4.87535 16.2503C4.7095 16.2503 4.55044 16.1844 4.43316 16.0672C4.31588 15.9499 4.25 15.7908 4.25 15.625C4.25 15.4591 4.31588 15.3001 4.43316 15.1828L9.61675 9.99998L4.43316 4.81717C4.31588 4.69989 4.25 4.54083 4.25 4.37498C4.25 4.20913 4.31588 4.05007 4.43316 3.93279C4.55044 3.81552 4.7095 3.74963 4.87535 3.74963C5.0412 3.74963 5.20026 3.81552 5.31753 3.93279L10.5003 9.11639L15.6832 3.93279C15.8004 3.81552 15.9595 3.74963 16.1253 3.74963C16.2912 3.74963 16.4503 3.81552 16.5675 3.93279C16.6848 4.05007 16.7507 4.20913 16.7507 4.37498C16.7507 4.54083 16.6848 4.69989 16.5675 4.81717L11.3839 9.99998L16.5675 15.1828Z"
                    fill="#F4F4F4"
                />
            </svg>
            <div class="prize-popup-content">
                <div class="prize-popup-content-header">
                    <div class="h3">Поздравляем! Вы выиграли!</div>
                    <div class="h2">{{ prize }}</div>
                </div>
                <div class="prize-popup-content-body">
                    <div class="prize-popup-content-promo">
                        Ваш промокод
                        <div class="h3">{{ promo }}</div>
                    </div>
                    <div class="prize-popup-content-text">
                        Ваш подарок и условия акции отправим в смс <br />
                        на номер, который вы указали
                    </div>
                </div>
                <Button
                    @click="closePopup"
                    class="prize-popup-btn"
                    type="white"
                    link="/"
                >
                    На главную
                </Button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useStore } from "vuex";
import Button from "./Button.vue";
import { computed } from "vue";
const store = useStore();
function closePopup() {
    store.commit("prizePopupOpen", false);
    setTimeout(() => {
        store.commit("setPrizePopupMessage", null);
    }, 500);
    localStorage.setItem("showPrizeBtn", false);
}

const prize = computed(() => {
    return store.state.prize_message;
});

const promo = computed(() => {
    return store.state.prize_promo;
});
</script>
<style>
.prize-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    z-index: 120;
}

.prize-popup.open {
    opacity: 1;
    pointer-events: all;
}

.prize-popup-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--black);
}

.prize-popup-container {
    max-width: 954px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 60px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    padding: 40px;
}

.prize-popup-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 10;
    transition: 0.5s;
}
.prize-popup-close path {
    transition: 0.5s;
}
.prize-popup-close:hover path {
    fill: black;
}
.prize-popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}
.prize-popup-content-header,
.prize-popup-content-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}
.prize-popup-content-header .h2,
.prize-popup-content-header .h3 {
    color: var(--white);
    text-align: center;
}

.prize-popup-content-promo {
    background-color: var(--accent);
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: center;
    color: var(--white);
}
.prize-popup-content-text {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: center;
    color: var(--white);
}
.prize-popup-btn {
    margin-top: 20px;
}
@media screen and (max-width: 800px) {
    .prize-popup-content-promo {
        padding: 20px;
        font-size: 14px;
        line-height: 100%;
    }
    .prize-popup-content-header {
        gap: 10px;
    }
    .prize-popup-container {
        padding: 40px 20px;
    }
    .prize-popup-content {
        gap: 20px;
    }
    .prize-popup-content-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.12px;
        letter-spacing: 0.045em;
    }
    .prize-popup-content-text br {
        display: none;
    }
}
</style>
