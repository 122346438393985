<template>
    <div class="header-catalog" :class="is_show ? 'show' : ''">
        <n-dropdown :on-update:show="update" class="header-dropdown-catalog" placement="bottom-start" trigger="click" size="huge"
            :options="options">
            <h4>
                Каталог
                <ph-caret-down :size="24" weight="bold" />
            </h4>
        </n-dropdown>
    </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useStore } from "vuex";
import { NDropdown } from "naive-ui";
import { getMenuItem } from "@/assets/js/helper";
import {
    PhCaretDown,
} from "@phosphor-icons/vue";

const is_show = ref(false);

function update(event) {
    is_show.value = event;
}

const store = useStore().state;

const menu = ref([]);

watch(store, (new_value) => {
    if (new_value) {
        menu.value = new_value.site_info?.header?.catalog;
    }
});


const options = computed(() => {
    let result = [];

    if (menu.value?.length > 0) {
        menu.value.forEach((item, key) => {
            result.push(getMenuItem(item, key));
        });
    }

    return result;
});
</script>

<style>
.header-catalog h4 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: var(--black);
    padding: 20px 100px;
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 5px;
    color: var(--white);
    cursor: pointer;
}

.header-catalog h4 svg {
    transition: 0.5s;
    rotate: 0deg;
}

.header-catalog.show h4 svg {
    rotate: -180deg;
}

.header-dropdown-catalog .n-dropdown-menu .n-dropdown-option .n-dropdown-option-body .n-dropdown-option-body__label,
.header-dropdown-catalog.n-dropdown-menu .n-dropdown-option .n-dropdown-option-body .n-dropdown-option-body__label {
    height: auto;
}

.header-dropdown-catalog.n-dropdown-menu .n-dropdown-option .n-dropdown-option-body::before {
    content: none;
}

[v-placement="bottom-start"]>.header-dropdown-catalog {
    margin-top: 20px;
    width: 350px;
}

[v-placement="bottom-start"]>.header-dropdown-catalog .n-dropdown-menu {
    width: 350px;
}

div.header-navbar-item {
    cursor: default;
}

.header-dropdown-profile .n-dropdown-option .n-dropdown-option-body {
    padding: 10px 40px;
}

.header-dropdown-catalog.n-dropdown-menu .n-dropdown-option .n-dropdown-option-body {
    padding: 0 20px;
}

.header-dropdown-catalog.n-dropdown-menu .n-dropdown-option .n-dropdown-option-body__prefix,
.header-dropdown-profile.n-dropdown-menu .n-dropdown-option .n-dropdown-option-body__prefix,
.header-dropdown-profile.n-dropdown-menu .n-dropdown-option .n-dropdown-option-body__suffix,
.header-dropdown-profile-empty.n-dropdown-menu .n-dropdown-option .n-dropdown-option-body__suffix,
.header-dropdown-profile-empty.n-dropdown-menu .n-dropdown-option .n-dropdown-option-body__prefix {
    display: none;
}

@media screen and (max-width: 1440px) {
    .header-catalog h4 {
        padding: 0px;
    }
}
</style>