<template>
    <section id="review" v-if="props?.reviews?.list?.length > 0">
        <div class="container review__wrapper">
            <div class="review-header">
                <h2>{{ props?.reviews?.title }}</h2>
                <div class="custom-arrow review-custom-arrow">
                    <button
                        type="button"
                        class="custom-arrow--left"
                        @click="prev"
                        :class="currentIndex == 0 ? 'disabled' : ''"
                    >
                        <n-icon><ph-arrow-left :size="20" /></n-icon>
                    </button>
                    <button
                        type="button"
                        class="custom-arrow--right"
                        @click="next"
                        :class="
                            currentIndex == props?.reviews?.list?.length - 3
                                ? 'disabled'
                                : ''
                        "
                    >
                        <n-icon><ph-arrow-right :size="20" /></n-icon>
                    </button>
                </div>
            </div>
            <n-carousel
                class="review-slider"
                draggable
                :slides-per-view="slides_per_view"
                :show-dots="false"
                :loop="false"
                :space-between="30"
                ref="review_slider"
                :on-update:current-index="checkIndex"
            >
                <ReviewCard
                    v-for="item in props?.reviews?.list"
                    :review="item"
                />
            </n-carousel>
        </div>
    </section>
</template>
<script setup>
import { NCarousel, NIcon } from "naive-ui";
import { ref, onMounted } from "vue";
import { PhArrowRight, PhArrowLeft } from "@phosphor-icons/vue";
import ReviewCard from "@/components/UI/ReviewCard.vue";

const props = defineProps({
    reviews: Object,
});

const slides_per_view = ref(null);

const review_slider = ref(null);
const currentIndex = ref(0);

function prev() {
    review_slider.value.prev();
}

function next() {
    review_slider.value.next();
}

function checkIndex() {
    currentIndex.value = review_slider.value.realIndex;
}

function checkSize() {
    if (window.innerWidth > 1400) {
        slides_per_view.value = 3;
    } else if (window.innerWidth > 800) {
        slides_per_view.value = 2;
    } else {
        slides_per_view.value = 1;
    }
}

onMounted(() => {
    checkSize();
    window.addEventListener("resize", checkSize);
});
</script>
<style>
.review-slider.n-carousel {
    overflow-x: hidden;
    overflow-y: visible;
}
.review__wrapper {
    display: flex;
    flex-direction: column;
    gap: 60px;
}
.review-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.review-custom-arrow {
    position: static;
    justify-content: unset;
}

@media screen and (max-width: 1200px) {
    .review__wrapper {
        gap: 20px;
    }

    .review-custom-arrow {
        gap: 10px;
    }
}
</style>
