<template>
    <div
        class="ask_price-popup"
        :class="$store.state.ask_price_popup ? 'open' : ''"
    >
        <div class="ask_price-popup-bg" @click="closePopup"></div>
        <div class="ask_price-popup-container">
            <svg
                class="ask_price-popup-close"
                @click="closePopup"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6 17L17 6"
                    stroke="var(--dark_gray)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
                <path
                    d="M6 6L17 17"
                    stroke="var(--dark_gray)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
            </svg>
            <div class="ask_price-popup-content" v-if="!success">
                <div class="h3">Узнать цену</div>
                <p>
                    Для того, чтобы узнать цену на товар, необходимо указать
                    свой номер телефона или почту. Наш менеджер с вами свяжится
                    для уточнения информации.
                </p>
                <n-form
                    class="order-form ask-form"
                    ref="formRef"
                    :model="form_data"
                    :rules="rules"
                    :show-label="false"
                >
                    <n-form-item path="phone_email">
                        <n-input
                            v-model:value="form_data.phone_email"
                            placeholder="Телефон или почта *"
                        />
                    </n-form-item>
                    <n-form-item>
                        <n-checkbox v-model:checked="form_data.confirm">
                            я даю согласие на обработку персональных данных
                        </n-checkbox>
                        <div class="autho-form-desc">
                            Нажимая кнопку зарегистрироваться подтверждаю, что я
                            ознакомлен и согласен с
                            <a href="/policy/personal">условиями оферты</a>
                            и
                            <a href="/policy/privacy">
                                политики конфиденциальности </a
                            >.
                        </div>
                    </n-form-item>
                </n-form>
                <Button @click="AskPrice">Отправить</Button>
            </div>
            <div class="ask_price-popup-content answer" v-else>
                <div class="h3">
                    Запрос на получение цены успешно отправлен!
                </div>
                <Button @click="closePopup">Вернуться к покупкам</Button>
            </div>
        </div>
    </div>
</template>
<script setup>
import {
    NForm,
    NFormItem,
    NInput,
    useMessage,
    NAlert,
    NCheckbox,
} from "naive-ui";

import { useStore } from "vuex";
import Button from "./Button.vue";
import orderApi from "@/assets/js/api/order.js";
import { replaceNumberForPaste, validateEmail } from "@/assets/js/helper";
import { h, ref } from "vue";

const message_error = useMessage();
const pattern = /^[а-яА-ЯЁёa-zA-Z@ ]*$/g;
const store = useStore();

const success = ref(false);

function closePopup() {
    store.commit("AskPricePopupOpen", false);
    store.commit("AskPriceId", -1);
    setTimeout(() => {
        success.value = false;
    }, 500);
}

const formRef = ref(null);
const form_data = ref({
    phone_email: "",
    confirm: false,
});
const rules = {
    phone_email: {
        required: true,
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (!value) {
                return new Error("Обязательное поле");
            }
            if (value.charAt().match(pattern) != null) {
                if (!validateEmail(value)) {
                    return new Error("Введите существующую почту");
                }
            } else {
                form_data.value.phone_email = replaceNumberForPaste(value);
                if (value.length < 18) {
                    return new Error("Введите существующий телефон");
                }
            }
        },
    },
};

function resetForm() {
    form_data.value.phone_email = "";
    form_data.value.confirm = false;
}

async function AskPrice() {
    const data = {
        phone_email: form_data.value.phone_email,
        product_id: store.state.ask_price_id,
    };

    await formRef.value.validate(async (errors) => {
        if (!errors) {
            if (!form_data.value.confirm) {
                message_error.error("Подтвердите согласие, нажмите галочку", {
                    render: (props) => {
                        return h(
                            NAlert,
                            {
                                closable: props.closable,
                                onClose: props.onClose,
                                type: "error",
                                style: {
                                    boxShadow: "var(--n-box-shadow)",
                                    maxWidth: "calc(100vw - 32px)",
                                    padding: "14px",
                                    width: "480px",
                                },
                            },
                            {
                                default: () =>
                                    h("div", { innerHTML: props.content }),
                            }
                        );
                    },
                    closable: true,
                    duration: 5000,
                });
                return;
            }

            const result = await orderApi.priceAsk(data);

            if (result?.success) {
                resetForm();
                success.value = true;
            } else {
                message_error.error("Ошибка сервера", {
                    render: (props) => {
                        return h(
                            NAlert,
                            {
                                closable: props.closable,
                                onClose: props.onClose,
                                type: "error",
                                style: {
                                    boxShadow: "var(--n-box-shadow)",
                                    maxWidth: "calc(100vw - 32px)",
                                    padding: "14px",
                                    width: "480px",
                                },
                            },
                            {
                                default: () =>
                                    h("div", { innerHTML: props.content }),
                            }
                        );
                    },
                    closable: true,
                    duration: 5000,
                });
                return;
            }
        }
    });
}
</script>
<style>
.ask_price-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    z-index: 100;
}

.ask_price-popup.open {
    opacity: 1;
    pointer-events: all;
}

.ask_price-popup-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.ask_price-popup-container {
    max-width: 700px;
    position: relative;
    background-color: var(--white);
    border-radius: 5px;
    padding: 40px;
}

.ask_price-popup-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 10;
    transition: 0.5s;
}
.ask_price-popup-close path {
    transition: 0.5s;
}
.ask_price-popup-close:hover path {
    stroke: var(--black);
}
.ask_price-popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.ask_price-popup-content.answer {
    gap: 50px;
}
.ask_price-popup-content > .h3 {
    text-align: center;
}
.ask_price-popup-content p {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: center;
}
.ask_price-popup-content .ask_price-popup-btn {
    margin-top: 40px;
}
.ask-form.order-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 40px;
}
.ask-form .n-input .n-input-wrapper {
    padding: 16px;
}
.ask-form .autho-form-desc,
.ask-form .autho-form-desc a {
    font-size: 14px;
}
.ask-form.order-form .n-checkbox {
    margin: 0;
}
@media screen and (max-width: 1200px) {
    .ask_price-popup-container {
        padding: 40px 20px;
        max-width: 340px;
    }
    .ask_price-popup-content {
        align-items: center;
        gap: 10px;
    }
    .ask_price-popup-content .ask_price-popup-btn {
        margin-top: 30px;
    }
    .ask_price-popup-content p {
        font-size: 14px;
        line-height: 19.12px;
    }
}
</style>
