<template>
    <div
        class="added_product-popup"
        :class="store.state.added_product_popup ? 'open' : ''"
    >
        <div class="added_product-popup-bg" @click="closePopup"></div>
        <div class="added_product-popup-container">
            <svg
                class="added_product-popup-close"
                @click="closePopup"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.0672 15.1828C16.1252 15.2409 16.1713 15.3098 16.2027 15.3857C16.2342 15.4615 16.2503 15.5429 16.2503 15.625C16.2503 15.7071 16.2342 15.7884 16.2027 15.8643C16.1713 15.9402 16.1252 16.0091 16.0672 16.0672C16.0091 16.1252 15.9402 16.1713 15.8643 16.2027C15.7884 16.2342 15.7071 16.2503 15.625 16.2503C15.5429 16.2503 15.4615 16.2342 15.3857 16.2027C15.3098 16.1713 15.2409 16.1252 15.1828 16.0672L9.99998 10.8836L4.81717 16.0672C4.69989 16.1844 4.54083 16.2503 4.37498 16.2503C4.20913 16.2503 4.05007 16.1844 3.93279 16.0672C3.81552 15.9499 3.74963 15.7908 3.74963 15.625C3.74963 15.4591 3.81552 15.3001 3.93279 15.1828L9.11639 9.99998L3.93279 4.81717C3.81552 4.69989 3.74963 4.54083 3.74963 4.37498C3.74963 4.20913 3.81552 4.05007 3.93279 3.93279C4.05007 3.81552 4.20913 3.74963 4.37498 3.74963C4.54083 3.74963 4.69989 3.81552 4.81717 3.93279L9.99998 9.11639L15.1828 3.93279C15.3001 3.81552 15.4591 3.74963 15.625 3.74963C15.7908 3.74963 15.9499 3.81552 16.0672 3.93279C16.1844 4.05007 16.2503 4.20913 16.2503 4.37498C16.2503 4.54083 16.1844 4.69989 16.0672 4.81717L10.8836 9.99998L16.0672 15.1828Z"
                    fill="#8F8F8F"
                />
            </svg>
            <n-scrollbar>
                <div class="added_product-info">
                    <h3>
                        Товар добавлен <br />
                        в корзину!
                    </h3>
                    <div class="added_product-info-header">
                        <h4>{{ product?.title }}</h4>
                        <h5>{{ count + " x " + price + " ₽" }}</h5>
                    </div>
                    <div class="added_product-info-block">
                        <img
                            :src="added_product?.img ?? product?.img"
                            alt=""
                            v-if="added_product?.img || product?.img"
                        />
                        <img
                            :src="require('@/assets/img/no_img.png')"
                            alt=""
                            v-else
                        />
                    </div>
                    <div
                        class="added_product-info-block"
                        v-html="
                            'В вашей корзине  <span>' +
                            count +
                            ' ' +
                            num_word(count, words) +
                            '</span>  на сумму <span>' +
                            total_price +
                            ' ₽</span>'
                        "
                    ></div>
                </div>
                <div class="product__main-btns popup">
                    <Button link="/basket">Открыть корзину</Button>
                    <Button type="border" @click="closePopup">
                        Продолжить покупки
                    </Button>
                </div>
            </n-scrollbar>
        </div>
    </div>
</template>
<script setup>
import { NScrollbar } from "naive-ui";
import { useStore } from "vuex";
import Button from "./Button.vue";
import { computed } from "vue";
import { num_word, numberWithSpaces } from "@/assets/js/helper";
const store = useStore();
function closePopup() {
    store.commit("AddedProductPopupOpen", false);
}

const words = ["товар", "товара", "товаров"];

const count = computed(() => {
    return store.state.added_product_count;
});

const product = computed(() => {
    return store.state.productInfo;
});

const added_product = computed(() => {
    return store.state.addedProductInfo;
});

const price = computed(() => {
    if (added_product?.value?.info?.complect) {
        if (
            added_product.value?.price_kit &&
            added_product.value?.price_kit != "0"
        ) {
            return numberWithSpaces(added_product.value?.price_kit);
        }
        if (product.value?.price_kit && product.value?.price_kit != "0") {
            return numberWithSpaces(product.value?.price_kit);
        }
    } else {
        if (
            added_product.value?.price_stock &&
            added_product.value?.price_stock != "0"
        ) {
            return numberWithSpaces(added_product.value?.price_stock);
        }
        if (added_product.value?.price) {
            return numberWithSpaces(added_product.value?.price);
        }
        if (product.value?.price_stock && product.value?.price_stock != "0") {
            return numberWithSpaces(product.value?.price_stock);
        }
        if (product.value?.price) {
            return numberWithSpaces(product.value?.price);
        }
    }
});

const total_price = computed(() => {
    if (added_product?.value?.info?.complect) {
        if (
            added_product.value?.price_kit &&
            added_product.value?.price_kit != "0"
        ) {
            return numberWithSpaces(
                added_product.value?.price_kit * count.value
            );
        }
        if (product.value?.price_kit && product.value?.price_kit != "0") {
            return numberWithSpaces(product.value?.price_kit * count.value);
        }
    } else {
        if (
            added_product.value?.price_stock &&
            added_product.value?.price_stock != "0"
        ) {
            return numberWithSpaces(
                added_product.value?.price_stock * count.value
            );
        }
        if (added_product.value?.price) {
            return numberWithSpaces(added_product.value?.price * count.value);
        }
        if (product.value?.price_stock && product.value?.price_stock != "0") {
            return numberWithSpaces(product.value?.price_stock * count.value);
        }
        if (product.value?.price) {
            return numberWithSpaces(product.value?.price * count.value);
        }
    }
});
</script>
<style>
.added_product-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    z-index: 100;
}

.added_product-popup.open {
    opacity: 1;
    pointer-events: all;
}

.added_product-popup-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.added_product-popup-container {
    max-height: 90vh;
    max-width: 500px;
    position: relative;
    background-color: white;
    border-radius: 5px;
    padding: 40px;
}
.added_product-popup-container .n-scrollbar-content {
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.added_product-popup-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 10;
    transition: 0.5s;
}
.added_product-popup-close path {
    transition: 0.5s;
}
.added_product-popup-close:hover path {
    fill: black;
}
.added_product-info {
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.added_product-info,
.product__main-btns.popup {
    padding-right: 20px;
}
.added_product-info h3 br {
    display: none;
}
.added_product-info-header {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.added_product-info-block {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    letter-spacing: 0.045em;
    text-align: left;
}
.added_product-info-block span {
    font-weight: 700;
}
.added_product-info-block img {
    pointer-events: none;
    height: 100%;
    width: 100%;
    object-fit: contain;
    max-height: 200px;
}

@media screen and (max-width: 800px) {
    .added_product-popup {
        padding: 0;
    }
    .added_product-popup-container {
        height: 100dvh;
        width: 100%;
        max-height: none;
        max-width: none;
        padding: 20px;
        gap: 40px;
    }
    .product__main-info.popup {
        max-height: calc(100vh - 40px);
    }
    .added_product-info {
        gap: 20px;
    }
    .added_product-info-block {
        margin-top: 20px;
    }
    .added_product-info h3 br {
        display: block;
    }
    .added_product-info-header {
        gap: 10px;
    }
    .added_product-info-block {
        font-size: 14px;
        line-height: 100%;
    }
    .added_product-popup .product__main-btns.popup {
        margin: 0;
    }
}

@media screen and (max-width: 500px) {
    .card-popup-container {
        padding: 20px;
    }
    .card-popup .product__main-info-price {
        display: block;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
    }
    .product__main-btns.popup {
        margin-top: 20px;
    }
}
</style>
