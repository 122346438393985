<template>
    <a href="/" class="header-logo">
        <img :src="store?.site_info?.header?.logo" alt="" />
    </a>
    <ul class="header-navigation">
        <li
            class="header-navigation-item"
            v-for="(item, key) in store?.site_info?.header?.menu"
        >
            <template v-if="!item.children">
                <a
                    class="header-navigation-link"
                    :href="item.link"
                    :key="key"
                    >{{ item.label }}</a
                >
            </template>
            <template v-else>
                <n-dropdown
                    trigger="hover"
                    :on-update:show="
                        (is_show) => {
                            update(is_show, key);
                        }
                    "
                    :options="getOptions(item.children, key)"
                    class="header-dropdown-catalog"
                    placement="bottom-start"
                    size="huge"
                    :key="key"
                >
                    <a
                        class="header-navigation-link"
                        :class="[
                            item.link != '' ? '' : 'no-href',
                            hovers[key] ? 'open' : '',
                        ]"
                        :href="item.link == '' ? '#' : item.link"
                    >
                        {{ item.label }}
                        <ph-caret-down :size="20" />
                    </a>
                </n-dropdown>
            </template>
        </li>
    </ul>
    <a
        class="header-contacts"
        @click="YMTargetSend('Nomer')"
        :href="'tel:' + phone"
    >
        <ph-phone :size="20" />
        {{ store?.site_info?.contacts?.phone }}
    </a>
</template>

<script setup>
import { computed, ref } from "vue";
import { PhPhone, PhCaretDown } from "@phosphor-icons/vue";
import { NDropdown } from "naive-ui";
import { getMenuItem, phoneSymbolsRemove } from "@/assets/js/helper";
import { useStore } from "vuex";
import { YMTargetSend } from "@/assets/js/helper";

const store = useStore().state;
const phone = computed(() => {
    if (store?.site_info?.contacts?.phone) {
        return phoneSymbolsRemove(store?.site_info?.contacts?.phone)
    }
})


const hovers = ref({});

function getOptions(items, key) {
    let result = [];

    if (items.length > 0) {
        items.forEach((item, sub_key) => {
            result.push(getMenuItem(item, key + "_" + sub_key));
        });
    }

    return result;
}

function update(show, key) {
    hovers.value[key] = show;
}
</script>

<style>
.header-logo {
    display: flex;
    align-items: center;
}
.header-logo img {
    width: 160px;
}
.header-navigation {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 40px;
    margin: 0;
    padding: 0;
}

.header-navigation-item {
    height: max-content;
}

.header-navigation-link {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    color: currentColor;
    text-decoration: none;
    transition: color 0.5s;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.header-navigation-link svg {
    transition: rotate 0.5s, fill 0.5s;
    rotate: 0deg;
}

.header-navigation-link.open svg {
    rotate: -180deg;
}

.header-navigation-link.no-href {
    cursor: default;
}

.header-navigation-link:hover {
    color: var(--hover);
}

.header-contacts {
    display: inline-flex;
    padding: 0px 51px;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: center;
    color: currentColor;
    text-decoration: none;
    transition: 0.5s;
}

.header-contacts svg path {
    transition: 0.5s;
}

.header-contacts:hover {
    color: var(--hover);
}

.header-contacts:hover svg path {
    fill: var(--hover);
}

@media screen and (max-width: 1440px) {
    .header-navigation {
        gap: 15px;
    }

    .header-logo img {
        width: 140px;
    }
}

@media screen and (max-width: 1200px) {
    .header-logo img {
        width: 100px;
        height: auto;
    }
}
</style>
