<template>
    <div
        class="fortune-wheel-popup"
        :class="$store.state.fortune_wheel ? 'open' : ''"
    >
        <div class="fortune-wheel-popup-bg" @click="closePopup"></div>
        <div class="fortune-wheel-popup-container">
            <n-scrollbar style="max-height: 100vh">
                <svg
                    class="fortune-wheel-popup-close"
                    @click="closePopup"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M6 17L17 6"
                        stroke="var(--white)"
                        stroke-width="1.5"
                        stroke-linecap="round"
                    />
                    <path
                        d="M6 6L17 17"
                        stroke="var(--white)"
                        stroke-width="1.5"
                        stroke-linecap="round"
                    />
                </svg>
                <div class="fortune-wheel-content">
                    <div
                        class="fortune-wheel"
                        :style="
                            'background: center / 120% no-repeat url(' +
                            require('@/assets/img/wheel.svg') +
                            ')'
                        "
                    >
                        <div class="fortune-wheel-arrow">
                            <img
                                :src="require('@/assets/img/wheel-arrow.svg')"
                            />
                        </div>
                        <div class="fortune-wheel-spinner" ref="spinner"></div>
                    </div>
                    <n-form
                        class="fortune-wheel-form"
                        ref="formRef"
                        :label-width="80"
                        :model="form_data"
                        :rules="rules"
                    >
                        <div class="h2">Испытать удачу</div>
                        <div class="fortune-wheel-form-desc">
                            Введите ваше имя, номер телефона, вращайте колесо и
                            получите бонус!
                        </div>
                        <n-form-item path="name">
                            <n-input
                                v-model:value="form_data.name"
                                placeholder="Имя"
                            />
                        </n-form-item>
                        <n-form-item path="phone">
                            <n-input
                                v-model:value="form_data.phone"
                                placeholder="Телефон"
                            />
                        </n-form-item>
                        <n-form-item class="autho-confirm fortune">
                            <n-checkbox v-model:checked="form_data.confirm">
                                я даю согласие на обработку персональных данных
                            </n-checkbox>
                            <div class="autho-form-desc">
                                Нажимая кнопку зарегистрироваться подтверждаю,
                                что я ознакомлен и согласен с
                                <a href="/policy/personal">условиями оферты</a>
                                и
                                <a href="/policy/privacy">
                                    политики конфиденциальности </a
                                >.
                            </div>
                        </n-form-item>
                        <Button class="fortune-btn" type="white">
                            Вращать колесо
                        </Button>
                    </n-form>
                </div>
            </n-scrollbar>
        </div>
    </div>
</template>
<script setup>
import {
    NForm,
    NFormItem,
    NInput,
    NCheckbox,
    useMessage,
    NAlert,
} from "naive-ui";
import { computed, ref, onMounted, watch, h } from "vue";
import { useStore } from "vuex";
import Button from "./Button.vue";
import { NScrollbar } from "naive-ui";
import orderApi from "@/assets/js/api/order.js";
import { YMTargetSend, replaceNumberForPaste } from "@/assets/js/helper";

const store = useStore();
function closePopup() {
    store.commit("fortuneWheelOpen", false);
    if (JSON.parse(localStorage.getItem("gotPrize")) != true) {
        localStorage.setItem("showPrizeBtn", true);
    }
}
const prizes = ref([]);

async function init_fortune() {
    const result = await orderApi.fortune();
    prizes.value = result;
}

if (JSON.parse(localStorage.getItem("gotPrize")) != true) {
    init_fortune();
}

const message_error = useMessage();

const formRef = ref(null);

const form_data = ref({
    name: "",
    phone: "",
    message: "",
    confirm: false,
});

const rules = {
    name: {
        required: true,
        message: "Обязательное поле",
        trigger: ["input", "blur"],
    },
    phone: {
        required: true,
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (!value) {
                return new Error("Обязательное поле");
            } else {
                form_data.value.phone = replaceNumberForPaste(value);
                if (value.length < 18) {
                    return new Error("Введите существующий телефон");
                }
            }
        },
    },
};

watch(prizes, (new_value) => {
    if (new_value.length == 6) {
        const wheel = document.querySelector(".fortune-wheel");
        const spinner = wheel.querySelector(".fortune-wheel-spinner");
        const trigger = document.querySelector(".fortune-btn");

        const prizeSlice = 360 / new_value.length;
        const prizeOffset = Math.floor(180 / new_value.length);
        const spinClass = "spinning";
        const selectedClass = "selected";
        const spinnerStyles = window.getComputedStyle(spinner);

        let tickerAnim;
        let rotation = 0;
        let currentSlice = 0;
        let prizeNodes;

        const createPrizeNodes = () => {
            new_value.forEach((item, i) => {
                const rotation = prizeSlice * i * -1 - prizeOffset;
                spinner.insertAdjacentHTML(
                    "beforeend",
                    `<h4 class="fortune-wheel-sector" style="--rotate: ${rotation}deg" data-promo="${item.promo}">${item.name}</h4>`
                );
            });
        };

        const setupWheel = () => {
            createPrizeNodes();
            prizeNodes = wheel.querySelectorAll(".fortune-wheel-sector");
        };

        const spinertia = (min, max) => {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        };

        const runTickerAnimation = () => {
            const values = spinnerStyles.transform
                .split("(")[1]
                .split(")")[0]
                .split(",");
            const a = values[0];
            const b = values[1];
            let rad = Math.atan2(b, a);

            if (rad < 0) rad += 2 * Math.PI;

            const angle = Math.round(rad * (180 / Math.PI));
            const slice = Math.floor(angle / prizeSlice);

            if (currentSlice !== slice) {
                currentSlice = slice;
            }
            tickerAnim = requestAnimationFrame(runTickerAnimation);
        };

        const selectPrize = () => {
            const selected = Math.floor(rotation / prizeSlice);
            prizeNodes[selected].classList.add(selectedClass);
            store.commit(
                "setPrizePopupMessage",
                prizeNodes[selected].innerHTML
            );
            store.commit(
                "setPrizePopupPromocode",
                prizeNodes[selected].dataset.promo
            );
            store.commit("prizePopupOpen", true);
            store.commit("fortuneWheelOpen", false);
            localStorage.setItem("gotPrize", true);
            localStorage.setItem("showPrizeBtn", false);
        };

        trigger.addEventListener("click", async () => {
            await formRef.value.validate(async (errors) => {
                if (!errors) {
                    if (!form_data.value.confirm) {
                        message_error.error(
                            "Подтвердите согласие, нажмите галочку",
                            {
                                render: (props) => {
                                    return h(
                                        NAlert,
                                        {
                                            closable: props.closable,
                                            onClose: props.onClose,
                                            type: "error",
                                            style: {
                                                boxShadow:
                                                    "var(--n-box-shadow)",
                                                maxWidth: "calc(100vw - 32px)",
                                                padding: "14px",
                                                width: "480px",
                                            },
                                        },
                                        {
                                            default: () =>
                                                h("div", {
                                                    innerHTML: props.content,
                                                }),
                                        }
                                    );
                                },
                                closable: true,
                                duration: 5000,
                            }
                        );
                        return;
                    }

                    rotation = Math.floor(
                        Math.random() * 360 + spinertia(1000, 2500)
                    );
                    prizeNodes.forEach((prize) =>
                        prize.classList.remove(selectedClass)
                    );
                    wheel.classList.add(spinClass);
                    spinner.style.setProperty("--rotate", rotation);
                    runTickerAnimation();
                    trigger.disabled = true;

                    setTimeout(async () => {
                        cancelAnimationFrame(tickerAnim);
                        rotation %= 360;
                        selectPrize();
                        wheel.classList.remove(spinClass);
                        spinner.style.setProperty("--rotate", rotation);
                        trigger.disabled = false;

                        const selected = Math.floor(rotation / prizeSlice);
                        const data = {
                            name: form_data.value?.name,
                            phone: form_data.value?.phone,
                            message: "Заявка с колеса фортуны ",
                            prize: prizeNodes[selected].innerHTML,
                        };

                        const result = await orderApi.createRequest(data);
                        if (!result?.success) {
                            message_error.error("Ошибка сервера", {
                                render: (props) => {
                                    return h(
                                        NAlert,
                                        {
                                            closable: props.closable,
                                            onClose: props.onClose,
                                            type: "error",
                                            style: {
                                                boxShadow:
                                                    "var(--n-box-shadow)",
                                                maxWidth: "calc(100vw - 32px)",
                                                padding: "14px",
                                                width: "480px",
                                            },
                                        },
                                        {
                                            default: () =>
                                                h("div", {
                                                    innerHTML: props.content,
                                                }),
                                        }
                                    );
                                },
                                closable: true,
                                duration: 5000,
                            });
                            return;
                        }
                    }, 8000);
                    YMTargetSend("Udacha");
                }
            });
        });

        spinner.addEventListener("transitionend", () => {});

        setupWheel();
    }
});
</script>
<style>
/* попап */
.fortune-wheel-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    height: 100lvh;
}

.fortune-wheel-popup.open {
    opacity: 1;
    pointer-events: all;
}

.fortune-wheel-popup-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100lvh;
}

.fortune-wheel-popup-container {
    position: relative;
    background-color: var(--black);
    padding: 100px;
    width: 100%;
    height: 100%;
}

.fortune-wheel-popup-close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 10;
    transition: 0.5s;
}
/* .fortune-wheel-popup-close path {
    transition: 0.5s;
}
.fortune-wheel-popup-close:hover path {
    stroke: black;
} */

.fortune-wheel-content {
    align-items: center;
    display: flex;
    gap: 60px;
}
/* колесо */

.fortune-wheel {
    width: 745px;
    height: 745px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    position: relative;
}

.fortune-wheel > img {
    width: 100%;
    height: 100%;
}

.fortune-wheel-arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -52%;
    rotate: 90deg;
    z-index: 10;
    pointer-events: none;
}

.spinning .fortune-wheel-spinner {
    transition: transform 8s cubic-bezier(0.1, -0.01, 0, 1);
}

.fortune-wheel-spinner {
    position: relative;
    display: grid;
    align-items: center;
    transform: rotate(calc(var(--rotate, 25) * 1deg));
    border-radius: 50%;
    background: conic-gradient(
        from -90deg,
        var(--accent) 0 16.7%,
        var(--white) 0 33.3%,
        var(--accent) 0 50%,
        var(--white) 0 66.7%,
        var(--accent) 0 83.3%,
        var(--white) 0 100%
    );
    width: calc(100% - 158px);
    height: calc(100% - 158px);
    aspect-ratio: 1 / 1;
    box-shadow: inset 0px 0px 20px 10px var(--black);
    border-radius: 50%;
    translate: 80px 67px;
}

.fortune-wheel-sector {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) rotate(var(--rotate));
    display: flex;
    align-items: center;
    padding-left: 110px;
    /* width: 50%;
    height: 50%; */
    transform-origin: left;
    /* transform: rotate(var(--rotate)); */
    user-select: none;
    transition: 0.5s;
    color: var(--black);
}
.fortune-wheel-sector:nth-child(odd) {
    color: var(--white);
}
.fortune-wheel-sector.selected {
}
.fortune-wheel-arrow {
    width: 335px;
    height: 335px;
    aspect-ratio: 1 / 1;
}
.fortune-wheel-arrow img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
}
/* форма */
.fortune-wheel-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 550px;
    margin: 0 auto;
}
.fortune-wheel-form .h2 {
    margin-bottom: 20px;
    color: var(--white);
}
.fortune-wheel-form .n-form-item.n-form-item--top-labelled {
}
.n-form-item:has(.autho-form-desc) .n-form-item-feedback-wrapper {
    display: none;
}
.fortune-wheel-form .n-input:not(.n-input--textarea) .n-input__placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
}
.fortune-wheel-form .n-input .n-input__input-el {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;

    width: 100%;
    height: auto;
    line-height: normal;
}
.fortune-wheel-form .n-input .n-input-wrapper {
    padding: 16px;
}

.fortune-wheel-form .n-input .n-input__suffix {
    flex-direction: row-reverse;
    line-height: normal;
    gap: 10px;
}
.fortune-wheel-form .n-input .n-input__suffix .n-base-icon {
    width: 24px;
    height: 24px;
}
.fortune-wheel-form .n-input .n-input__suffix .n-base-icon svg {
    width: 24px;
    height: 24px;
}
.fortune-wheel-form .n-input .n-input__suffix svg {
    cursor: pointer;
}
.fortune-wheel-confirm .n-form-item-blank {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}
/* .fortune-wheel-form
    .n-form-item.fortune-wheel-confirm
    .n-form-item-feedback-wrapper {
    display: none;
} */
.fortune-wheel-form-desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
    margin-bottom: 40px;
    color: var(--white);
}

.fortune-btn {
    margin-top: 40px;
}

.fortune-wheel-form-other-methods {
    display: flex;
    justify-content: center;
    gap: 20px;
}
.fortune-wheel-forgot.n-form-item .n-form-item-blank {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
}
.fortune-wheel-forgot.n-form-item .n-form-item-blank a {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--dark_gray);
    text-decoration: none;
}
.autho-confirm.fortune .n-checkbox .n-checkbox__label {
    color: var(--white);
}
.autho-confirm.fortune .autho-form-desc {
    color: var(--dark_gray);
}
.autho-confirm.fortune .n-form-item-blank {
    margin-top: 0;
}

@media screen and (max-width: 1400px) {
    .fortune-wheel {
        width: 500px;
        height: 500px;
    }
    .fortune-wheel-spinner {
        width: calc(100% - 109px);
        height: calc(100% - 109px);
        translate: 55px 46px;
    }

    .fortune-wheel-arrow {
        width: 250px;
        height: 250px;
    }
    .fortune-wheel-sector {
        padding-left: 80px;
        font-size: 16px;
    }
}
@media screen and (max-width: 1000px) {
    .fortune-wheel-popup-container .n-checkbox .n-checkbox-box-wrapper {
        margin: 0 !important;
    }
    .fortune-wheel-form {
        gap: 10px;
    }
    .fortune-wheel-popup-container {
        padding: 40px 10px;
    }
    .fortune-wheel-content {
        margin-top: -24px;
        gap: 40px;
        flex-direction: column-reverse;
    }
    .autho-confirm.fortune .autho-form-desc,
    .autho-confirm.fortune .autho-form-desc a {
        font-size: 12px;
        font-weight: 400;
        line-height: 16.39px;
        letter-spacing: 0.045em;
        text-align: left;
    }
    .fortune-wheel-form-desc {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.12px;
        text-align: left;
    }
    .fortune-wheel-form .h2 {
        margin-bottom: 10px;
    }
    .fortune-wheel-popup-close {
        position: relative;
        top: 0;
        left: calc(100% - 40px);
    }
}
@media screen and (max-width: 550px) {
    .fortune-wheel {
        height: 320px;
        width: 320px;
    }
    .fortune-wheel-sector {
        font-size: 12px;
        font-weight: 600;
        line-height: 14.63px;
        letter-spacing: 0.045em;
        padding-left: 35px;
    }
    .fortune-wheel-arrow {
        width: 100px;
        height: 100px;
    }

    .fortune-wheel-spinner {
        width: calc(100% - 74px);
        height: calc(100% - 74px);
        translate: 37px 31px;
    }
}
</style>
