<template>
    <div class="contacts">
        <Contacts />
    </div>
</template>
<script setup>
import Contacts from "@/components/contacts/Contacts.vue";
// import { mapMutations } from "vuex";
import { useStore } from "vuex";

const store = useStore();

store.commit("setBreadcrumb", [
    { name: "Главная", link: "/" },
    { name: "Контакты" },
]);

store.commit("setLoading", true);
setTimeout(() => {
    store.commit("setLoading", false);
}, 1500);
</script>
