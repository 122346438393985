<template>
    <section id="profile">
        <div class="container">
            <h1>{{ tabs_title[active_tab] }}</h1>
            <n-collapse-transition :show="active_tab == 'history'">
                <div class="sort-history pc">
                    <div class="sort-history-left">
                        <n-input
                            :allow-input="onlyNumber"
                            v-model:value="search"
                            @input="searchInHistory"
                            class="search-input"
                            placeholder="Номер документа"
                        >
                            <template #suffix>
                                <ph-magnifying-glass :size="20" />
                            </template>
                        </n-input>
                        <div class="data-pickers">
                            <div class="data-picker-item">
                                <n-date-picker
                                    start-placeholder="от"
                                    end-placeholder="до"
                                    separator="-"
                                    @update:value="updateDate"
                                    type="daterange"
                                    clearable
                                    style="width: 100%"
                                />
                            </div>
                        </div>
                        <n-select
                            @update:value="updateFilter"
                            class="catalog-sort-select history"
                            :options="options"
                            v-model:value="currentValue"
                            placement="bottom-end"
                            :menu-props="menu_styles"
                            style="width: max-content"
                        >
                            <template #arrow>
                                <ph-arrows-down-up :size="20" />
                            </template>
                        </n-select>
                    </div>
                    <div class="sort-history-right pc">
                        <h4>Фильтрация по:</h4>
                        <n-select
                            @update:value="updateFilter"
                            class="catalog-sort-select history"
                            :options="options"
                            v-model:value="currentValue"
                            placement="bottom-end"
                            :menu-props="menu_styles"
                            style="width: max-content"
                        >
                            <template #arrow>
                                <ph-caret-down :size="20" />
                            </template>
                        </n-select>
                    </div>
                    <div class="sort-history-right mob">
                        <n-select
                            @update:value="updateFilter"
                            class="catalog-sort-select history"
                            :options="options"
                            v-model:value="currentValue"
                            placement="bottom-end"
                            :menu-props="menu_styles"
                            style="width: max-content"
                        >
                            <template #arrow>
                                <ph-caret-down :size="20" />
                            </template>
                        </n-select>
                    </div>
                </div>
                <div class="sort-history mob">
                    <n-input
                        :allow-input="onlyNumber"
                        v-model:value="search"
                        @input="searchInHistory"
                        class="search-input"
                        placeholder="Номер документа"
                    >
                        <template #suffix>
                            <ph-magnifying-glass :size="20" />
                        </template>
                    </n-input>
                    <div class="data-pickers">
                        <div class="data-picker-item mobile">
                            <n-date-picker
                                placeholder="от"
                                @update:value="updateDateMobileAfter"
                                clearable
                                style="width: 100%"
                                format="d.MM.yy"
                                :is-date-disabled="disableAfter"
                            />
                            -
                            <n-date-picker
                                placeholder="до"
                                @update:value="updateDateMobileBefore"
                                clearable
                                style="width: 100%"
                                format="d.MM.yy"
                                :is-date-disabled="disableBefore"
                            />
                        </div>
                    </div>
                    <n-select
                        @update:value="updateFilter"
                        class="catalog-sort-select history"
                        :options="options"
                        v-model:value="currentValue"
                        placement="bottom-end"
                        :menu-props="menu_styles"
                        style="width: max-content"
                    >
                        <template #arrow>
                            <ph-arrows-down-up :size="20" />
                        </template>
                    </n-select>
                </div>
            </n-collapse-transition>
            <n-tabs
                :value="active_tab"
                @update:value="updateTabs"
                type="line"
                animated
                class="profile-tabs"
            >
                <n-tab-pane name="history" tab="История заказов">
                    <div v-if="history?.length > 0" class="history-list">
                        <HorizontalCard
                            v-for="item in history"
                            :card="item"
                            :type="'history'"
                        />
                    </div>
                    <div class="not-found" v-else>
                        <img
                            :src="require('@/assets/img/history-img.png')"
                            alt=""
                        />
                        <div class="not-found-text">
                            <h3>
                                Заказы по выбранным параметрам<br />
                                не найдены
                            </h3>
                            <div class="not-found-btns">
                                <Button :link="'/catalog'">В каталог</Button>
                                <Button :link="'/'" type="border">
                                    На главную
                                </Button>
                            </div>
                        </div>
                    </div>
                </n-tab-pane>
                <n-tab-pane name="address" tab="Адрес доставки">
                    <div class="profile-address" v-if="address_data">
                        <n-form
                            ref="addressRef"
                            :model="address_data"
                            :rules="address_rules"
                            :show-label="false"
                            class="address-list"
                        >
                            <n-form-item path="city">
                                <n-input
                                    v-model:value="address_data.city"
                                    placeholder="Город"
                                />
                            </n-form-item>
                            <n-form-item path="street">
                                <n-input
                                    v-model:value="address_data.street"
                                    placeholder="Улица"
                                />
                            </n-form-item>
                            <n-form-item path="number">
                                <n-input
                                    v-model:value="address_data.number"
                                    placeholder="Дом"
                                />
                            </n-form-item>
                            <n-form-item path="doorway">
                                <n-input
                                    v-model:value="address_data.doorway"
                                    placeholder="Подъезд"
                                />
                            </n-form-item>
                            <n-form-item path="floor">
                                <n-input
                                    v-model:value="address_data.floor"
                                    placeholder="Этаж"
                                />
                            </n-form-item>
                            <n-form-item path="flat">
                                <n-input
                                    v-model:value="address_data.flat"
                                    placeholder="Квартира"
                                />
                            </n-form-item>
                            <Button @click="updateAddress">
                                Сохранить изменение
                            </Button>
                        </n-form>
                    </div>
                    <div class="not-found" v-else>
                        <PhTruck
                            color="var(--light_gray)"
                            class="not-found-icon"
                        />
                        <div class="not-found-text">
                            <h3>У вас пока нет добавленных адресов</h3>
                            <p>Чтобы добавить адрес, оформите заказ на него</p>
                            <div class="not-found-btns">
                                <Button :link="'/catalog'">В каталог</Button>
                                <Button :link="'/'" type="border">
                                    На главную
                                </Button>
                            </div>
                        </div>
                    </div>
                </n-tab-pane>
                <n-tab-pane name="settings" tab="Настройки">
                    <n-form
                        ref="settingsRef"
                        :model="settings_data"
                        :rules="settings_rules"
                        :show-label="false"
                    >
                        <div class="profile-settings">
                            <n-form-item path="last_name">
                                <n-input
                                    v-model:value="settings_data.last_name"
                                    placeholder="Фамилия"
                                />
                            </n-form-item>
                            <n-form-item path="first_name">
                                <n-input
                                    v-model:value="settings_data.first_name"
                                    placeholder="Имя"
                                />
                            </n-form-item>
                            <n-form-item path="phone">
                                <n-input
                                    v-model:value="settings_data.phone"
                                    placeholder="Номер телефона"
                                />
                            </n-form-item>
                            <n-form-item path="email">
                                <n-input
                                    v-model:value="settings_data.email"
                                    placeholder="Email"
                                />
                            </n-form-item>
                            <n-checkbox
                                class="reset-password"
                                v-model:checked="change_password_value"
                            >
                                Сменить пароль
                            </n-checkbox>
                        </div>
                        <n-form
                            ref="settingsPasswordsRef"
                            :model="settings_data_passwords"
                            :rules="settings_passwords_rules"
                            :show-label="false"
                            class="profile-settings hide"
                            v-show="change_password_value"
                        >
                            <n-form-item path="old_password">
                                <n-input
                                    type="password"
                                    show-password-on="click"
                                    placeholder="Старый пароль"
                                    v-model:value="
                                        settings_data_passwords.old_password
                                    "
                                />
                            </n-form-item>
                            <div class="pc"></div>
                            <n-form-item path="new_password">
                                <n-input
                                    show-password-on="click"
                                    type="password"
                                    @input="maskPassword"
                                    v-model:value="
                                        settings_data_passwords.new_password
                                    "
                                    placeholder="Новый пароль"
                                >
                                    <template #suffix>
                                        <n-tooltip
                                            class="password_rules"
                                            placement="bottom-start"
                                            trigger="click"
                                        >
                                            <template #trigger>
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2475 14.1873 19.3775 16.2843 17.8309 17.8309C16.2843 19.3775 14.1873 20.2475 12 20.25ZM11.25 12.75V7.5C11.25 7.30109 11.329 7.11032 11.4697 6.96967C11.6103 6.82902 11.8011 6.75 12 6.75C12.1989 6.75 12.3897 6.82902 12.5303 6.96967C12.671 7.11032 12.75 7.30109 12.75 7.5V12.75C12.75 12.9489 12.671 13.1397 12.5303 13.2803C12.3897 13.421 12.1989 13.5 12 13.5C11.8011 13.5 11.6103 13.421 11.4697 13.2803C11.329 13.1397 11.25 12.9489 11.25 12.75ZM13.125 16.125C13.125 16.3475 13.059 16.565 12.9354 16.75C12.8118 16.935 12.6361 17.0792 12.4305 17.1644C12.225 17.2495 11.9988 17.2718 11.7805 17.2284C11.5623 17.185 11.3618 17.0778 11.2045 16.9205C11.0472 16.7632 10.94 16.5627 10.8966 16.3445C10.8532 16.1262 10.8755 15.9 10.9606 15.6945C11.0458 15.4889 11.19 15.3132 11.375 15.1896C11.56 15.066 11.7775 15 12 15C12.2984 15 12.5845 15.1185 12.7955 15.3295C13.0065 15.5405 13.125 15.8266 13.125 16.125Z"
                                                        fill="#C8C8C8"
                                                    />
                                                </svg>
                                            </template>
                                            <ul>
                                                <li>
                                                    <span></span>Не менее 8
                                                    символов
                                                </li>
                                                <li>
                                                    <span></span>Минимум одна
                                                    цифра
                                                </li>
                                                <li>
                                                    <span> </span>Минимум одна
                                                    заглавная
                                                </li>
                                                <li>
                                                    <span> </span>Без пробелов
                                                </li>
                                            </ul>
                                        </n-tooltip>
                                    </template>
                                </n-input>
                            </n-form-item>
                            <n-form-item path="repeat_password">
                                <n-input
                                    show-password-on="click"
                                    type="password"
                                    v-model:value="
                                        settings_data_passwords.repeat_password
                                    "
                                    placeholder="Повторить пароль"
                                />
                            </n-form-item>
                        </n-form>
                        <div class="profile-settings">
                            <Button class="setting-btn" @click="updateSettings">
                                Сохранить изменение
                            </Button>
                        </div>
                    </n-form>
                </n-tab-pane>
            </n-tabs>
        </div>
    </section>
</template>
<script setup>
import {
    NTabs,
    NDatePicker,
    NTabPane,
    NCollapseTransition,
    NInput,
    NCheckbox,
    NForm,
    NFormItem,
    NSelect,
    useMessage,
    NAlert,
    NTooltip,
} from "naive-ui";
import HorizontalCard from "@/components/UI/HorizontalCard.vue";
import {
    PhMagnifyingGlass,
    PhCaretDown,
    PhTruck,
    PhArrowsDownUp,
} from "@phosphor-icons/vue";
import Button from "@/components/UI/Button.vue";
import { computed, h, ref, watch } from "vue";
import { useRouter } from "vue-router";
import userApi from "@/assets/js/api/user.js";
import { replaceNumberForPaste, validateEmail } from "@/assets/js/helper";

import { useStore } from "vuex";

const store = useStore();

store.commit("setLoading", true);
setTimeout(() => {
    store.commit("setLoading", false);
}, 1500);

if (useRouter().currentRoute.value.params.slug == "history") {
    store.commit("setBreadcrumb", [
        { name: "Главная", link: "/" },
        { name: "История заказов" },
    ]);
}
if (useRouter().currentRoute.value.params.slug == "address") {
    store.commit("setBreadcrumb", [
        { name: "Главная", link: "/" },
        { name: "Адрес доставки" },
    ]);
}
if (useRouter().currentRoute.value.params.slug == "settings") {
    store.commit("setBreadcrumb", [
        { name: "Главная", link: "/" },
        { name: "Настройки" },
    ]);
}

const before = ref(null);
const after = ref(null);
const status = ref(null);

const message_error = useMessage();

const tabs_title = {
    history: "История заказов",
    address: "Адрес",
    settings: "Настройки",
};

function updateDate(value) {
    if (value) {
        before.value = value[1];
        after.value = value[0];
    } else {
        before.value = null;
        after.value = null;
    }
    update();
}

function updateDateMobileAfter(value) {
    if (value) {
        after.value = value;
    }

    update();
}

function updateDateMobileBefore(value) {
    if (value) {
        before.value = value;
    }

    update();
}

function disableAfter(value) {
    if (before.value) {
        return before.value <= value;
    }
}
function disableBefore(value) {
    if (after.value) {
        return after.value >= value;
    }
}

function updateFilter(value) {
    status.value = value;

    update();
}

async function update() {
    if (before.value || after.value) {
        if (before.value == after.value) {
            before.value = after.value + 86400000;
        } else if (before.value) {
            before.value = before.value + 86400000;
        }
    }

    const data = {
        before: before.value ?? false,
        after: after.value ?? false,
        status: status.value ?? "all",
    };

    const result = await userApi.orderHistory(data);
    history.value = result.products;
}
const menu_styles = { class: "catalog-sort-select-menu" };

const options = [
    {
        label: "Все",
        value: "all",
    },
    {
        label: "В обработке",
        value: "pending",
    },
    {
        label: "Подтверждён",
        value: "accepted",
    },
    {
        label: "Закрыт",
        value: "closed",
    },
    {
        label: "Отменён",
        value: "cancelled",
    },
];

const currentValue = ref(null);
currentValue.value = options[0].label;

const change_password_value = ref(false);

// история
const search = ref(null);
function onlyNumber(value) {
    if (!value || !/\D/.test(value)) {
        return true;
    } else {
        return false;
    }
}

async function searchInHistory(value) {
    const data = {
        order_id: value,
    };
    if (value.length >= 3) {
        const result = await userApi.orderHistory(data);

        history.value = result.products;
    } else {
        const result = await userApi.orderHistory();

        history.value = result?.products;
    }
}
const history = ref(null);

const active_tab = ref(useRouter().currentRoute.value.params.slug);

watch(active_tab, (new_value) => {
    if (new_value == "history") {
        store.commit("setBreadcrumb", [
            { name: "Главная", link: "/" },
            { name: "История заказов" },
        ]);
    }
    if (new_value == "address") {
        store.commit("setBreadcrumb", [
            { name: "Главная", link: "/" },
            { name: "Адрес доставки" },
        ]);
    }
    if (new_value == "settings") {
        store.commit("setBreadcrumb", [
            { name: "Главная", link: "/" },
            { name: "Настройки" },
        ]);
    }
});

function updateTabs(value) {
    active_tab.value = value;
}

async function orderHistory() {
    const result = await userApi.orderHistory();

    history.value = result?.products;
}
orderHistory();
// адрес
async function getAddress() {
    const result = await userApi.getSettings("address");

    if (result?.success) {
        let data = result?.result;
        address_data.value.city = data.city;
        address_data.value.street = data.street;
        address_data.value.number = data.number;
        address_data.value.doorway = data.doorway;
        address_data.value.floor = data.floor;
        address_data.value.flat = data.flat;
    }
}
getAddress();

const addressRef = ref(null);
const address_data = ref({
    city: "",
    street: "",
    number: "",
    doorway: "",
    floor: "",
    flat: "",
});
const address_rules = {
    city: {
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (!value) {
                return new Error("Пустое поле!");
            }
        },
    },
    street: {
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (!value) {
                return new Error("Пустое поле!");
            }
        },
    },
    number: {
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (!value) {
                return new Error("Пустое поле!");
            }
        },
    },
    doorway: {
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (!value) {
                return new Error("Пустое поле!");
            }
        },
    },
    floor: {
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (!value) {
                return new Error("Пустое поле!");
            }
        },
    },
    flat: {
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (!value) {
                return new Error("Пустое поле!");
            }
        },
    },
};

async function updateAddress() {
    const data = {
        city: address_data.value.city,
        street: address_data.value.street,
        number: address_data.value.number,
        doorway: address_data.value.doorway,
        floor: address_data.value.floor,
        flat: address_data.value.flat,
    };

    await addressRef.value.validate(async (errors) => {
        if (!errors) {
            const result = await userApi.userUpdate(data);

            if (result?.success) {
                message_error.success("Изменения успешно сохранены!", {
                    render: (props) => {
                        return h(
                            NAlert,
                            {
                                closable: props.closable,
                                onClose: props.onClose,
                                type: "success",
                                style: {
                                    boxShadow: "var(--n-box-shadow)",
                                    maxWidth: "calc(100vw - 32px)",
                                    padding: "14px",
                                    width: "480px",
                                },
                            },
                            {
                                default: () =>
                                    h("div", { innerHTML: props.content }),
                            }
                        );
                    },
                    closable: true,
                    duration: 5000,
                });
            } else {
                message_error.error("Ошибка сервера", {
                    render: (props) => {
                        return h(
                            NAlert,
                            {
                                closable: props.closable,
                                onClose: props.onClose,
                                type: "error",
                                style: {
                                    boxShadow: "var(--n-box-shadow)",
                                    maxWidth: "calc(100vw - 32px)",
                                    padding: "14px",
                                    width: "480px",
                                },
                            },
                            {
                                default: () =>
                                    h("div", { innerHTML: props.content }),
                            }
                        );
                    },
                    closable: true,
                    duration: 5000,
                });
                return;
            }
        }
    });
}

// Настройки
const pattern = /[а-яА-ЯЁёa-zA-Z@ ]*$/g;

const settingsRef = ref(null);
const settings_data = ref({
    last_name: "",
    first_name: "",
    phone: "",
    email: "",
});

const settingsPasswordsRef = ref(null);
const settings_data_passwords = ref({
    old_password: "",
    new_password: "",
    repeat_password: "",
});

const settings_rules = {
    last_name: {
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (!value) {
                return new Error("Пустое поле!");
            }
        },
    },
    first_name: {
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (!value) {
                return new Error("Пустое поле!");
            }
        },
    },
    phone: {
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (!value) {
                return new Error("Пустое поле!");
            } else {
                settings_data.value.phone = replaceNumberForPaste(value);
                if (value.length < 18) {
                    return new Error("Введите существующий телефон");
                }
            }
        },
    },
    email: {
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (!value) {
                return new Error("Пустое поле!");
            }
            if (value.charAt().match(pattern) != null) {
                if (!validateEmail(value)) {
                    return new Error("Введите существующую почту");
                }
            }
        },
    },
};

const settings_passwords_rules = {
    old_password: {
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (!value) {
                return new Error("Пустое поле!");
            }
        },
    },
    new_password: {
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (!value) {
                return new Error("Пустое поле!");
            }
            if (value.match(/[а-яА-ЯЁё]+/g)) {
                return new Error("Пароль не должен содержать кириллицу");
            }
            if (value.length < 8) {
                return new Error("Пароль должен содержать не менее 8 символов");
            }
            if (!value.match(/\d+/)) {
                return new Error("Пароль должен содержать хотя бы 1 цифру");
            }
            if (!value.match(/[A-Z]+/g)) {
                return new Error(
                    "Пароль должен содержать хотя бы 1 заглавную букву"
                );
            }
            if (value.match(/ /g)) {
                return new Error("Пароль не должен содержать пробелы");
            }
        },
    },
    repeat_password: {
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (settings_data_passwords.value.new_password == "") {
                return new Error("Сначала введите пароль");
            }
            if (!value) {
                return new Error("Повторите пароль");
            }
            if (settings_data_passwords.value.new_password != value) {
                return new Error("Пароли не совпадают");
            }
        },
    },
};

async function getSettings() {
    const result = await userApi.getSettings("user");

    if (result?.success) {
        let data = result?.result;
        settings_data.value.last_name = data.last_name;
        settings_data.value.first_name = data.first_name;
        settings_data.value.phone = data.phone;
        settings_data.value.email = data.email;
    }
}
getSettings();

function maskPassword() {
    settings_data_passwords.value.new_password =
        settings_data_passwords.value.new_password.replace(
            /[^0-9a-zA-Z*$]/gi,
            ""
        );
}

function resetPassword() {
    settings_data_passwords.value.old_password = "";
    settings_data_passwords.value.new_password = "";
    settings_data_passwords.value.repeat_password = "";
}

async function updateSettings() {
    if (!change_password_value.value) {
        const data = {
            last_name: settings_data.value.last_name,
            first_name: settings_data.value.first_name,
            phone: settings_data.value.phone,
            email: settings_data.value.email,
        };

        await settingsRef.value.validate(async (errors) => {
            if (!errors) {
                const result = await userApi.userUpdate(data);

                if (result?.success) {
                    message_error.success("Изменения успешно сохранены!", {
                        render: (props) => {
                            return h(
                                NAlert,
                                {
                                    closable: props.closable,
                                    onClose: props.onClose,
                                    type: "success",
                                    style: {
                                        boxShadow: "var(--n-box-shadow)",
                                        maxWidth: "calc(100vw - 32px)",
                                        padding: "14px",
                                        width: "480px",
                                    },
                                },
                                {
                                    default: () =>
                                        h("div", { innerHTML: props.content }),
                                }
                            );
                        },
                        closable: true,
                        duration: 5000,
                    });
                } else {
                    message_error.error(result?.message, {
                        render: (props) => {
                            return h(
                                NAlert,
                                {
                                    closable: props.closable,
                                    onClose: props.onClose,
                                    type: "error",
                                    style: {
                                        boxShadow: "var(--n-box-shadow)",
                                        maxWidth: "calc(100vw - 32px)",
                                        padding: "14px",
                                        width: "480px",
                                    },
                                },
                                {
                                    default: () =>
                                        h("div", { innerHTML: props.content }),
                                }
                            );
                        },
                        closable: true,
                        duration: 5000,
                    });
                    return;
                }
            }
        });
    } else {
        const data = {
            last_name: settings_data.value.last_name,
            first_name: settings_data.value.first_name,
            phone: settings_data.value.phone,
            email: settings_data.value.email,
            password: settings_data_passwords.value.old_password,
            new_password: settings_data_passwords.value.new_password,
        };

        await settingsPasswordsRef.value.validate(async (errors) => {
            if (!errors) {
                const result = await userApi.userUpdate(data);

                if (result?.success) {
                    resetPassword();
                    change_password_value.value = false;
                    message_error.success("Изменения успешно сохранены!", {
                        render: (props) => {
                            return h(
                                NAlert,
                                {
                                    closable: props.closable,
                                    onClose: props.onClose,
                                    type: "success",
                                    style: {
                                        boxShadow: "var(--n-box-shadow)",
                                        maxWidth: "calc(100vw - 32px)",
                                        padding: "14px",
                                        width: "480px",
                                    },
                                },
                                {
                                    default: () =>
                                        h("div", { innerHTML: props.content }),
                                }
                            );
                        },
                        closable: true,
                        duration: 5000,
                    });
                } else {
                    message_error.error(result?.message, {
                        render: (props) => {
                            return h(
                                NAlert,
                                {
                                    closable: props.closable,
                                    onClose: props.onClose,
                                    type: "error",
                                    style: {
                                        boxShadow: "var(--n-box-shadow)",
                                        maxWidth: "calc(100vw - 32px)",
                                        padding: "14px",
                                        width: "480px",
                                    },
                                },
                                {
                                    default: () =>
                                        h("div", { innerHTML: props.content }),
                                }
                            );
                        },
                        closable: true,
                        duration: 5000,
                    });
                    return;
                }
            }
        });
    }
}
</script>
<style>
.n-base-selection .n-base-suffix,
.n-base-selection .n-base-loading .n-base-loading__placeholder {
    position: static;
    transition: 0.5s;
    transform: none;
}

.profile-settings .n-input .n-input__suffix {
    flex-direction: row-reverse;
    line-height: normal;
    gap: 10px;
}
.profile-settings .n-input .n-input__suffix svg {
    cursor: pointer;
}
.profile-settings .n-input .n-input__suffix .n-base-icon svg,
.profile-settings .n-input .n-input__suffix .n-base-icon {
    width: 24px;
    height: 24px;
}
@media screen and(min-width:1000px) {
    .n-base-selection--active .n-base-suffix {
        rotate: -180deg;
    }
}

.catalog-sort-select.history
    .n-base-selection
    .n-base-selection-label
    .n-base-selection-input {
    padding: 0;
}
.catalog-sort-select.history .n-base-selection .n-base-selection-label {
    gap: 5px;
}

.catalog-sort-select.history .n-base-selection .n-base-selection-label div,
.catalog-sort-select.history .n-base-selection .n-base-selection-label svg {
    transition: 0.5s;
}

@media screen and (min-width: 1000px) {
    .catalog-sort-select.history
        .n-base-selection
        .n-base-selection-label:hover
        div {
        color: var(--hover);
    }
    .catalog-sort-select.history
        .n-base-selection
        .n-base-selection-label:hover
        svg {
        fill: var(--hover);
    }
    .search-input:hover svg {
        opacity: 1;
    }
    .search-input:hover {
        border-color: var(--hover);
    }
}

#profile h1 {
    margin-bottom: 60px;
}
.sort-history.mob {
    display: none;
}
.sort-history .data-pickers .n-input {
    /* --n-border: none !important;
    --n-border-active: none !important;
    --n-border-focus: none !important;
    --n-border-hover: none !important;
    --n-border-radius: none !important;
    --n-box-shadow-active: none !important;
    --n-box-shadow-focus: none !important;
    --n-box-shadow-hover: none !important;
    --n-padding-single-right: 40px !important; */
    width: max-content;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.sort-history-left {
    display: flex;
    flex-direction: row;
    gap: 30px;
}
.sort-history-left .catalog-sort-select {
    display: none;
}

.search-input {
    position: relative;
    display: flex;
    gap: 10px;
    transition: 0.5s;
}
.search-input.n-input:not(.n-input--autosize) {
    width: 320px;
}

.search-input svg {
    opacity: 0.5;
    transition: 0.5s;
}

.search-input:has(input:focus) svg {
    opacity: 1;
}

.search-input:has(input:focus) {
    border-color: var(--focus);
}

.search-input input {
    border: none;
    outline: none;
    width: 258px;
    height: 22px;
}

.search-input input:focus {
    border: none;
}

.sort-history .data-pickers .n-input .n-input__suffix {
    display: none;
}

.sort-history .n-input .n-input__border,
.sort-history .n-input .n-input__state-border,
.sort-history .n-input:not(.n-input--disabled):hover .n-input__state-border,
.sort-history
    .n-input:not(.n-input--disabled).n-input--focus
    .n-input__state-border {
    border: none;
}

.sort-history .n-input-wrapper {
    padding: 0px;
    border: 1px solid rgb(223, 223, 223);
    border-radius: 5px;
    padding: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 4.5%;
    cursor: pointer;
    transition: 0.5s;
}
.sort-history .data-picker-item .n-input-wrapper {
    width: 200px;
}

.n-input-wrapper:hover {
    border-color: var(--hover);
}

.n-input-wrapper:has(input:focus) {
    border-color: var(--accent);
}

.sort-history .n-input-wrapper input {
    width: 100%;
    cursor: pointer;
}

.n-input.n-input--pair .n-input__input-el,
.n-input.n-input--pair .n-input__placeholder {
    text-align: left;
}

.sort-history .data-picker-item .n-input-wrapper::after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    aspect-ratio: 1 / 1;
    background-image: url("../assets/img/date_blanck.svg");
    opacity: 0.5;
    transition: 0.5s;
}

.sort-history .n-input-wrapper:hover::after {
    opacity: 1;
}

.sort-history .n-input-wrapper:has(input:focus)::after {
    opacity: 1;
}

.sort-history {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
}

.sort-history-right {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 4.5%;
    display: flex;
    align-items: center;
    gap: 20px;
    position: relative;
}

.sort-history-right-container {
    position: absolute;
    top: 24px;
    right: 0px;
}

.sort-history-right-drop {
    display: grid;
    grid-template-rows: 0fr;
    transition: 0.5s;
}

.sort-history-right-drop.drop {
    grid-template-rows: 1fr;
    padding-bottom: 8px;
}

.sort-history-right-list {
    padding: 0px 10px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
    background: var(--white);
    width: 200px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-height: 0px;
    transition: 0.8s;
    overflow: hidden;
}

.sort-history-right-drop.drop .sort-history-right-list {
    padding: 10px 10px;
}

.sort-history-right-item {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 4.5%;
    transition: 0.5s;
    z-index: 10;
    cursor: pointer;
}

.sort-history-right-item:hover {
    color: var(--hover);
}

.sort-history-right-item.active {
    color: var(--hover);
}

.sort-history-right-head {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    cursor: pointer;
}

.sort-history-right-head svg {
    transition: 0.5s;
}

.sort-history-right-head svg.drop {
    transform: rotate(180deg);
}

.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
}
.not-found-icon {
    height: 220px;
    width: 220px;
}

.not-found-btns {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.not-found-btns button {
    padding: 20px 120px;
}

.not-found-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.not-found-text h3 {
    text-align: center;
}

.address-list,
.profile-settings {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
    max-width: 886px;
}
.profile-settings.hide {
    margin-top: 30px;
}
.address-list input {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 4.5%;
    padding: 16px;
    width: 100%;
    border: 1px solid var(--gray);
    border-radius: 5px;
    background: var(--white);
    outline: none;
    transition: 0.5s;
}

.address-list .n-form-item {
    position: relative;
}

.address-list .n-form-item .n-form-item-feedback-wrapper {
    position: absolute;
    top: calc(100% + 3px);
    padding: 0;
    font-family: Manrope;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.045em;
}

.address-list input:hover {
    border-color: var(--hover);
}

.address-list input:focus {
    border-color: var(--focus);
}

.address-list button {
    margin-top: 40px;
}

.reset-password {
    width: max-content;
    grid-column: 1 / span 2;
}
.reset-password.n-checkbox.n-checkbox--show-label {
    line-height: var(--n-size);
}
.password-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.password-input svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
}

.password-input input {
    border-color: var(--gray);
}
.profile-settings .n-input .n-input__input-el,
.address-list .n-input .n-input__input-el {
    height: auto;
    line-height: normal;
}
.profile-settings .n-input .n-input-wrapper,
.address-list .n-input .n-input-wrapper {
    padding: 16px;
}
.profile-settings .n-form-item.n-form-item--top-labelled.n-form-item--no-label {
    position: relative;
}
.profile-settings .n-form-item .n-form-item-feedback-wrapper {
    position: absolute;
    top: calc(100% + 3px);
    padding: 0;
}

.setting-btn {
    margin-top: 30px;
}
.profile-tabs.n-tabs.n-tabs--top .n-tab-pane {
    padding-top: 60px;
}
.sort-history-right.mob {
    display: none;
}
@media screen and (max-width: 1200px) {
    .catalog-sort-select.history .n-base-selection-input__content {
        display: block;
    }
    .catalog-sort-select.history .n-base-selection .n-base-suffix {
        width: 20px;
        height: 20px;
        position: static;
    }
    .catalog-sort-select.history .n-base-selection .n-base-suffix div,
    .catalog-sort-select.history .n-base-selection .n-base-suffix i,
    .catalog-sort-select.history .n-base-selection .n-base-suffix svg {
        width: 100%;
        height: 100%;
    }
}
@media screen and (max-width: 1000px) {
    .data-picker-item.mobile {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .not-found-text h3 br {
        display: none;
    }
    .sort-history-right {
        display: none;
    }
    .sort-history-left .catalog-sort-select.history {
        display: block;
        width: 52px !important;
        height: 52px;
        aspect-ratio: 1 / 1;
    }
    .sort-history-left .catalog-sort-select.history > * {
        width: 100%;
        height: 100%;
        aspect-ratio: 1 / 1;
    }
    .catalog-sort-select.history .n-base-selection .n-base-selection__border,
    .catalog-sort-select.history
        .n-base-selection
        .n-base-selection__state-border {
        border: var(--n-border);
    }
    .catalog-sort-select.history
        .n-base-selection:active
        .n-base-selection__border,
    .catalog-sort-select.history
        .n-base-selection:active
        .n-base-selection__state-border {
        border: var(--n-border);
    }
    .sort-history-left
        .catalog-sort-select.history
        .n-base-selection
        .n-base-selection-label
        .n-base-selection-input
        .n-base-selection-input__content {
        display: none;
    }
    .catalog-sort-select.history .n-base-selection .n-base-selection-label {
        height: 100%;
        justify-content: center;
    }
    .catalog-sort-select.history
        .n-base-selection
        .n-base-selection-label
        .n-base-selection-input {
        display: none;
    }

    .catalog-sort-select.history
        .n-base-selection:not(
            .n-base-selection--disabled
        ).n-base-selection--active
        .n-base-selection__state-border,
    .n-base-selection:not(.n-base-selection--disabled).n-base-selection--focus
        .n-base-selection__state-border {
        box-shadow: none;
    }
    .catalog-sort-select.history
        .n-base-selection--active
        .n-base-selection__state-border {
        border: var(--n-border-hover);
    }
    .catalog-sort-select.history .n-base-selection--active svg {
        fill: var(--hover);
    }
    .sort-history.pc {
        display: none;
    }
    .sort-history.mob {
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 1fr 52px;
        gap: 10px 5px;
    }
    .sort-history.mob .search-input {
        grid-column: 1 / span 2;
        width: 100%;
    }
    .sort-history.mob .data-picker-item .n-input-wrapper {
        width: 100%;
    }
    .sort-history.mob .data-pickers .n-input {
        width: 100%;
    }
    .sort-history.mob .catalog-sort-select.history,
    .sort-history.mob .catalog-sort-select.history .n-base-selection {
        width: 100% !important;
        height: 100% !important;
    }
}
@media screen and (max-width: 800px) {
    #profile h1 {
        margin-bottom: 40px;
    }
    .profile-tabs.n-tabs .n-tabs-wrapper {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .profile-tabs.n-tabs .n-tabs-nav-scroll-content {
        justify-content: center;
    }
    .profile-tabs.n-tabs .n-tabs-tab-wrapper {
        width: 100%;
    }
    .profile-tabs.n-tabs
        .n-tabs-nav.n-tabs-nav--line-type.n-tabs-nav--top
        .n-tabs-bar {
        display: none;
    }
    .profile-tabs.n-tabs.n-tabs--line-type .n-tabs-tab.n-tabs-tab--active {
        border-bottom: 1px solid var(--accent);
    }
    .profile-tabs.n-tabs.n-tabs--line-type .n-tabs-tab {
        border-bottom: 1px solid transparent;
        width: 100%;
        justify-content: center;
    }

    .profile-settings,
    .address-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .pc {
        display: none;
    }
    .profile-settings.hide,
    .setting-btn {
        margin-top: 20px;
    }
    .setting-btn {
        padding: 10px;
    }
    .reset-password {
        margin-top: 10px;
    }
    .profile-tabs.n-tabs.n-tabs--top .n-tab-pane {
        padding-top: 20px;
    }
    .not-found {
        gap: 40px;
    }
    .not-found-text {
        width: 100%;
    }
    .not-found-text > * {
        text-align: center;
    }
    .not-found-icon {
        width: 172px;
        height: 172px;
    }
    .not-found-btns {
        margin-top: 20px;
        gap: 10px;
        flex-direction: column;
        width: 100%;
    }
    .not-found-btns button {
        padding: 10px;
    }
}
</style>
