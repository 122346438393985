<template>
    <div
        class="fortune-anchor"
        :class="show ? 'show' : ''"
        @click="openFortune"
    >
        <img :src="require('@/assets/img/gift.png')" />
    </div>
</template>
<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
const store = useStore();

const show = ref(false);

watch(store.state, () => {
    let ls = JSON.parse(localStorage.getItem("showPrizeBtn"));
    if (ls != true) {
        show.value = false;
    } else {
        show.value = true;
    }
});

function openFortune() {
    store.commit("fortuneWheelOpen", true);
}
</script>
<style>
.fortune-anchor {
    position: fixed;
    bottom: 40px;
    left: 40px;
    z-index: 30;
    width: 80px;
    height: 80px;
    cursor: pointer;
    animation: animateGift 3s infinite;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
}

.fortune-anchor img {
    width: 100%;
    height: 100%;
}
.fortune-anchor.show {
    opacity: 1;
    pointer-events: all;
}

@media screen and (max-width: 1200px) {
    .fortune-anchor {
        bottom: 20px;
        left: 20px;
        width: 40px;
        height: 40px;
    }
}

@keyframes animateGift {
    0%,
    100% {
        scale: 1;
    }
    50% {
        scale: 1.2;
    }
}
</style>
