<template>
    <button class="custom-btn" :class="props?.type" @click="href(props?.link)">
        <slot></slot>
    </button>
</template>
<script setup>
const props = defineProps({
    link: String,
    type: String,
});

function href(link) {
    if (link) {
        window.location.href = link;
    }
}
</script>
<style>
/* Дефолт */
.custom-btn {
    text-decoration: none;
    color: var(--white);
    padding: 20px 80px;
    background-color: var(--black);
    border-radius: 5px;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.045em;
    text-align: center;
    transition: 0.5s;
    border: 1px solid transparent;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
}
.custom-btn:hover {
    background-color: var(--dark_gray);
}
.custom-btn:disabled {
    pointer-events: none;
    background-color: var(--light_gray);
    color: var(--gray);
}
.custom-btn:active {
    background-color: var(--dark_gray);
    border: 1px solid var(--black);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
}
/* Белый */
.custom-btn.white {
    color: var(--black);
    background-color: var(--white);
}
.custom-btn.white:hover {
    background-color: var(--gray);
}
.custom-btn.white:active {
    background-color: var(--gray);
    border: 1px solid var(--dark_gray);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
}
/* Бордер */
.custom-btn.border {
    color: var(--black);
    background-color: var(--white);
    border: 1px solid var(--gray);
}
.custom-btn.border:hover {
    border: 1px solid transparent;
}
.custom-btn.border:active {
    background-color: var(--gray);
    border: 1px solid transparent;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

@media screen and (max-width: 1200px) {
    .custom-btn {
        padding: 20px;
        font-size: 16px;
        font-weight: 600;
        line-height: 19.5px;
        letter-spacing: 0.045em;
        text-align: center;
    }
}
</style>
