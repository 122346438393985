<template>
    <div class="home">
        <Main :slides="page_data?.data?.main?.slides" />
        <Categories :list="page_data?.data?.categories?.list" />
        <Catalog :catalog="page_data?.data?.main_products" />
        <Company :company="page_data?.data?.company" />
        <ReviewSection :reviews="page_data?.data?.reviews" />
        <OrderSection />
    </div>
</template>

<script setup>
import Main from "@/components/home/Main.vue";
import Categories from "@/components/home/Categories.vue";
import Catalog from "@/components/home/Catalog.vue";
import Company from "@/components/home/Company.vue";
import ReviewSection from "@/components/UI/ReviewSection.vue";
import OrderSection from "@/components/UI/OrderSection.vue";
import { useStore } from "vuex";
import { onMounted, onUpdated, ref } from "vue";
import pagesApi from "@/assets/js/api/pages.js";

const store = useStore();
store.commit("setBreadcrumb", null);

const page_data = ref(null);

onMounted(async () => {
    store.commit("setLoading", true);
    const result = await pagesApi.getPageData("home");
    page_data.value = result;

    if (result) {
        store.commit("setLoading", false);
    }
});
</script>
