<template>
    <section id="main">
        <n-carousel show-arrow draggable autoplay>
            <div
                class="img-container"
                v-for="item in new_slides"
                :style="
                    'background-image: linear-gradient(257.91deg, rgba(41, 41, 41, 0) 25.9%, rgba(42, 42, 42, 0.812179) 76.73%, #000000 101.1%), url(' +
                    item.img +
                    ')'
                "
            >
                <div class="container main-container">
                    <div class="main-container-header">
                        <div class="h1" v-html="item.title"></div>
                        <div
                            class="main-container-desc"
                            v-html="item.desc"
                        ></div>
                    </div>
                    <Button
                        class="main-container-btn"
                        type="white"
                        @click="openPopup"
                    >
                        Оставить заявку
                    </Button>
                </div>
            </div>

            <template #arrow="{ prev, next }">
                <div class="container custom-arrow">
                    <button
                        type="button"
                        class="custom-arrow--left"
                        @click="prev"
                    >
                        <n-icon><ph-arrow-left :size="20" /></n-icon>
                    </button>
                    <button
                        type="button"
                        class="custom-arrow--right"
                        @click="next"
                    >
                        <n-icon><ph-arrow-right :size="20" /></n-icon>
                    </button>
                </div>
            </template>
            <template #dots="{ total, currentIndex, to }">
                <ul class="container custom-dots">
                    <li
                        v-for="index of total"
                        :key="index"
                        :class="{ ['is-active']: currentIndex === index - 1 }"
                        @click="to(index - 1)"
                        :style="'width:' + +'px'"
                    />
                </ul>
            </template>
        </n-carousel>
    </section>
</template>
<script setup>
import { NCarousel, NIcon } from "naive-ui";
import { ArrowBack, ArrowForward } from "@vicons/ionicons5";
import Button from "@/components/UI/Button.vue";
import { PhArrowRight, PhArrowLeft } from "@phosphor-icons/vue";
import { useStore } from "vuex";
import { ref, watch } from "vue";

const props = defineProps({
    slides: Array,
});

const store = useStore();

function openPopup() {
    store.commit("orderPopupOpen", true);
}

const new_slides = ref(null);

watch(props, (new_value) => {
    if (new_value?.slides) {
        new_slides.value = new_value?.slides;
    }
});
</script>
<style>
#main {
    margin-bottom: 60px;
}
.main-container {
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 50%;
    translate: -50% 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 60px;
    padding: 0 20px 180px;
}

.main-container-header {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.main-container-header .h1 {
    color: var(--white);
}
.main-container-desc {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    color: var(--white);
}

.main-container-btn {
    width: max-content;
}

.img-container {
    height: 800px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.carousel-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.custom-arrow {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    position: absolute;
    bottom: 50px;
    right: 50px;
}

.custom-arrow button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: var(--black);
    background-color: var(--light_gray);
    border: none;
    border-radius: 50%;
    transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    transition: 0.5s;
}

.custom-arrow button.disabled {
    cursor: default;
    color: var(--gray);
}

.custom-arrow button:not(.disabled):hover {
    background-color: var(--hover);
    color: var(--white);
}

.custom-arrow button:not(.disabled):active {
    background-color: var(--accent);
    color: var(--white);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.custom-dots {
    left: 50%;
    translate: -50% 0;
    display: flex;
    gap: 20px;
    position: absolute;
    width: 100%;
    bottom: 70px;
}

.custom-dots li {
    position: relative;
    display: inline-block;
    width: 180px;
    height: 1px;
    background-color: var(--dark_gray);
    transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
}
.custom-dots li:before {
    content: "";
    position: absolute;
    background-color: var(--white);
    width: 0px;
    z-index: 1;
    height: 100%;
    left: 0;
    top: 0;
    transition: 0.5s;
}

.custom-dots li.is-active:before {
    width: 180px;
}

@media screen and (max-width: 1200px) {
    .custom-arrow button {
        width: 36px;
        height: 36px;
    }
    .img-container {
        height: 600px;
    }
    #main .custom-arrow {
        display: none;
    }
    .custom-dots {
        bottom: 20px;
    }
    .main-container {
        padding: 0 20px 60px;
    }
    .main-container-header {
        gap: 10px;
    }
}
@media screen and (max-width: 800px) {
    .main-container {
        gap: 40px;
    }
    .custom-dots {
        justify-content: center;
    }
    .custom-dots li {
        width: 100%;
        max-width: 100px;
    }

    .custom-dots li.is-active:before {
        width: 100%;
    }
    #main {
        margin-bottom: 40px;
    }
}
@media screen and (max-width: 360px) {
    .main-container {
        padding: 0 10px 60px;
    }
}
</style>
