<template>
    <div class="product__main">
        <!-- <ImgPicker :imgs="new_imgs" v-if="new_imgs" />
        <div class="product-no-images" v-else>
            <img :src="require('@/assets/img/no_img.png')" alt="" />
        </div> -->
        <div class="product-image" v-if="current_slide">
            <img :src="current_slide" />
        </div>
        <div class="product-no-images" v-else>
            <img :src="require('@/assets/img/no_img.png')" alt="" />
        </div>
        <div class="product__main-info">
            <div class="product__main-info-header">
                <div class="product__main-info-articul">
                    Артикул: <span>{{ new_article }}</span>
                </div>
                <h3>{{ new_title }}</h3>
                <div class="product__main-info-rate">
                    <n-rate :value="Number(mark)" :allow-half="true" :readonly="true" />
                    {{ mark }}
                    <div v-if="props?.comments?.length > 0" v-html="review_count + ' ' + num_word(review_count, words)">
                    </div>
                    <div v-else>Нет отзывов</div>
                </div>
                <template v-if="props?.data?.price && props?.data?.price != '0'">
                    <div class="product__main-info-price mob" v-if="is_complect && !color_complect_price">
                        {{ price_complect + " ₽" }}
                        <h5 class="product__main-info-body-item-title" v-if="props?.data?.available == 'Под заказ'">
                            Под заказ
                        </h5>
                    </div>
                    <div class="product__main-info-price mob" v-else-if="is_complect && color_complect_price">
                        {{ color_complect_price + " ₽" }}
                        <h5 class="product__main-info-body-item-title" v-if="props?.data?.available == 'Под заказ'">
                            Под заказ
                        </h5>
                    </div>
                    <div class="product__main-info-price mob" v-else-if="!color_price">
                        {{ price + " ₽" }}
                        <h5 class="product__main-info-body-item-title" v-if="props?.data?.available == 'Под заказ'">
                            Под заказ
                        </h5>
                    </div>
                    <div class="product__main-info-price mob" v-else>
                        {{ color_price + " ₽" }}
                        <h5 class="product__main-info-body-item-title" v-if="props?.data?.available == 'Под заказ'">
                            Под заказ
                        </h5>
                    </div>
                </template>
            </div>
            <div class="product__main-info-body">
                <div class="product__main-info-body-item" v-if="props?.data?.categories[0].items">
                    <h5 class="product__main-info-body-item-title">
                        {{ props?.data?.categories[0]?.name }}:
                    </h5>
                    <div class="product__main-info-body-item-filters">
                        <Radio @change="changeRadio(item)" :checked="index == 0" :id="item.size" :value="item.size"
                            :name="'size'" v-for="(item, index) in props?.data?.categories[0]
            ?.items" />
                    </div>
                </div>
                <div class="product__main-info-body-item" v-if="colors_info.length > 0">
                    <h5 class="product__main-info-body-item-title">
                        {{ props?.data?.categories[1]?.name }}:
                    </h5>
                    <div class="product__main-info-body-item-filters">
                        <template v-for="(item, index) in colors_info">
                            <Radio @change="changeRadio(item)" v-if="item.color != null" :id="item.title"
                                :color="item.color" :name="'color'" :checked="props?.variations[0]?.color_id ==
            item.color_id
            " />
                            <Radio @change="changeRadio(item)" v-else :id="item.title" :value="item.title"
                                :name="'color'" :checked="props?.variations[0]?.color_id ==
            item.color_id
            " />
                        </template>
                    </div>
                </div>
                <div class="product__main-info-body-item" v-if="props?.data?.price_kit && props?.data?.price_kit != '0'
            ">
                    <h5 class="product__main-info-body-item-title">
                        Комплектация:
                    </h5>
                    <div class="product__main-info-body-item-filters">
                        <Radio @change="changeRadio" id="Полотно" name="kit" value="Полотно" :checked="true" />
                        <n-tooltip trigger="hover">
                            <template #trigger>
                                <n-button class="product__main-info-body-item-filter" icon-placement="right">
                                    <Radio @change="changeRadio" id="Комплект" name="kit" value="Комплект" />

                                    <template #icon>
                                        <ph-warning-circle :size="20" />
                                    </template>
                                </n-button>
                            </template>
                            Полотно, коробка, наличники.
                        </n-tooltip>
                    </div>
                </div>
                <template v-if="props?.data?.price && props?.data?.price != '0'">
                    <div class="product__main-info-price" v-if="is_complect && !color_complect_price">
                        {{ price_complect + " ₽" }}
                        <h5 class="product__main-info-body-item-title" v-if="props?.data?.available == 'Под заказ'">
                            Под заказ
                        </h5>
                    </div>
                    <div class="product__main-info-price" v-else-if="is_complect && color_complect_price">
                        {{ color_complect_price + " ₽" }}
                        <h5 class="product__main-info-body-item-title" v-if="props?.data?.available == 'Под заказ'">
                            Под заказ
                        </h5>
                    </div>
                    <div class="product__main-info-price" v-else-if="!color_price">
                        {{ price + " ₽" }}
                        <h5 class="product__main-info-body-item-title" v-if="props?.data?.available == 'Под заказ'">
                            Под заказ
                        </h5>
                    </div>
                    <div class="product__main-info-price" v-else>
                        {{ color_price + " ₽" }}
                        <h5 class="product__main-info-body-item-title" v-if="props?.data?.available == 'Под заказ'">
                            Под заказ
                        </h5>
                    </div>
                </template>
                <div class="product__main-info-body-item">
                    <h5 class="product__main-info-body-item-title">
                        Количество:
                    </h5>
                    <Counter ref="counter" :value="product_count" :default="1" @update:value="setCounter" />
                </div>
                <div class="product__main-info-body-item" v-if="props?.data?.categories[2].items">
                    <h5 class="product__main-info-body-item-title">
                        {{ props?.data?.categories[2]?.name }}:
                    </h5>
                    <n-scrollbar style="max-height: 238px" trigger="none">
                        <div class="product__main-info-body-item-furniture">
                            <FurnitureItem v-for="item in props?.data?.categories[2].items" :furniture="item" />
                        </div>
                    </n-scrollbar>
                </div>
            </div>
            <div class="product__main-btns" v-if="props?.data?.available != 'Нет в наличии'">
                <Button class="product__main-btn-basket" @click="addProductInBasket(props?.data?.id)"
                    :disabled="is_disabled" v-if="price">
                    В корзину
                </Button>
                <Button class="product__main-btn-basket" @click="priceAsk(props?.card?.id)" :disabled="is_disabled"
                    type="border" v-else>
                    Узнать цену
                </Button>
                <Button class="product__main-btn-favourites" :class="in_favourites ? 'active' : ''"
                    @click="productAction('favourites', props?.data?.id)" type="border">
                    <PhHeart :size="20" />
                </Button>
                <Button class="product__main-btn-comparison" :class="in_comparison ? 'active' : ''"
                    @click="productAction('compare', props?.data?.id)" type="border">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 16.6666V8.33331M10 16.6666V3.33331M5 16.6666V11.6666" stroke="var(--gray)"
                            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Button>
            </div>
            <div class="product__main-btns" v-else>
                <h3>Нет в наличии</h3>
            </div>
        </div>
    </div>
</template>
<script setup>
import {
    NTooltip,
    NButton,
    useMessage,
    NAlert,
    NScrollbar,
    NRate,
} from "naive-ui";
import { PhHeart, PhWarningCircle } from "@phosphor-icons/vue";
import { YMTargetSend, num_word, numberWithSpaces } from "@/assets/js/helper";
import { computed, ref, h, watch, nextTick, defineEmits } from "vue";
import { useStore } from "vuex";
import ImgPicker from "@/components/UI/ImgPicker.vue";
import Radio from "../UI/Radio.vue";
import Button from "../UI/Button.vue";
import Counter from "../UI/Counter.vue";
import FurnitureItem from "../UI/FurnitureItem.vue";
import productApi from "@/assets/js/api/products.js";

const words = ["отзыв", "отзыва", "отзывов"];

const store = useStore();
const message_error = useMessage();

const emit = defineEmits(["change_img"]);

const props = defineProps({
    data: Object,
    comments: Array,
    variations: Array,
});

const new_title = ref(null);
const new_article = ref(null);

const info_product = ref({
    size: "",
    complect: false,
    color: "",
});

function priceAsk(id) {
    store.commit("AskPricePopupOpen", true);
    store.commit("AskPriceId", id);
}

watch(props, (new_value) => {
    if (new_value?.data) {
        new_title.value = new_value?.data?.title;
        new_article.value = new_value?.data?.article;
        if (new_value?.data?.categories[0].items) {
            info_product.value.size =
                new_value?.data?.categories[0]?.items[0]?.size ?? "";
        }
        if (new_value?.data?.categories[1].items) {
            info_product.value.color =
                new_value?.data?.categories[1]?.items[0]?.id ?? "";
        }
    }
});

const counter = ref(null);

const price = computed(() => {
    const checkedColor = colors_info.value.find(
        (item) => props?.variations[0]?.color_id == item.color_id
    );

    if (checkedColor) {
        const variant = props?.variations.find(
            (variant) => variant.color_id == checkedColor.color_id
        );

        if (variant) {
            if (variant.price_promo && variant.price_promo != "0") {
                return numberWithSpaces(variant.price_promo);
            }
            if (variant.price && variant.price != "0") {
                return numberWithSpaces(variant.price);
            }
        }
    } else {
        if (props?.data) {
            if (props.data.price_stock && props.data.price_stock != "0") {
                return numberWithSpaces(props.data.price_stock);
            }
            if (props.data.price && props.data.price != "0") {
                return numberWithSpaces(props.data.price);
            }
        }
    }
});

const price_complect = computed(() => {
    const checkedColor = colors_info.value.find(
        (item) => props?.variations[0]?.color_id == item.color_id
    );

    if (checkedColor) {
        const variant = props?.variations.find(
            (variant) => variant.color_id == checkedColor.color_id
        );

        if (variant) {
            if (variant.price_kit_promo && variant.price_kit_promo != "0") {
                return numberWithSpaces(variant.price_kit_promo);
            }
            if (variant.price_kit && variant.price_kit != "0") {
                return numberWithSpaces(variant.price_kit);
            }
            if (variant.price_promo && variant.price_promo != "0") {
                return numberWithSpaces(variant.price_promo);
            }
            if (variant.price && variant.price != "0") {
                return numberWithSpaces(variant.price);
            }
        }
    } else {
        if (props?.data) {
            if (props.data.price_kit && props.data.price_kit != "0") {
                return numberWithSpaces(props.data.price_kit);
            }
            if (props.data.price_stock && props.data.price_stock != "0") {
                return numberWithSpaces(props.data.price_stock);
            }
            if (props.data.price && props.data.price != "0") {
                return numberWithSpaces(props.data.price);
            }
        }
    }
});

const in_favourites = computed(() => {
    if (store.state.itemsInFavourites?.length > 0) {
        return store.state.itemsInFavourites.find(
            (el) => el.id == props?.data?.id
        );
    } else {
        return false;
    }
});

const in_comparison = computed(() => {
    if (store.state.itemsInComparison?.length > 0) {
        return store.state.itemsInComparison.find(
            (el) => el.id == props?.data?.id
        );
    } else {
        return false;
    }
});

async function productAction(type, id) {
    const result = await productApi.products_actions(type, {
        date: new Date().getTime(),
        id,
    });

    if (!result?.success) {
        message_error.error(
            result?.message ?? "Ошибка. Повторите попытку позже.",
            {
                render: (props) => {
                    return h(
                        NAlert,
                        {
                            closable: props.closable,
                            onClose: props.onClose,
                            type: "error",
                            style: {
                                boxShadow: "var(--n-box-shadow)",
                                maxWidth: "calc(100vw - 32px)",
                                padding: "14px",
                                width: "480px",
                            },
                        },
                        {
                            default: () =>
                                h("div", { innerHTML: props.content }),
                        }
                    );
                },
                closable: true,
                duration: 5000,
            }
        );
    }
}

const is_complect = ref(false);

const color_price = ref(null);
const color_complect_price = ref(null);

const color_id = ref(null);

function colorful_price(price) {
    return numberWithSpaces(price);
}

const last_color = ref(null);

function changeRadio(item) {
    if (item.size) {
        info_product.value.size = item.size;
    }
    if (item.color || item.title) {
        info_product.value.color = item.id;

        const variant = props?.variations.find(
            (variant) => variant.color_id == item.color_id
        );

        color_id.value = variant.color_id;

        new_title.value = variant.name;

        new_article.value = variant.article;

        if (is_complect.value) {
            if (variant?.price && variant?.price != "0") {
                color_complect_price.value = colorful_price(variant?.price);
            }
            if (variant?.price_promo && variant?.price_promo != "0") {
                color_complect_price.value = colorful_price(
                    variant?.price_promo
                );
            }
            if (variant?.price_kit && variant?.price_kit != "0") {
                color_complect_price.value = colorful_price(variant?.price_kit);
            }
            if (variant?.price_kit_promo && variant?.price_kit_promo != "0") {
                color_complect_price.value = colorful_price(
                    variant?.price_kit_promo
                );
            }
        } else {
            if (variant?.price && variant?.price != "0") {
                color_price.value = colorful_price(variant?.price);
            }
            if (variant?.price_promo && variant?.price_promo != "0") {
                color_price.value = colorful_price(variant?.price_promo);
            }
        }

        emit("change_img", variant);
        current_slide.value = variant.img;
        last_color.value = variant;
    }
    if (item?.target?.value) {
        info_product.value.complect =
            item.target.value == "Комплект" ? true : false;
        if (info_product.value.complect) {
            is_complect.value = true;
            if (last_color.value) {
                if (last_color.value?.price && last_color.value?.price != "0") {
                    color_complect_price.value = colorful_price(
                        last_color.value?.price
                    );
                }
                if (
                    last_color.value?.price_promo &&
                    last_color.value?.price_promo != "0"
                ) {
                    color_complect_price.value = colorful_price(
                        last_color.value?.price_promo
                    );
                }
                if (
                    last_color.value?.price_kit &&
                    last_color.value?.price_kit != "0"
                ) {
                    color_complect_price.value = colorful_price(
                        last_color.value?.price_kit
                    );
                }
                if (
                    last_color.value?.price_kit_promo &&
                    last_color.value?.price_kit_promo != "0"
                ) {
                    color_complect_price.value = colorful_price(
                        last_color.value?.price_kit_promo
                    );
                }
            }
        } else {
            is_complect.value = false;
            if (last_color.value) {
                if (last_color.value?.price && last_color.value?.price != "0") {
                    color_price.value = colorful_price(last_color.value?.price);
                }
                if (
                    last_color.value?.price_promo &&
                    last_color.value?.price_promo != "0"
                ) {
                    color_price.value = colorful_price(
                        last_color.value?.price_promo
                    );
                }
            }
        }
    }
}

const product_count = ref(1);

async function setCounter(value) {
    product_count.value = value;
}

const is_disabled = ref(false);

async function addProductInBasket(id) {
    is_disabled.value = true;

    let data = {};
    if (is_complect.value) {
        data = {
            date: new Date().getTime(),
            uniq_id: id + "_" + info_product.value.color + "_1",
            id: id,
            count: product_count.value,
            info: info_product.value,
            color_id: color_id.value,
        };
    } else {
        data = {
            date: new Date().getTime(),
            uniq_id: id + "_" + info_product.value.color,
            id: id,
            count: product_count.value,
            info: info_product.value,
            color_id: color_id.value,
        };
    }

    const result = await productApi.products_actions("basket", data);

    if (result?.success) {
        counter.value.reset();
        message_error.success("Товар успешно добавлен в корзину.", {
            render: (props) => {
                return h(
                    NAlert,
                    {
                        closable: props.closable,
                        onClose: props.onClose,
                        type: "success",
                        style: {
                            boxShadow: "var(--n-box-shadow)",
                            maxWidth: "calc(100vw - 32px)",
                            padding: "14px",
                            width: "480px",
                        },
                    },
                    {
                        default: () => h("div", { innerHTML: props.content }),
                    }
                );
            },
            closable: true,
            duration: 5000,
        });
        YMTargetSend("korzina");
    }
    setTimeout(() => {
        is_disabled.value = false;
    }, 1000);
}

const mark = computed(() => {
    return props?.data?.average_rate?.toFixed(2);
});

const review_count = computed(() => {
    return props?.comments?.length;
});

const colors_info = ref([]);

watch(props, (new_colors) => {
    if (new_colors?.data?.categories[1].items) {
        colors_info.value = new_colors?.data?.categories[1].items;
        if (colors_info.value) {
            colors_info.value = colors_info.value.filter(
                (item) => item.title || item.color
            );
        }
    }
});

// const new_imgs = ref([]);

// watch(props, (new_value) => {
//     if (new_value?.data?.imgs && new_value?.variations) {
// new_imgs.value.push(new_value?.data?.imgs);
// new_imgs.value = new_imgs.value.concat(new_value?.variations);
//         new_imgs.value = new_value?.variations;
//     }
// });

const current_slide = ref(null);
watch(props, (new_slide) => {
    current_slide.value = new_slide?.data?.img;
    if (new_slide?.variations) {
        current_slide.value = new_slide?.variations[0]?.img;
        color_id.value = new_slide?.variations[0]?.color_id;
    }
});
</script>
<style>
.product__wrapper {
    display: grid;
    grid-template-columns: 1fr 650px;
    gap: 60px;
}

/* .product__wrapper {
    display: flex;
    flex-direction: column;
    gap: 60px;
} */
.product__main {
    display: grid;
    grid-template-columns: 1fr 650px;
    gap: 100px;
}

.product__main .img-picker {
    display: none;
}

.product__main-info {
    display: flex;
    flex-direction: column;
    gap: 40px;
    min-width: 650px;
}

.product__main-info-header {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.product__main-info-articul {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--dark_gray);
}

.product__main-info-articul span {
    color: var(--black);
}

.product__main-info-name {
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 600;
    line-height: 37px;
    letter-spacing: 0.045em;
    text-align: left;
    color: gray;
}

.product__main-info-rate {
    display: flex;
    align-items: center;
    gap: 16px;
}

.product__main-info-rate-count {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: lightgray;
}

.product__main-info-body {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.product__main-info-body-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.product__main-info-body-item-filters {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
}

.product__main-info-body-item-furniture {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
}

.product__main-info-body-item-filters .n-button {
    padding: 0;
}

.product__main-info-body-item-filters .n-button .radio-btn label {
    border: none;
}

.product__main-info-body-item-filters .n-button .radio-btn input[type="radio"]:checked+label {
    border: none;
}

.product__main-info-body-item-filters .n-button:has(.radio-btn input[type="radio"]:checked + label) .n-button__state-border {
    border: 1px solid var(--accent);
}

.product__main-info-price {
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 600;
    line-height: 37px;
    letter-spacing: 0.045em;
    text-align: left;
    color: var(--black);
}

.product__main-btns {
    display: flex;
    gap: 20px;
}

.product__main-btn-basket {
    width: max-content;
}

.product__main-btn-comparison.border,
.product__main-btn-favourites.border {
    color: var(--gray);
    padding: 22px;
    width: max-content;
    height: max-content;
    aspect-ratio: 1;
    border-radius: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.product__main-btn-comparison.border.active,
.product__main-btn-comparison.border.active svg path,
.product__main-btn-favourites.border.active {
    background-color: var(--black);
    color: var(--white);
    stroke: var(--white);
}

.product__main-btn-comparison.border svg,
.product__main-btn-comparison.border svg path {
    transition: 0.5s;
}

.product__main-btn-comparison.border:hover,
.product__main-btn-comparison.border:hover svg path,
.product__main-btn-favourites.border:hover {
    background-color: var(--gray);
    color: var(--white);
    stroke: var(--white);
}

.product__main-btn-comparison.border:active,
.product__main-btn-comparison.border:active svg path,
.product__main-btn-favourites.border:active {
    background-color: var(--black);
    color: var(--white);
    stroke: var(--white);
}

.product__main-info-price.mob {
    display: none;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
}

.product__main-info-body-item-filters .n-button .n-button__content~.n-button__icon {
    margin: 0;
    margin-right: 20px;
    width: 20px;
    height: 20px;
    max-width: none;
    display: none;
}

.product__main-info-body-item-filters .n-button .n-button__icon .n-icon-slot {
    width: 20px;
    height: 20px;
    max-width: none;
}

.product-no-images {
    display: grid;
    width: 100%;
    height: 512px;
    place-items: center;
}

.product__main .product-no-images,
.product__main .product-image {
    display: none;
}

.product-no-images img {
    height: inherit;
    object-fit: contain;
}

@media screen and (max-width: 1200px) {
    .product__main .product-no-images {
        display: grid;
    }

    .product__main .product-image {
        min-width: auto;
        display: block;
        height: 400px;
    }

    .product__tabs.product-no-images {
        display: none;
    }

    .product__main-info-body-item-filters {
        display: flex;
        flex-wrap: wrap;
    }

    .product__main .img-picker.mobile {
        display: flex;
    }

    .product__main-info-body-item-filters .n-button .n-button__content~.n-button__icon {
        display: block;
    }

    .product__main {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .product__main-info-header {
        gap: 10px;
    }

    .product__main-info-price {
        display: none;
    }

    .product__main-info-price.mob {
        display: block;
    }

    .product__main-info {
        gap: 20px;
    }

    .product__main-info-body {
        gap: 20px;
    }

    .product__main-btn-basket {
        width: 300px;
    }

    .product__main-info-body-item .counter .n-input .n-input__input-el {
        height: 52px;
    }

    .product__wrapper {
        display: flex;
        flex-direction: column-reverse;
        gap: 40px;
    }
}

@media screen and (max-width: 800px) {
    .product__main-info {
        min-width: auto;
    }

    .product__main-btn-basket {
        padding: 10px;
        width: 100%;
    }

    .product__main-btns {
        gap: 10px;
    }

    .product__main-info-body-item .counter .n-input .n-input__input-el {
        height: 38px;
    }

    .product__main-info-body-item-filters {
        gap: 10px;
    }

    .product__main-btn-comparison.border,
    .product__main-btn-favourites.border {
        padding: 10px;
    }

    .product-no-images {
        height: 200px;
    }
}
</style>
