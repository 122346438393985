import Api from "./index.js";

class PagesApi extends Api {
    async getPageData(page_name) {
        const result = await this.send("methods/" + page_name);

        return result?.data;
    }
}
export default new PagesApi();
