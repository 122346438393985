<template>
    <div class="catalog-filters">
        <n-collapse arrow-placement="right">
            <template #arrow>
                <svg
                    width="15"
                    height="9"
                    viewBox="0 0 15 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M13.5 7.5L7.5 1.5L1.5 7.5"
                        stroke="#434343"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </template>
            <n-space vertical :size="[0, 20]" class="catalog-filters__wrapper">
                <template v-for="(filter, index) in filters">
                    <n-collapse-item
                        v-if="filter?.name == 'price'"
                        :title="filter?.label"
                        :name="filter?.name"
                    >
                        <div>
                            <n-input
                                class="catalog-filter-pair"
                                pair
                                separator="-"
                                :placeholder="['от', 'до']"
                                :allow-input="onlyAllowNumber"
                                v-model:value="price"
                                @update:value="updatePrice"
                                :disabled="loading"
                            />
                            <n-slider
                                v-model:value="price"
                                range
                                @update:value="updatePrice"
                                :min="filter.min"
                                :max="filter.max"
                                :default-value="[filter.min, filter.max]"
                            />
                        </div>
                    </n-collapse-item>
                    <n-collapse-item
                        v-else-if="filter?.children"
                        :title="filter?.label"
                        :name="filter?.name"
                    >
                        <n-checkbox-group
                            v-model:value="applied_filters[filter?.name]"
                        >
                            <n-space vertical>
                                <n-checkbox
                                    v-for="(item, index) in filter?.children"
                                    :value="String(item.value)"
                                    :label="item.label"
                                    :key="index"
                                    :disabled="loading"
                                />
                            </n-space>
                        </n-checkbox-group>
                    </n-collapse-item>
                    <n-checkbox-group
                        v-else
                        v-model:value="applied_filters.others"
                    >
                        <n-checkbox
                            :value="String(filter.name)"
                            :label="filter.label"
                            :disabled="loading"
                        />
                    </n-checkbox-group>
                </template>
                <div>
                    <Button
                        class="catalog-filter-btn"
                        @click="submit"
                        :disabled="loading"
                        >Применить</Button
                    >
                    <Button
                        class="catalog-filter-btn"
                        style="margin-top: 20px"
                        type="border"
                        @click="clear"
                        :disabled="loading"
                        >Сбросить</Button
                    >
                </div>
            </n-space>
        </n-collapse>
    </div>
</template>
<script setup>
import {
    NCollapse,
    NCollapseItem,
    NSpace,
    NInput,
    NSlider,
    NCheckboxGroup,
    NCheckbox,
} from "naive-ui";
import { ref, watch, reactive, defineEmits } from "vue";
import Button from "../UI/Button.vue";

const props = defineProps({
    filters: Array,
    default_filters: Object,
    loading: Boolean,
});

const price = ref([0, 100]);
const applied_filters = reactive({});

const emit = defineEmits(["update-filters"]);

function updatePrice(value) {
    if (value[0] == "") {
        value[0] = 0;
    } else if (value[1] == "") {
        value[1] = 0;
    }

    const price_template = props.filters.find(
        (filter) => filter.name == "price"
    );

    if (value[0] > value[1] || value[1] < value[0]) {
        value[1] = value[0];
    }

    if (value[0] < price_template.min) {
        value = [price_template.min, price_template.min];
    }

    if (value[1] > price_template.max) {
        value = [price_template.max, price_template.max];
    }

    price.value = value;
    applied_filters.price = value;
}

watch(props, (new_value) => {
    for (const key in new_value.default_filters) {
        applied_filters[key] = Array.isArray(new_value.default_filters[key])
            ? new_value.default_filters[key]
            : [new_value.default_filters[key]];
    }

    if (!new_value.default_filters?.price) {
        const price_template = new_value.filters?.find(
            (filter) => filter?.name == "price"
        );
        price.value = [price_template?.min, price_template?.max];
    } else {
        price.value = new_value.default_filters.price;
    }
});

function onlyAllowNumber(value) {
    return !value || /^\d+$/.test(value);
}

function submit() {
    emit("update-filters", applied_filters);
}

function clear() {
    for (const key in applied_filters) {
        if (key == "price") {
            const price_template = props.filters.find(
                (filter) => filter?.name == "price"
            );
            price.value = [price_template?.min, price_template?.max];
            applied_filters.price = [price_template?.min, price_template?.max];
        } else {
            applied_filters[key] = [];
        }
    }

    emit("update-filters", applied_filters);
}
</script>
<style>
.catalog-filters {
    width: 25%;
}
.catalog-filters .n-input.n-input--pair .n-input__placeholder {
    padding: 16px;
}

.catalog-filters__wrapper > div:not(:last-of-type) {
    border-top: 1px solid var(--gray);
    padding: 20px 20px 0px;
}
.catalog-filters
    .n-collapse
    .n-collapse-item
    .n-collapse-item__header
    .n-collapse-item__header-main {
    justify-content: space-between;
    line-height: normal;
}

.catalog-filters
    .n-collapse
    .n-collapse-item
    .n-collapse-item__header
    .n-collapse-item__header-main:hover {
    color: var(--hover);
}
.catalog-filters
    .n-collapse
    .n-collapse-item
    .n-collapse-item__header
    .n-collapse-item__header-main
    .n-collapse-item-arrow
    svg
    path {
    transition: 0.5s;
}
.catalog-filters
    .n-collapse
    .n-collapse-item
    .n-collapse-item__header
    .n-collapse-item__header-main:hover
    .n-collapse-item-arrow
    svg
    path {
    stroke: var(--hover);
}

.catalog-filters
    .n-collapse
    .n-collapse-item.n-collapse-item--active
    .n-collapse-item__header.n-collapse-item__header--active
    .n-collapse-item-arrow {
    rotate: 270deg;
}

.catalog-filters
    .n-collapse
    .n-collapse-item.n-collapse-item--right-arrow-placement
    .n-collapse-item__header
    .n-collapse-item-arrow {
    rotate: 180deg;
}

.catalog-filters
    .n-collapse
    .n-collapse-item
    .n-collapse-item__content-wrapper
    .n-collapse-item__content-inner {
    padding-top: 40px;
}
.catalog-filter-pair.n-input {
    margin-bottom: 10px;
    --n-border-hover: none !important;
    --n-border-focus: none !important;
    --n-border: none !important;
}

.catalog-filter-pair.n-input .n-input__border {
    border: none;
}
.catalog-filter-pair.n-input:hover,
.catalog-filter-pair.n-input:active {
}

.catalog-filter-pair.n-input .n-input__input-el,
.catalog-filter-pair.n-input .n-input .n-input__textarea-el,
.catalog-filter-pair.n-input .n-input .n-input__input-mirror,
.catalog-filter-pair.n-input .n-input .n-input__textarea-mirror,
.catalog-filter-pair.n-input .n-input .n-input__separator,
.catalog-filter-pair.n-input .n-input .n-input__placeholder {
    border: 1px solid var(--gray);
    border-radius: 5px;

    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
    --n-height: auto;
    --n-width: auto;
    padding: 16px;
}
.catalog-filter-pair.n-input .n-input-wrapper {
    --n-padding-left: 0;
    --n-padding-right: 0;
}
.catalog-filter-pair.n-input.n-input--pair .n-input__separator {
    margin: 0 10px;
}
.catalog-filter-btn {
    margin-top: 40px;
}
</style>
