import { createStore } from "vuex";
import { pageModule } from "./pageModule";

export default createStore({
    state: () => {
        return {
            is_page_loading: true,

            recover_popup: false,
            recover_popup_message: null,
            fortune_wheel: false,
            show_prize: false,
            success_popup: false,
            prize_popup: false,
            prize_message: null,
            prize_promo: null,
            card_popup: false,
            card_popup_counter: 1,
            breadcrumb: null,
            added_product_popup: false,
            added_product_count: 1,
            review_popup: false,
            review_popup_id: -1,
            order_popup: false,

            scrollItem: null,
            mobileFilter: false,

            productInfo: null,
            productVariants: null,
            addedProductInfo: null,

            itemsInBasket: null,
            itemsInFavourites: null,
            itemsInComparison: null,
            comparison_specifications: null,

            //Юзер
            user: null,

            // Узнать цену
            ask_price_popup: false,
            ask_price_id: -1,

            // Site-info
            site_info: {},
        };
    },
    getters: {},
    mutations: {
        setLoading(state, bool) {
            state.is_page_loading = bool;
        },
        recoverPopupOpen(state, bool) {
            state.recover_popup = bool;
        },
        setRecoverPopupMessage(state, string) {
            state.recover_popup_message = string;
        },
        fortuneWheelOpen(state, bool) {
            state.fortune_wheel = bool;
        },
        showPrize(state, bool) {
            state.show_prize = bool;
        },
        successPopupOpen(state, bool) {
            state.success_popup = bool;
        },
        prizePopupOpen(state, bool) {
            state.prize_popup = bool;
        },
        setPrizePopupMessage(state, string) {
            state.prize_message = string;
        },
        setPrizePopupPromocode(state, string) {
            state.prize_promo = string;
        },
        cardPopupOpen(state, bool) {
            state.card_popup = bool;
        },
        cardPopupCounter(state, number) {
            state.card_popup_counter = number;
        },
        setBreadcrumb(state, array) {
            state.breadcrumb = array;
        },
        setScrollItem(state, object) {
            state.scrollItem = object;
        },
        setMobileFilter(state, bool) {
            state.mobileFilter = bool;
        },
        setProcutInfo(state, object) {
            state.productInfo = object;
        },
        setProcutVariants(state, array) {
            state.productVariants = array;
        },
        //
        //
        //
        AddedProductPopupOpen(state, bool) {
            state.added_product_popup = bool;
        },
        AddedProductCount(state, number) {
            state.added_product_count = number;
        },
        setAddedProductInfo(state, object) {
            state.addedProductInfo = object;
        },
        //
        //
        //
        setUser(state, object) {
            state.user = object;
        },
        //
        //
        //
        setBadgeFavourites(state, array) {
            state.itemsInFavourites = array;
        },
        setBadgeComparison(state, array) {
            state.itemsInComparison = array;
        },
        setBadgeBasket(state, array) {
            state.itemsInBasket = array;
        },
        setComparisonSpecifications(state, array) {
            state.comparison_specifications = array;
        },
        //
        //
        //
        AskPricePopupOpen(state, bool) {
            state.ask_price_popup = bool;
        },
        AskPriceId(state, number) {
            state.ask_price_id = number;
        },
        reviewPopupOpen(state, bool) {
            state.review_popup = bool;
        },
        reviewPopupId(state, number) {
            state.review_popup_id = number;
        },
        orderPopupOpen(state, bool) {
            state.order_popup = bool;
        },
        setSiteInfo(state, object) {
            state.site_info = object;
        },
    },
    actions: {},
    modules: {
        page: pageModule,
    },
});
