<template>
    <div class="stoks">
        <StockMain :stock="page_data?.data?.stock" />
        <OrderSection />
    </div>
</template>
<script setup>
import StockMain from "@/components/stock/StockMain.vue";
import OrderSection from "@/components/UI/OrderSection.vue";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import pagesApi from "@/assets/js/api/pages.js";

const store = useStore();

store.commit("setBreadcrumb", [
    { name: "Главная", link: "/" },
    { name: "Акции" },
]);

const page_data = ref(null);

onMounted(async () => {
    store.commit("setLoading", true);
    const result = await pagesApi.getPageData("stocks");
    page_data.value = result;

    if (result) {
        store.commit("setLoading", false);
    }
});
// export default {
//     name: "HomeView",
//     components: { StockMain, OrderSection },
//     methods: {
//         ...mapMutations({
//             setLoading: "page/setLoading",
//         }),
//     },
//     mounted() {
//         // Убирает прелоадер.
//         setTimeout(() => {
//             this.setLoading(false);
//         }, 1500);
//     },
// };
</script>
