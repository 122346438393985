<template>
    <div class="recover-popup" :class="store.state.recover_popup ? 'open' : ''">
        <div class="recover-popup-bg" @click="closePopup"></div>
        <div class="recover-popup-container">
            <svg
                class="recover-popup-close"
                @click="closePopup"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6 17L17 6"
                    stroke="#6D6C6C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
                <path
                    d="M6 6L17 17"
                    stroke="#6D6C6C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
            </svg>
            <div class="recover-popup-message" v-html="message"></div>
            <Button @click="closePopup">Вернуться к странице</Button>
        </div>
    </div>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import Button from "./Button.vue";
const store = useStore();
function closePopup() {
    store.commit("recoverPopupOpen", false);
    setTimeout(() => {
        store.commit("setRecoverPopupMessage", "");
    }, 500);
}

const message = computed(() => {
    return store.state.recover_popup_message;
});
</script>
<style>
.recover-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    z-index: 100;
}

.recover-popup.open {
    opacity: 1;
    pointer-events: all;
}

.recover-popup-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.recover-popup-container {
    max-height: 90vh;
    max-width: 670px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 60px;
    background-color: white;
    border-radius: 5px;
    padding: 80px;
}

.recover-popup-close {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
    z-index: 10;
    transition: 0.5s;
}
.recover-popup-close path {
    transition: 0.5s;
}
.recover-popup-close:hover path {
    stroke: black;
}
.recover-popup-message {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: center;
    color: var(--black);
}
.recover-popup-message span {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: center;
    color: var(--black);
}

@media screen and (max-width: 1200px) {
    .recover-popup-close {
        top: 20px;
        right: 20px;
    }
    .recover-popup-container {
        padding: 40px;
        gap: 40px;
    }
    .recover-popup-message {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.12px;
        text-align: center;
    }
    .recover-popup-message span {
        font-size: 14px;
        font-weight: 700;
        line-height: 19.12px;
        text-align: center;
    }
    .recover-popup-container .custom-btn {
        padding: 10px;
    }
}
</style>
