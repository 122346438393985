<template>
    <div class="review-popup" :class="store.state.review_popup ? 'open' : ''">
        <div class="review-popup-bg" @click="closePopup"></div>
        <div class="review-popup-container">
            <svg
                class="review-popup-close"
                @click="closePopup"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6 17L17 6"
                    stroke="#6D6C6C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
                <path
                    d="M6 6L17 17"
                    stroke="#6D6C6C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
            </svg>
            <div class="review-popup-message">
                <div class="h3">Оставить свой отзыв</div>
                <n-scrollbar trigger="none" style="max-height: 60vh">
                    <n-form
                        class="order-form review-form"
                        ref="formRef"
                        :model="form_data"
                        :rules="rules"
                        :show-label="false"
                    >
                        <n-form-item path="rate">
                            <div class="review-form-block">
                                <div class="h5">Ваша оценка:</div>
                                <n-rate
                                    size="large"
                                    clearable
                                    v-model:value="form_data.rate"
                                />
                            </div>
                        </n-form-item>
                        <n-form-item path="message">
                            <n-input
                                rows="5"
                                type="textarea"
                                placeholder="Текст отзыва"
                                v-model:value="form_data.message"
                            />
                        </n-form-item>
                        <n-form-item path="pictures">
                            <div class="review-form-block">
                                <div class="h5">Изображения:</div>
                                <n-upload
                                    :max="3"
                                    accept="image/jpeg, image/jpg"
                                    list-type="image-card"
                                    :multiple="true"
                                    @change="changeFiles"
                                    v-model:value="form_data.pictures"
                                />
                            </div>
                        </n-form-item>
                        <n-form-item path="name">
                            <n-input
                                placeholder="Ваше имя"
                                v-model:value="form_data.name"
                            />
                        </n-form-item>
                        <n-form-item path="email">
                            <n-input
                                placeholder="Email"
                                v-model:value="form_data.email"
                            />
                        </n-form-item>
                        <n-form-item class="reg-confirm">
                            <n-checkbox v-model:checked="form_data.confirm">
                                я даю согласие на обработку персональных данных
                            </n-checkbox>
                            <div class="reg-form-desc">
                                Нажимая кнопку зарегистрироваться подтверждаю,
                                что я ознакомлен и согласен с
                                <a href="/policy/personal">условиями оферты</a>
                                и
                                <a href="/policy/privacy">
                                    политики конфиденциальности </a
                                >.
                            </div>
                        </n-form-item>
                        <Button @click="sendReview">Оставить отзыв</Button>
                    </n-form>
                </n-scrollbar>
            </div>
        </div>
    </div>
</template>
<script setup>
import {
    NForm,
    NFormItem,
    NInput,
    NRate,
    NUpload,
    NCheckbox,
    NScrollbar,
    NAlert,
    useMessage,
} from "naive-ui";
import { useStore } from "vuex";
import { computed, h, ref } from "vue";
import Button from "./Button.vue";
import productApi from "@/assets/js/api/products.js";
import { validateEmail } from "@/assets/js/helper";

const message_error = useMessage();

const store = useStore();
function closePopup() {
    store.commit("reviewPopupOpen", false);
    store.commit("reviewPopupId", -1);
}

const id = computed(() => {
    return store.state.review_popup_id;
});

function resetForm() {
    form_data.value.rate = 0;
    form_data.value.message = "";
    form_data.value.pictures = [];
    form_data.value.name = "";
    form_data.value.email = "";
    form_data.value.confirm = false;
}

async function sendReview() {
    let data = new FormData();
    data.append("id", id.value);
    data.append("rate", form_data.value.rate);
    data.append("text", form_data.value.message);
    data.append("name", form_data.value.name);
    data.append("email", form_data.value.email);

    if (form_data.value.pictures.length > 0) {
        form_data.value.pictures.forEach((file, index) => {
            data.append(["file" + (index + 1)], file);
        });
    }

    await formRef.value.validate(async (errors) => {
        if (!errors) {
            if (!form_data.value.confirm) {
                message_error.error("Подтвердите согласие, нажмите галочку", {
                    render: (props) => {
                        return h(
                            NAlert,
                            {
                                closable: props.closable,
                                onClose: props.onClose,
                                type: "error",
                                style: {
                                    boxShadow: "var(--n-box-shadow)",
                                    maxWidth: "calc(100vw - 32px)",
                                    padding: "14px",
                                    width: "480px",
                                },
                            },
                            {
                                default: () =>
                                    h("div", { innerHTML: props.content }),
                            }
                        );
                    },
                    closable: true,
                    duration: 5000,
                });
                return;
            }

            const result = await productApi.createComment(data);

            if (result?.success) {
                message_error.success("Отзыв успешно добавлен", {
                    render: (props) => {
                        return h(
                            NAlert,
                            {
                                closable: props.closable,
                                onClose: props.onClose,
                                type: "success",
                                style: {
                                    boxShadow: "var(--n-box-shadow)",
                                    maxWidth: "calc(100vw - 32px)",
                                    padding: "14px",
                                    width: "480px",
                                },
                            },
                            {
                                default: () =>
                                    h("div", { innerHTML: props.content }),
                            }
                        );
                    },
                    closable: true,
                    duration: 5000,
                });
                store.commit("reviewPopupOpen", false);
                store.commit("reviewPopupId", -1);
                resetForm();
            } else {
                message_error.error(result?.message, {
                    render: (props) => {
                        return h(
                            NAlert,
                            {
                                closable: props.closable,
                                onClose: props.onClose,
                                type: "error",
                                style: {
                                    boxShadow: "var(--n-box-shadow)",
                                    maxWidth: "calc(100vw - 32px)",
                                    padding: "14px",
                                    width: "480px",
                                },
                            },
                            {
                                default: () =>
                                    h("div", { innerHTML: props.content }),
                            }
                        );
                    },
                    closable: true,
                    duration: 5000,
                });
                return;
            }
        }
    });
}

function changeFiles(file) {
    form_data.value.pictures.push(file.file.file);
}
const pattern = /[а-яА-ЯЁёa-zA-Z@ ]*$/g;
const formRef = ref(null);
const form_data = ref({
    rate: 0,
    message: "",
    pictures: [],
    name: "",
    email: "",
    confirm: false,
});
const rules = {
    rate: {
        required: true,
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (!value) {
                return new Error("Обязательное поле!");
            }
        },
    },
    email: {
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (value.charAt().match(pattern) != null) {
                if (!validateEmail(value)) {
                    return new Error("Введите существующую почту");
                }
            }
        },
    },
};
</script>
<style>
.review-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    z-index: 100;
}

.review-popup.open {
    opacity: 1;
    pointer-events: all;
}

.review-popup-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.review-popup-container {
    max-width: 680px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 60px;
    background-color: white;
    border-radius: 5px;
    padding: 80px;
}

.review-popup-close {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
    z-index: 10;
    transition: 0.5s;
}
.review-popup-close path {
    transition: 0.5s;
}
.review-popup-close:hover path {
    stroke: black;
}

.review-form {
    padding-right: 20px;
}
.review-form.order-form .n-checkbox {
    margin: 0;
}
.review-form.order-form .n-checkbox.n-checkbox--show-label {
    line-height: var(--n-size);
}
.review-form.order-form .n-form-item .n-form-item-feedback-wrapper {
    top: calc(100% + 2px);
}
.review-popup-message {
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.review-form-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
@media screen and (max-width: 1200px) {
    .review-popup-close {
        top: 20px;
        right: 20px;
    }

    .review-popup-container {
        padding: 40px;
        gap: 40px;
    }

    .review-popup-container .custom-btn {
        padding: 10px;
    }
}
</style>
