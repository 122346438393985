<template>
    <div class="furniture-item">
        <div class="furniture-item-name" v-html="props?.furniture?.title"></div>
        <div class="furniture-item-info">
            <Counter
                ref="counter"
                :value="1"
                :default="1"
                @update:value="setCounter"
                v-if="price"
            />
            <div class="furniture-item-price" v-if="price">
                {{ price + " ₽" }}
            </div>
            <div class="furniture-item-price empty" v-else>узнать цену</div>
            <Button
                class="border"
                @click="buttonFunction(props?.furniture?.id)"
            >
                <PhShoppingCartSimple :size="20" v-if="price" />
                <PhQuestion :size="20" v-else />
            </Button>
        </div>
    </div>
</template>
<script setup>
import Button from "@/components/UI/Button.vue";
import Counter from "@/components/UI/Counter.vue";
import { useMessage, NAlert } from "naive-ui";
import { PhShoppingCartSimple, PhQuestion } from "@phosphor-icons/vue";
import { computed, h, ref } from "vue";
import { numberWithSpaces } from "@/assets/js/helper.js";
import productApi from "@/assets/js/api/products.js";
import { useStore } from "vuex";

const store = useStore();
const message_error = useMessage();

const props = defineProps({
    furniture: Object,
});

const price = computed(() => {
    if (props?.furniture?.price && props?.furniture?.price != "0") {
        return numberWithSpaces(
            props?.furniture?.price_stock ?? props?.furniture?.price
        );
    }
});
const counter = ref(null);
const product_count = ref(1);

async function setCounter(value) {
    product_count.value = value;
}
function buttonFunction(id) {
    if (price.value) {
        addProductInBasket(id);
    } else {
        priceAsk(id);
    }
}

async function addProductInBasket(id) {
    const data = {
        date: new Date().getTime(),
        id: id,
        count: product_count.value,
        uniq_id: id,
    };

    const result = await productApi.products_actions("basket", data);

    if (result?.success) {
        message_error.success("Товар успешно добавлен в корзину.", {
            render: (props) => {
                return h(
                    NAlert,
                    {
                        closable: props.closable,
                        onClose: props.onClose,
                        type: "success",
                        style: {
                            boxShadow: "var(--n-box-shadow)",
                            maxWidth: "calc(100vw - 32px)",
                            padding: "14px",
                            width: "480px",
                        },
                    },
                    {
                        default: () => h("div", { innerHTML: props.content }),
                    }
                );
            },
            closable: true,
            duration: 5000,
        });
        YMTargetSend("korzina");
        counter.value.reset();
    }
}

function priceAsk(id) {
    store.commit("AskPricePopupOpen", true);
    store.commit("AskPriceId", id);
}
</script>
<style>
.furniture-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
}
.furniture-item:not(:last-of-type) {
    border-bottom: 1px solid var(--gray);
}
.furniture-item-name {
    word-break: break-word;
}

.furniture-item-name,
.furniture-item-price {
    font-size: 16px;
}
.furniture-item-info {
    display: flex;
    gap: 20px;
    align-items: center;
}
.furniture-item-info .counter .n-input .n-input__input-el {
    height: 34px;
    width: 70px;
}

.furniture-item-price {
    width: 65px;
    white-space: nowrap;
}
.furniture-item-price.empty {
    width: auto;
}

.furniture-item-info button:not(.n-button) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    padding: 10px;
    aspect-ratio: 1;
    border: 1px solid var(--gray);
    background-color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s;
    color: var(--gray);
}
@media (hover: hover) {
    .furniture-item-info button:hover {
        background-color: var(--gray);
        color: var(--white);
    }
}
.furniture-item-info button:active {
    border: 1px solid var(--black);
    background-color: var(--black);
}
.furniture-item-info button.active {
    border: 1px solid var(--black);
    background-color: var(--black);
    color: var(--white);
}

@media screen and (max-width: 1200px) {
    .furniture-item {
        gap: 30px;
    }
    .furniture-item-name,
    .furniture-item-price {
        font-size: 12px;
    }
    .furniture-item-info {
        gap: 15px;
    }
    .product__main .furniture-item-info .counter .n-input .n-input__input-el {
        height: 28px;
        width: 30px;
    }
    .furniture-item-price {
        width: 50px;
    }
    .furniture-item-info button:not(.n-button) {
        width: 30px;
        height: 30px;
        padding: 0;
    }
}
</style>
