import axios from "axios";
axios.defaults.withCredentials = true;

class Api {
    constructor(params) {
        this.default_params = {
            url: "https://slavyanskie-dveri.ru/v1/",
            //   site_info: JSON.parse(sessionStorage.getItem("site_info")),
        };

        this.params = Object.assign(this.default_params, params);
    }

    async send(path, data = null, method = "get") {
        try {
            const axios_params = {
                method,
                url: this.params.url + path,
                data,
            };

            const token = localStorage.getItem("token");

            if (token) {
                axios_params.headers = {
                    Authorization: "Bearer " + token,
                };
            }

            let result = await axios(axios_params);

            if (result.status != 200) {
                console.log(result);
                return;
            }

            return result;
        } catch (error) {
            return error;
        }
    }

    async getPageData(page_name) {
        try {
            const response = await this.send("methods/" + page_name);
            // if (response.status == 200) {
            //     commit("setPageData", response.data);
            // }
            console.log(response);
        } catch (error) {
            if (error.response.status == 404) {
                //document.location = '/404'
            }
            console.log(error.message);
        } finally {
            // commit("setLoading", false);
        }
    }
}

export default Api;
