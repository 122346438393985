<template>
    <div class="header-navbar">
        <a class="header-navbar-item" href="/comparison">
            <n-badge :value="store.state.itemsInComparison?.length" :max="4">
                <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M15.5 17.1663V8.83301M10.5 17.1663V3.83301M5.5 17.1663V12.1663"
                        stroke="#363636"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </n-badge>
            Сравнение
        </a>
        <a class="header-navbar-item" href="/favourites">
            <n-badge :value="store.state.itemsInFavourites?.length" :max="20">
                <ph-heart-straight :size="20" />
            </n-badge>
            Избранное
        </a>
        <a class="header-navbar-item" href="/basket">
            <n-badge :value="store.state.itemsInBasket?.length">
                <ph-shopping-cart-simple :size="20" />
            </n-badge>
            Корзина
        </a>
        <n-dropdown
            class="header-dropdown-profile"
            placement="bottom-end"
            trigger="hover"
            size="huge"
            :options="profile"
        >
            <div class="header-navbar-item">
                <ph-user :size="20" />
                Профиль
            </div>
        </n-dropdown>
    </div>
</template>

<script setup>
import { h } from "vue";
import { NBadge, NDropdown } from "naive-ui";
import {
    PhHeartStraight,
    PhShoppingCartSimple,
    PhUser,
} from "@phosphor-icons/vue";

import Profile from "@/components/UI/header/ProfileCard.vue";
import store from "@/store";

const profile = [
    {
        key: "profile",
        type: "render",
        render: () => {
            return h(Profile);
        },
    },
];
</script>

<style>
.header-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.header-navbar-item {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: center;
    padding: 10px;
    display: inline-flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: currentColor;
    transition: 0.5s;
}
.header-navbar-item svg path {
    transition: 0.5s;
}

.header-navbar-item:hover svg path {
    fill: var(--hover);
    stroke: var(--hover);
}

.header-navbar-item:hover {
    color: var(--accent);
}

.header-dropdown-profile.n-dropdown-menu
    .n-dropdown-option
    .n-dropdown-option-body__prefix.n-dropdown-option-body__prefix--show-icon {
    display: inline-flex;
}
.header-dropdown-profile.n-dropdown-menu
    .n-dropdown-option
    .n-dropdown-option-body::before {
    left: 0;
    right: 0;
}
.header-dropdown-profile.n-dropdown-menu
    .n-dropdown-option
    .n-dropdown-option-body
    .n-dropdown-option-body__prefix.n-dropdown-option-body__prefix--show-icon {
    width: 24px;
    height: 24px;
    aspect-ratio: 1;
}
.header-dropdown-profile.n-dropdown-menu
    .n-dropdown-option
    .n-dropdown-option-body
    .n-dropdown-option-body__prefix.n-dropdown-option-body__prefix--show-icon
    .n-icon
    svg {
    width: 24px;
    height: 24px;
    aspect-ratio: 1;
}
.header-dropdown-profile.n-dropdown-menu
    .n-dropdown-option
    .n-dropdown-option-body
    .n-dropdown-option-body__prefix.n-dropdown-option-body__prefix--show-icon
    .n-icon {
    width: 24px;
    height: 24px;
    aspect-ratio: 1;
}
.header-dropdown .n-dropdown-menu .n-dropdown-option .n-dropdown-option-body,
.header-dropdown-profile
    .n-dropdown-menu
    .n-dropdown-option
    .n-dropdown-option-body,
.header-dropdown-profile .n-dropdown-option .n-dropdown-option-body,
.header-dropdown-profile-empty .n-dropdown-option .n-dropdown-option-body {
    height: auto;
    line-height: 100%;
    align-items: center;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
}

.header-dropdown.n-dropdown-menu
    .n-dropdown-option
    .n-dropdown-option-body
    .n-dropdown-option-body__suffix {
    padding: 0;
}
.header-dropdown-profile .n-dropdown-option .n-dropdown-option-body {
    gap: 10px;
}

[v-placement="bottom-end"] > .header-dropdown-profile {
    min-width: 320px;
}

[v-placement="bottom-end"] > .header-dropdown-profile-empty {
    width: 425px;
}

[v-placement="bottom-end"] > .header-dropdown-profile .n-avatar {
    width: 76px;
    height: 76px;
}

@media screen and (max-width: 1440px) {
    .header-navbar {
        gap: 10px;
    }
}

@media screen and (max-width: 1200px) {
    .header-navbar-item {
        padding: 0px;
    }
    .header-navbar-item .n-badge .n-badge-sup {
        height: 16px;
        width: 16px;
        line-height: 100%;
        border-radius: 50%;
        font-size: 10px;
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
