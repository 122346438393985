<template>
    <div class="comparison">
        <ComparisonMain
            :comparison="store.state.itemsInComparison"
            :specifications="store.state.comparison_specifications"
        />
    </div>
</template>
<script setup>
import ComparisonMain from "@/components/comparison/ComparisonMain.vue";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

const comparison = ref(null);
const specifications = ref(null);

const store = useStore();

store.commit("setBreadcrumb", [
    { name: "Главная", link: "/" },
    { name: "Сравнение товаров" },
]);

onMounted(() => {
    setTimeout(() => {
        store.commit("setLoading", false);
    }, 1000);
});
</script>
