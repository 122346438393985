<template>
    <a
        :href="
            props?.card?.type == 'furniture'
                ? '#'
                : '/product/' + props?.card?.slug
        "
        class="catalog-item"
        :class="[props?.type, props?.card?.type]"
    >
        <div class="catalog-item-content">
            <div class="catalog-item-hints" v-if="props?.type != 'delete'">
                <span class="catalog-item-hint" v-if="props?.card?.new">
                    новинка
                </span>
                <span class="catalog-item-hint" v-if="props?.card?.hit">
                    хит продаж
                </span>
                <span class="catalog-item-hint" v-if="props?.card?.price_stock">
                    акция
                </span>
            </div>
            <div class="catalog-item-img">
                <img
                    :src="props?.card?.img"
                    all="Дверь"
                    v-if="props?.card?.img"
                />
                <img
                    :src="require('@/assets/img/no_img.png')"
                    all="Дверь"
                    v-else
                />
            </div>
            <button
                class="catalog-item-remove-btn"
                @click.prevent="productAction('compare', props?.card?.id)"
                v-if="props?.type == 'delete'"
            >
                <PhX :size="20" />
            </button>
            <div class="catalog-item-btns">
                <button
                    v-if="props?.card?.type != 'furniture'"
                    class="catalog-item-comparison"
                    :class="in_compare ? 'active' : ''"
                    @click.prevent="productAction('compare', props?.card?.id)"
                >
                    <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M12 13.8334V7.16669" stroke="var(--gray)" />
                        <path d="M8 13.8334V3.16669" stroke="var(--gray)" />
                        <path d="M4 13.8333V9.83331" stroke="var(--gray)" />
                    </svg>
                </button>
                <button
                    v-if="props?.card?.type != 'furniture'"
                    class="catalog-item-favourites"
                    :class="in_favourites ? 'active' : ''"
                    @click.prevent="
                        productAction('favourites', props?.card?.id)
                    "
                >
                    <ph-heart-straight :size="20" />
                </button>
                <button
                    class="catalog-item-favourites mobile"
                    @click.prevent="addProductInBasket(props?.card?.id)"
                    :disabled="props?.card?.available == 'Нет в наличии'"
                    v-if="props?.card?.type == 'furniture'"
                >
                    <ph-shopping-cart-simple :size="20" />
                </button>
                <button
                    class="catalog-item-favourites mobile"
                    @click.prevent="openCard"
                    v-else-if="old_price"
                    :disabled="props?.card?.available == 'Нет в наличии'"
                >
                    <ph-shopping-cart-simple :size="20" />
                </button>
            </div>
            <div class="catalog-item-title">
                {{ props?.card?.title }}
            </div>
            <div
                class="catalog-item-prices"
                v-if="
                    props?.card?.price_stock && props?.card?.price_stock != '0'
                "
            >
                <span class="catalog-item-old_price">
                    <span>{{ old_price + " ₽" }}</span>
                </span>
                <span class="catalog-item-current_price">
                    {{ current_price + " ₽" }}
                </span>
            </div>
            <div class="catalog-item-prices" v-else-if="old_price">
                <span class="catalog-item-current_price">
                    {{ old_price + " ₽" }}
                </span>
            </div>
            <div class="catalog-item-prices" v-else style="opacity: 0">
                <span class="catalog-item-current_price"> &mdash; </span>
            </div>
            <div class="catalog-item-info">
                <div
                    class="catalog-item-info-block"
                    v-if="props?.card?.article"
                >
                    Артикул: <span>{{ props?.card?.article }}</span>
                </div>
                <div
                    class="catalog-item-info-block"
                    v-if="props?.card?.brand?.title"
                >
                    Бренд: <span>{{ props?.card?.brand?.title }}</span>
                </div>
                <div
                    class="catalog-item-info-block"
                    v-if="
                        props?.card?.price_kit && props?.card?.price_kit != '0'
                    "
                >
                    Комплект: <span>{{ complect_price }} ₽</span>
                </div>
                <div class="catalog-item-info-block">
                    Наличие:
                    <template v-if="props?.card?.available">
                        <span>{{ props?.card?.available }}</span>
                    </template>
                    <template v-else>
                        <span>В наличии</span>
                    </template>
                    <!-- <span>
                        {{
                            props?.card?.available
                                ? "В наличии"
                                : "Нет в наличии"
                        }}
                    </span> -->
                </div>
            </div>
        </div>
        <Button
            class="catalog-item-btn empty-price-mobile"
            v-if="!old_price"
            type="border"
            @click.prevent="priceAsk(props?.card?.id)"
        >
            Узнать цену
        </Button>
        <Button
            class="catalog-item-btn"
            @click.prevent="openCard"
            :disabled="props?.card?.available == 'Нет в наличии'"
            v-else-if="props?.card?.type != 'furniture'"
        >
            В корзину
        </Button>
        <Button
            v-else
            class="catalog-item-btn"
            @click.prevent="addProductInBasket(props?.card?.id)"
            :disabled="props?.card?.available == 'Нет в наличии'"
        >
            В корзину
        </Button>
    </a>
</template>
<script setup>
import {
    PhHeartStraight,
    PhShoppingCartSimple,
    PhX,
} from "@phosphor-icons/vue";
import { useMessage, NAlert } from "naive-ui";
import { useStore } from "vuex";
import { numberWithSpaces } from "@/assets/js/helper.js";
import { computed, h } from "vue";
import Button from "./Button.vue";
import productApi from "@/assets/js/api/products.js";

const message_error = useMessage();
const store = useStore();

const props = defineProps({
    card: Object,
    type: String,
});

async function addProductInBasket(id) {
    const data = {
        date: new Date().getTime(),
        id: id,
        count: 1,
        uniq_id: id,
    };

    const result = await productApi.products_actions("basket", data);

    if (result?.success) {
        message_error.success("Товар успешно добавлен в корзину.", {
            render: (props) => {
                return h(
                    NAlert,
                    {
                        closable: props.closable,
                        onClose: props.onClose,
                        type: "success",
                        style: {
                            boxShadow: "var(--n-box-shadow)",
                            maxWidth: "calc(100vw - 32px)",
                            padding: "14px",
                            width: "480px",
                        },
                    },
                    {
                        default: () => h("div", { innerHTML: props.content }),
                    }
                );
            },
            closable: true,
            duration: 5000,
        });
        YMTargetSend("korzina");
    }
}

async function openCard() {
    const result = await productApi.get_by_id(props?.card?.slug, false);
    store.commit("setProcutInfo", result.data);
    store.commit("setProcutVariants", result.variations);
    store.commit("cardPopupOpen", true);
}

const old_price = computed(() => {
    if (props?.card?.price && props?.card?.price != "0") {
        return numberWithSpaces(props?.card?.price);
    }
});

const current_price = computed(() => {
    if (props?.card?.price_stock && props?.card?.price_stock != "0") {
        return numberWithSpaces(props?.card?.price_stock);
    }
});

const complect_price = computed(() => {
    if (props?.card?.price_kit) {
        return numberWithSpaces(props?.card?.price_kit);
    }
});

async function productAction(type, id) {
    const result = await productApi.products_actions(type, {
        date: new Date().getTime(),
        id,
    });

    if (!result?.success) {
        message_error.error(
            result?.message ?? "Ошибка. Повторите попытку позже.",
            {
                render: (props) => {
                    return h(
                        NAlert,
                        {
                            closable: props.closable,
                            onClose: props.onClose,
                            type: "error",
                            style: {
                                boxShadow: "var(--n-box-shadow)",
                                maxWidth: "calc(100vw - 32px)",
                                padding: "14px",
                                width: "480px",
                            },
                        },
                        {
                            default: () =>
                                h("div", { innerHTML: props.content }),
                        }
                    );
                },
                closable: true,
                duration: 5000,
            }
        );
    }
}

const in_favourites = computed(() => {
    if (store.state.itemsInFavourites?.length > 0) {
        return store.state.itemsInFavourites.find(
            (el) => el.id == props?.card?.id
        );
    } else {
        return false;
    }
});

const in_compare = computed(() => {
    if (store.state.user) {
        if (store.state.itemsInComparison?.length > 0) {
            return store.state.itemsInComparison.find(
                (el) => el.id == props?.card?.id
            );
        }
    } else {
        if (store.state.itemsInComparison?.length > 0) {
            return store.state.itemsInComparison.find(
                (el) => el.id == props?.card?.id
            );
        }
    }
});

function priceAsk(id) {
    store.commit("AskPricePopupOpen", true);
    store.commit("AskPriceId", id);
}
</script>
<style>
.catalog-item.furniture {
    cursor: default;
}
.catalog-item {
    border: 1px solid var(--gray);
    border-radius: 5px;
    padding: 20px;
    position: relative;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.catalog-item-hints {
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.catalog-item-hint {
    font-family: Manrope;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.045em;
    background-color: var(--black);
    color: var(--white);
    padding: 10px;
    text-align: center;
}
.catalog-item-img {
    width: 100%;
    height: 190px;
}
.catalog-item-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.catalog-item-btns {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    right: 20px;
    top: 20px;
}
.catalog-item-btns button {
    width: 34px;
    height: 34px;
    aspect-ratio: 1;
    border-radius: 2px;
    border: 1px solid var(--gray);
    background-color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s;
    color: var(--gray);
}
.catalog-item-btns button:hover {
    background-color: var(--gray);
}
.catalog-item-btns button svg path {
    transition: 0.5s;
}
.catalog-item-btns button:hover svg path,
.catalog-item-btns button:active svg path {
    stroke: var(--white);
    fill: var(--white);
}
.catalog-item-btns button:active {
    border: 1px solid var(--black);
    background-color: var(--black);
}
.catalog-item-btns button.active {
    border: 1px solid var(--black);
    background-color: var(--black);
}
.catalog-item-title {
    word-break: break-word;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.045em;
    margin: 20px 0 10px;
    color: var(--black);
}
.catalog-item-prices {
    display: flex;
    gap: 10px;
    align-items: center;
}
.catalog-item-prices .custom-btn {
    padding: 10px;
    font-size: 16px;
}
.catalog-item-old_price {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.045em;
    color: var(--gray);
}
.catalog-item-old_price span {
    position: relative;
}
.catalog-item-old_price span::after {
    content: "";
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    translate: 0 -50%;
    background-color: var(--gray);
    height: 1px;
}
.catalog-item-current_price {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.045em;
    color: var(--black);
}

.catalog-item-btn {
    margin-top: 20px;
    padding: 10px;
    justify-self: flex-end;
}

.catalog-item-info {
    display: flex;
    flex-direction: column;
}
.catalog-item-info-block:first-of-type {
    margin-top: 20px;
}
.catalog-item-info-block {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--dark_gray);
}

.catalog-item-info-block span {
    color: var(--black);
}

.catalog-item-favourites.mobile {
    display: none;
}

.catalog-item.delete {
    border: none;
    padding: 20px 0;
}
.catalog-item.delete .catalog-item-btns {
    display: none;
}
.catalog-item-remove-btn {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    color: var(--dark_gray);
    transition: 0.5s;
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: 5px;
}
.catalog-item-remove-btn:hover {
    border: 1px solid var(--black);
    color: var(--black);
}
@media screen and (max-width: 1200px) {
    .catalog-item-prices .custom-btn {
        padding: 5px;
        font-size: 10px;
    }
    .catalog-item-hints {
        top: 10px;
        left: 10px;
    }
    .catalog-item-btns {
        position: relative;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        top: 0px;
        right: 0px;
        margin: 10px 0px;
    }

    .catalog-item-title {
        margin: 20px 0px 10px;
        font-size: 14px;
    }

    .catalog-item-current_price,
    .catalog-item-old_price {
        font-size: 12px;
    }

    .catalog-item-favourites.mobile {
        display: block;
    }

    .catalog-item:not(.delete) .catalog-item-btn {
        display: none;
    }
    .catalog-item .catalog-item-btn.empty-price-mobile {
        display: inline-block;
        font-size: 10px;
        padding: 5px;
    }

    .n-pagination .n-pagination-item {
        font-size: 16px;
        min-width: 40px;
        height: 40px;
    }

    .n-base-icon svg {
        width: 20px;
        height: 20px;
    }

    .n-breadcrumb .n-breadcrumb-item {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
    }

    .catalog-sort-select .n-base-selection-input {
        display: none;
    }

    .catalog-sort-select .n-base-selection .n-base-suffix {
        width: 20px;
        height: 20px;
        /* position: relative;
        top: 10px;
        right: 10px; */
    }
    .n-base-selection .n-base-loading .n-base-loading__placeholder,
    .n-base-clear {
        width: 20px;
        height: 20px;
    }
    .catalog-item-info-block:first-of-type {
        margin-top: 10px;
    }
    .catalog-item-info-block {
        margin-top: 5px;
        font-size: 10px;
        line-height: 100%;
    }
}
</style>
