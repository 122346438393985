<template>
    <section id="catalog">
        <div class="container catalog__wrapper">
            <h1>Каталог</h1>
            <div class="catalog">
                <CatalogFilters
                    :filters="filters"
                    :default_filters="default_filters"
                    :loading="loading"
                    @update-filters="updateFilters"
                />
                <div class="catalog-list-sort mobile">
                    <div class="mobile-filter-btn" @click="open">
                        <ph-faders :size="20" />
                        Фильтр товаров
                    </div>
                    <div class="desktop">Сортировка:</div>
                    <n-select
                        class="catalog-sort-select list"
                        :options="options"
                        v-model:value="currentValue"
                        :menu-props="menu_styles"
                        :placement="placement_list"
                        @update:value="updateSort"
                        :disabled="loading"
                    >
                        <template #arrow>
                            <transition class="desktop" name="slide-left">
                                <ph-caret-down :size="20" />
                            </transition>
                            <transition class="mobile" name="slide-left">
                                <ph-arrows-down-up :size="20" />
                            </transition>
                        </template>
                    </n-select>
                </div>
                <div class="catalog-list" v-if="products">
                    <CatalogList
                        @open-filter="open"
                        :catalog="products"
                        :loading="loading"
                        :sort="sort"
                        @update-sort="updateSort"
                    />
                    <n-pagination
                        :simple="is_mobile"
                        v-if="count_pages > 1"
                        class="catalog-pagination"
                        v-model:page="page"
                        :page-count="count_pages"
                        :page-slot="5"
                        :disabled="loading"
                        @update:page="updatePage"
                    />
                </div>
                <div class="not-found-catalog" v-else>
                    <img :src="require('@/assets/img/empty_find.png')" alt="" />
                    <div class="h3">Товаров не найдено</div>
                </div>
                <MobileCatalogFilters
                    ref="mobile_filters_ref"
                    :filters="filters"
                    :default_filters="default_filters"
                    :loading="loading"
                    @update-filters="updateFilters"
                />
            </div>
        </div>
    </section>
</template>
<script setup>
import CatalogFilters from "./CatalogFilters.vue";
import CatalogList from "./CatalogList.vue";
import MobileCatalogFilters from "@/components/catalog/MobileCatalogFilters.vue";
import allDoors from "@/assets/js/api/products.js";
import { NPagination, NSelect } from "naive-ui";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { PhCaretDown, PhArrowsDownUp, PhFaders } from "@phosphor-icons/vue";

const router = useRouter();
const route = useRoute();
const store = useStore();

const page = ref(1);
const count_pages = ref(1);
const count_on_page = ref(1);

const products = ref(null);

const filters = ref([]);
const applied_filters = ref({});
const default_filters = ref({});
const sort = ref("popular");

const loading = ref(false);

store.commit("setLoading", true);

const placement_list = ref(null);
if (window.innerWidth < 1200) {
    placement_list.value = "bottom-end";
} else {
    placement_list.value = "bottom-start";
}

const options = [
    {
        label: "По популярности",
        value: "popular",
    },
    {
        label: "Сначала новинки",
        value: "new",
    },
    {
        label: "Сначала дешевые",
        value: "low",
    },
    {
        label: "Сначала дорогие",
        value: "expensive",
    },
];

const currentValue = ref(null);
currentValue.value = sort.value;

const menu_styles = { class: "catalog-sort-select-menu" };

// Открытие фильтров (моб.)
const mobile_filters_ref = ref(null);
function open() {
    mobile_filters_ref.value.open();
}

// Дефолтные фильтры из ссылки
function setDefaultFilters() {
    const temp = {};

    for (const key in route.query) {
        if (key == "current_page") {
            page.value = Number(route.query.current_page);
        } else if (key == "sort") {
            sort.value = route.query[key];
        } else {
            temp[key] = route.query[key];
        }
    }

    applied_filters.value = temp;
    default_filters.value = temp;
}
async function init() {
    await setDefaultFilters();
    await getDoors(true);
}
init();
// Получние товаров
async function getDoors(is_first = false) {
    loading.value = true;

    if (!is_first) {
        router.push({
            query: {
                ...applied_filters.value,
                sort: sort.value,
                current_page: page.value,
            },
        });
    }

    for (const key in applied_filters.value) {
        if (applied_filters.value[key]?.length == 0) {
            delete applied_filters.value[key];
        }
    }

    const params = {
        ...applied_filters.value,
    };

    if (sort.value) {
        params.sort = sort.value;
    }

    const result = await allDoors.get(params, { page: page.value });
    page.value = result?.pagination?.page;
    count_pages.value = result?.pagination?.count_pages;
    count_on_page.value = result?.pagination?.count_on_page;
    products.value = result?.doors;
    filters.value = result?.filters;

    setDefaultFilters();

    loading.value = false;

    store.state.scrollItem.scrollTo({ top: 0, behavior: "smooth" });
    if (is_first) {
        store.commit("setLoading", false);
    }
}

// Переход по сраницам
async function updatePage() {
    await getDoors();
}

// Обновление фильтров
async function updateFilters(value) {
    applied_filters.value = value;
    page.value = 1;
    await getDoors();
}

// Обновление сортировки
async function updateSort(value) {
    sort.value = value;
    page.value = 1;
    await getDoors();
}

const is_mobile = ref(false);

function checkDisplay() {
    if (window.innerWidth > 1200) {
        is_mobile.value = false;
    } else {
        is_mobile.value = true;
    }
}

checkDisplay();

onMounted(() => {
    window.addEventListener("resize", checkDisplay);
});
</script>
<style>
.catalog {
    display: flex;
    gap: 30px;
}

.not-found-catalog {
    width: 100%;
    text-align: center;
}
.not-found-catalog img {
    pointer-events: none;
    width: 200px;
    height: 200px;
}

@media screen and (max-width: 1200px) {
    .catalog {
        flex-direction: column;
    }
}
</style>
