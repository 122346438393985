<template>
    <section
        id="company_main"
        :style="
            'background-image: linear-gradient(257.91deg, rgba(41, 41, 41, 0) 25.9%, rgba(42, 42, 42, 0.812179) 76.73%, #000000 101.1%), url(' +
            props?.company?.img +
            ')'
        "
    >
        <div class="container company-container">
            <div class="main-container-header">
                <div class="h1">{{ props?.company?.title }}</div>
                <div
                    class="main-container-desc"
                    v-html="props?.company?.desc"
                ></div>
            </div>
            <Button class="main-container-btn" type="white" @click="openPopup">
                Оставить заявку
            </Button>
        </div>
    </section>
</template>
<script setup>
import Button from "../UI/Button.vue";
import { useStore } from "vuex";

const props = defineProps({
    company: Object,
});

const store = useStore();

function openPopup() {
    store.commit("orderPopupOpen", true);
}
</script>
<style>
#company_main {
    position: relative;
    height: 800px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    object-fit: cover;
}
.company-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    padding: 0 20px 80px;
    gap: 60px;
}

.main-container-header {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.main-container-h1 {
    color: var(--white);
}
.main-container-desc {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    color: var(--white);
}

.main-container-btn {
    width: max-content;
}

@media screen and (max-width: 1200px) {
    #company_main {
        height: 600px;
    }
    .main-container-header {
        gap: 10px;
    }
}

@media screen and (max-width: 800px) {
    .company-container {
        gap: 40px;
        padding: 0 20px 60px;
    }
    .main-container-btn {
        width: 100%;
    }
    .main-container-desc {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.12px;
        letter-spacing: 0.045em;
        text-align: left;
    }
    .main-container-desc br {
        display: none;
    }
}
@media screen and (max-width: 380px) {
    .company-container {
        padding: 0 10px 60px;
    }
}
</style>
