<template>
    <MainMontage :montage="page_data?.data?.montage" />
    <MontageBaner :baner="page_data?.data?.baner" />
    <OrderSection />
</template>
<script setup>
import MontageBaner from "@/components/montage/MontageBaner.vue";
import OrderSection from "@/components/UI/OrderSection.vue";
import MainMontage from "@/components/montage/MainMontage.vue";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import pagesApi from "@/assets/js/api/pages.js";

const store = useStore();

store.commit("setBreadcrumb", [
    { name: "Главная", link: "/" },
    { name: "Услуги" },
    { name: "Установка дверей" },
]);

const page_data = ref(null);

onMounted(async () => {
    store.commit("setLoading", true);
    const result = await pagesApi.getPageData("page/montage");
    page_data.value = result;

    if (result) {
        store.commit("setLoading", false);
    }
});
</script>
<style>
.montage-container h1 {
    margin-bottom: 60px;
}

.sub-title {
    margin-bottom: 40px;
}
@media screen and (max-width: 800px) {
    .montage-container h1 {
        margin-bottom: 20px;
    }
    .sub-title {
        margin-bottom: 20px;
    }
}
</style>
