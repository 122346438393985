<template>
    <div class="price-container">
        <n-tabs
            type="line"
            animated
            class="price-tabs"
            ref="tabs_ref"
            v-model:value="active_tab"
        >
            <n-tab-pane
                :name="item.name"
                :tab="item.tab"
                v-for="(item, index) in tabs"
            >
                <div class="price-tab-header" v-if="props?.tabs[index].price_type">
                    <h4>Услуга</h4>
                    <h4>Цена, руб.</h4>
                </div>
                <div class="price-tab-body">
                    <div class="price-tab-item" v-for="list in item.content">
                        <div
                            class="price-tab-item-name"
                            v-html="list.name"
                        ></div>
                        <div
                            class="price-tab-item-price"
                            v-html="numberWithSpaces(list.price)"
                        ></div>
                    </div>
                </div>
            </n-tab-pane>
        </n-tabs>
    </div>
</template>
<script setup>
import { NTabs, NTabPane } from "naive-ui";
import { nextTick, ref, watch } from "vue";
import { numberWithSpaces } from "@/assets/js/helper";

const props = defineProps({
    tabs: Array,
});

const tabs = ref([]);
const tabs_ref = ref(null);

const active_tab = ref("tab_0");

watch(props, async (new_value) => {
    if (new_value.tabs) {
        new_value.tabs.forEach(async (elems, index) => {
            tabs.value.push({
                tab: elems.title,
                name: "tab_" + index,
                content: elems.tab_list,
            });
        });
        await nextTick();
        tabs_ref.value.syncBarPosition();
    }
});
</script>
<style>
.price-tabs.n-tabs.n-tabs--top .n-tab-pane {
    padding: 0;
}

.price-tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
}
.price-tab-body {
    display: flex;
    flex-direction: column;
}
.price-tab-item {
    gap: 30px;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--gray);
}
.price-tab-item:last-of-type {
    border-bottom: 1px solid var(--gray);
}
.price-tab-item-name,
.price-tab-item-price {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
    color: var(--black);
}

@media screen and (max-width: 800px) {
    .price-tabs.n-tabs .n-tabs-wrapper {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .price-tabs.n-tabs .n-tabs-wrapper {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .price-tabs.n-tabs .n-tabs-nav-scroll-content {
        justify-content: center;
    }
    .price-tabs.n-tabs .n-tabs-tab-wrapper {
        width: 100%;
    }
    .price-tabs.n-tabs
        .n-tabs-nav.n-tabs-nav--line-type.n-tabs-nav--top
        .n-tabs-bar {
        display: none;
    }
    .price-tabs.n-tabs.n-tabs--line-type .n-tabs-tab.n-tabs-tab--active {
        border-bottom: 1px solid var(--accent);
    }
    .price-tabs.n-tabs.n-tabs--line-type .n-tabs-tab {
        border-bottom: 1px solid transparent;
        width: 100%;
        justify-content: center;
    }
    .price-tab-header {
        padding: 20px 0;
    }
    .price-tabs.n-tabs.n-tabs--top .n-tab-pane {
        padding: 20px 0 0;
    }
    .price-tab-item-name,
    .price-tab-item-price {
        font-size: 14px;
        line-height: 19.12px;
    }
    .price-tab-item-price {
        white-space: nowrap;
    }
}
</style>
