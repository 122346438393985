<template>
    <div class="product-info">
        <!-- <ImgPicker
            :imgs="new_imgs"
            v-if="new_imgs"
            :current_slide="current_slide"
            />
        <div class="product-no-images" v-else>
            <img :src="require('@/assets/img/no_img.png')" alt="" />
        </div> -->
        <div class="product-image" v-if="current_slide">
            <img :src="current_slide" />
        </div>
        <div class="product-no-images" v-else>
            <img :src="require('@/assets/img/no_img.png')" alt="" />
        </div>
        <n-tabs
            type="line"
            animated
            class="product-tabs"
            :default-value="description ? 'desc' : 'specifications'"
        >
            <n-tab-pane name="desc" tab="Описание">
                <noindex>
                    <div class="tab-info" v-html="description"></div>
                </noindex>
            </n-tab-pane>
            <n-tab-pane name="specifications" tab="Характеристики">
                <div class="specifications-list">
                    <div
                        class="specifications-list-item"
                        v-for="item in specifications"
                    >
                        <div class="specifications-list-item-title">
                            {{ item.name }}
                        </div>
                        <div class="specifications-list-item-value">
                            {{ item.value }}
                        </div>
                    </div>
                </div>
            </n-tab-pane>
            <n-tab-pane name="reviews" tab="Отзывы">
                <div class="tab-info low">
                    <n-carousel
                        class="review-slider"
                        draggable
                        :slides-per-view="slides_per_view"
                        :show-dots="false"
                        :loop="false"
                        :space-between="30"
                    >
                        <template v-for="item in filter_reviews">
                            <ReviewCard :review="item" />
                        </template>
                    </n-carousel>
                    <Button @click="openPopup">Оставить отзыв</Button>
                </div>
            </n-tab-pane>
        </n-tabs>
    </div>
</template>
<script setup>
import { NTabs, NTabPane, NCarousel } from "naive-ui";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import ImgPicker from "../UI/ImgPicker.vue";
import ReviewCard from "@/components/UI/ReviewCard.vue";
import Button from "../UI/Button.vue";

const store = useStore();

const props = defineProps({
    comments: Array,
    data: Object,
    tabs: Array,
    variations: Array,

    current_slide: Object,
});

const current_slide = ref(null);

watch(props, (new_slide) => {
    current_slide.value = new_slide?.data?.img;
    if (new_slide?.variations) {
        current_slide.value = new_slide?.variations[0]?.img;
    }
    if (new_slide?.current_slide) {
        current_slide.value = new_slide?.current_slide.img;
    }
});

const slides = ref([]);
const slides_per_view = ref(1);

const description = ref(null);
const specifications = ref(null);

function checkSize() {
    if (window.innerWidth > 800) {
        slides_per_view.value = 2;
    } else {
        slides_per_view.value = 1;
    }
}

watch(props, (new_value) => {
    if (new_value.comments) {
        slides.value = new_value.comments;
    }
    if (new_value.tabs) {
        description.value = new_value?.tabs[0]?.content;
        specifications.value = new_value?.tabs[1]?.content;
    }
});

const filter_reviews = computed(() => {
    if (props?.comments) {
        return props?.comments.filter((el) => {
            return el.content || el.imgs;
        });
    }
});

onMounted(() => {
    checkSize();
    window.addEventListener("resize", checkSize);
});

function openPopup() {
    store.commit("reviewPopupOpen", true);
    store.commit("reviewPopupId", props?.data?.id);
}

// const new_imgs = ref([]);
// new_imgs.value = props?.variations;

// watch(props, (new_value) => {
//     if (new_value?.data?.imgs && new_value?.variations) {
//         new_imgs.value = new_value?.variations;
//     }
// });
</script>
<style>
.product-info .n-carousel .n-carousel__slides .n-carousel__slide,
.review-slider.n-carousel .n-carousel__slides .n-carousel__slide {
    height: auto;
}
.product-info .n-carousel .n-carousel__slides,
.review-slider.n-carousel .n-carousel__slides {
    align-items: stretch;
}
.product-info {
    display: flex;
    flex-direction: column;
    gap: 60px;
    max-width: 1150px;
}

.main-catalog-tabs.n-tabs.n-tabs--top .n-tab-pane {
    padding-top: 60px;
}
.tab-info {
    display: flex;
    flex-direction: column;
    gap: 60px;
}
.tab-info.low {
    gap: 40px;
}
.tab-info.low button {
    width: max-content;
    align-self: center;
}
.product-tab-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.product-tab-block *:not(h3) {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
    color: var(--black);
}
.product-tab-block ul,
.product-tab-block ol {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.product-tabs.n-tabs.n-tabs--top .n-tab-pane {
    padding-top: 60px;
}

.specifications-list {
    display: flex;
    flex-direction: column;
}
.specifications-list-item {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
}

.specifications-list-item:nth-child(even) {
    background-color: var(--light_gray);
}

.specifications-list-item-title {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    letter-spacing: 0.045em;
    text-align: left;
}

.product-image {
    min-width: 300px;
    width: 100%;
    height: 700px;
    margin: 0 auto;
}

.product-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media screen and (max-width: 1200px) {
    .product-info .product-image,
    .product-info .product-no-images {
        display: none;
    }
    .product-tabs.n-tabs.n-tabs--top .n-tab-pane {
        padding-top: 40px;
    }
    .main-catalog-tabs.n-tabs.n-tabs--top .n-tab-pane {
        padding-top: 20px;
    }
    .tab-info {
        width: 100%;
        gap: 20px;
    }
    .specifications-list-item {
        padding: 10px;
        gap: 20px;
    }
    .specifications-list-item-title {
        font-size: 16px;
        line-height: 19.5px;
        letter-spacing: 0.045em;
    }
}
@media screen and (max-width: 800px) {
    .product-tabs.n-tabs .n-tabs-wrapper {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .product-tabs.n-tabs .n-tabs-nav-scroll-content {
        justify-content: center;
    }
    .product-tabs.n-tabs .n-tabs-tab-wrapper {
        width: 100%;
    }
    .product-tabs.n-tabs
        .n-tabs-nav.n-tabs-nav--line-type.n-tabs-nav--top
        .n-tabs-bar {
        display: none;
    }
    .product-tabs.n-tabs.n-tabs--line-type .n-tabs-tab.n-tabs-tab--active {
        border-bottom: 1px solid var(--accent);
    }
    .product-tabs.n-tabs.n-tabs--line-type .n-tabs-tab {
        border-bottom: 1px solid transparent;
        width: 100%;
        justify-content: center;
    }

    .product-tab-block {
        gap: 10px;
    }
    .product-tab-block *:not(h3) {
        font-family: Manrope;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.12px;
        letter-spacing: 0.045em;
        text-align: left;
    }
}
</style>
