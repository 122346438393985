<template>
    <div class="horizontal-card" :class="type == 'history' ? 'history' : ''">
        <div class="horizontal-card-left">
            <n-checkbox
                class="horizontal-card-choose"
                v-model:checked="choose"
                v-if="type != 'history'"
                @update:checked="updateChecked"
            >
            </n-checkbox>
            <div class="horizontal-card-img">
                <img :src="props.card?.img" alt="" v-if="props.card?.img" />
                <img :src="require('@/assets/img/no_img.png')" alt="" v-else />
            </div>
            <div class="horizontal-card-main-info">
                <a
                    :href="
                        props?.card?.type == 'furniture'
                            ? '#'
                            : '/product/' + props?.card?.slug
                    "
                    :class="props?.card?.type"
                    class="horizontal-card-title"
                >
                    <h4>{{ props.card?.title }}</h4>
                </a>
                <h4 class="horizontal-card-date mob" v-if="type == 'history'">
                    {{ props.card?.date }}
                </h4>
                <ul class="horizontal-card-sub-info">
                    <li v-if="props.card?.article">
                        Артикул: <span>{{ props.card?.article }}</span>
                    </li>
                    <li v-if="props?.card?.brand?.title">
                        Бренд: <span>{{ props?.card?.brand?.title }}</span>
                    </li>
                    <li
                        v-if="
                            props?.card?.price_kit &&
                            props?.card?.price_kit != '0'
                        "
                    >
                        Комплект: <span>{{ props?.card?.price_kit }} ₽</span>
                    </li>
                    <li>
                        Наличие:
                        <template v-if="props?.card?.available">
                            <span>{{ props?.card?.available }}</span>
                        </template>
                        <template v-else>
                            <span>В наличии</span>
                        </template>
                        <!-- <span>
                            {{
                                props?.card?.available
                                    ? "В наличии"
                                    : "Нет в наличии"
                            }}
                        </span> -->
                    </li>
                    <li
                        class="horizontal-card__price mob"
                        v-if="type != 'history'"
                    >
                        <Counter
                            :value="product_count"
                            :default="set_max_count"
                            @update:value="setCounter"
                        />
                    </li>
                    <li class="horizontal-card__price mob">
                        <div class="horizontal-card-right-mob">
                            <div class="horizontal-card__price">
                                <div class="horizontal-card__price-main">
                                    Цена:
                                    <h4>{{ new_price + " ₽" }}</h4>
                                </div>
                                <div class="horizontal-card__price-counter">
                                    <p
                                        v-html="
                                            product_count +
                                            ' ' +
                                            num_word(product_count, words)
                                        "
                                    ></p>
                                    <span>x</span>
                                    {{ product_complects + " ₽" }}
                                </div>
                            </div>
                            <div
                                class="horizontal-card-btns"
                                v-if="type != 'history'"
                            >
                                <button
                                    @click="addToFavourites(props?.card?.id)"
                                    :class="in_favourites ? 'active' : ''"
                                    v-if="props?.card?.type != 'furniture'"
                                >
                                    <PhHeartStraight :size="20" />
                                </button>
                                <button
                                    @click="
                                        deleteFromBasket(props?.card?.uniq_id)
                                    "
                                >
                                    <PhTrash :size="20" />
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <h4 class="horizontal-card-date pc" v-if="type == 'history'">
            {{ props.card?.date }}
        </h4>
        <Counter
            v-if="type != 'history'"
            class="pc"
            :value="product_count"
            :default="set_max_count"
            @update:value="setCounter"
        />
        <div class="horizontal-card-right">
            <div class="horizontal-card__price">
                <div class="horizontal-card__price-main">
                    Цена:
                    <h4>{{ new_price + " ₽" }}</h4>
                </div>
                <div class="horizontal-card__price-counter">
                    <p
                        v-if="props.card?.info?.complect"
                        v-html="
                            product_count +
                            ' ' +
                            num_word(product_count, words_complect)
                        "
                    ></p>
                    <p
                        v-else
                        v-html="
                            product_count + ' ' + num_word(product_count, words)
                        "
                    ></p>
                    <span>x</span> {{ product_complects + " ₽" }}
                </div>
            </div>
            <div class="horizontal-card-btns" v-if="type != 'history'">
                <button
                    @click="addToFavourites(props?.card?.id)"
                    :class="in_favourites ? 'active' : ''"
                    v-if="props?.card?.type != 'furniture'"
                >
                    <PhHeartStraight :size="20" />
                </button>
                <button @click="deleteFromBasket(props?.card?.uniq_id)">
                    <PhTrash :size="20" />
                </button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { NCheckbox, useMessage, NAlert } from "naive-ui";
import { PhHeartStraight, PhTrash } from "@phosphor-icons/vue";
import { numberWithSpaces, num_word } from "@/assets/js/helper.js";
import { computed, ref, watch, defineEmits, h } from "vue";
import { useStore } from "vuex";
import Counter from "./Counter.vue";
import productApi from "@/assets/js/api/products.js";

const store = useStore();

const message_error = useMessage();

const words_complect = ["комплект", "комплекта", "комплектов"];
const words = ["полотно", "полотна", "полотен"];
const product_count = ref(1);

product_count.value = props?.card?.count;
const set_max_count = computed(() => {
    if (props?.card?.count > 99) {
        return 99;
    } else {
        return props?.card?.count;
    }
});

const emit = defineEmits(["update_count", "update_checked", "delete_item"]);

const props = defineProps({
    card: Object,
    type: "",
    checked: false,
});

const new_price = computed(() => {
    if (props?.card?.info?.complect) {
        if (props?.card?.price_kit && props?.card?.price_kit != "0") {
            return numberWithSpaces(props?.card?.price_kit);
        }
    } else {
        if (props?.card?.price_stock && props?.card?.price_stock != "0") {
            return numberWithSpaces(props?.card?.price_stock);
        }
        if (props?.card?.price) {
            return numberWithSpaces(props?.card?.price);
        }
    }
});

const product_complects = computed(() => {
    if (props?.card?.info?.complect) {
        if (props?.card?.price_kit && props?.card?.price_kit != "0") {
            return numberWithSpaces(
                product_count.value * props?.card?.price_kit
            );
        }
    } else {
        if (props?.card?.price_stock && props?.card?.price_stock != "0") {
            return numberWithSpaces(
                product_count.value * props?.card?.price_stock
            );
        }
        if (props?.card?.price) {
            return numberWithSpaces(product_count.value * props?.card?.price);
        }
    }
});

async function setCounter(value) {
    product_count.value = value;
    let data = {
        id: props?.card?.uniq_id,
        count: product_count.value,
    };

    emit("update_count", data);

    data = {
        date: new Date().getTime(),
        id: props?.card?.id,
        uniq_id: props?.card?.uniq_id,
        color_id: props?.card?.color_id,
        count: product_count.value,
        count_add: true,
    };

    const result = await productApi.products_actions("basket", data, "basket");
}

const choose = ref(false);
choose.value = props?.checked;

watch(props, (new_value) => {
    choose.value = new_value?.checked;
});

function updateChecked(value) {
    emit("update_checked", { id: props?.card?.uniq_id, value });
}

async function addToFavourites(id) {
    const result = await productApi.products_actions("favourites", {
        date: new Date().getTime(),
        id,
    });
    if (!result?.success) {
        message_error.error(
            result?.message ?? "Ошибка. Повторите попытку позже.",
            {
                render: (props) => {
                    return h(
                        NAlert,
                        {
                            closable: props.closable,
                            onClose: props.onClose,
                            type: "error",
                            style: {
                                boxShadow: "var(--n-box-shadow)",
                                maxWidth: "calc(100vw - 32px)",
                                padding: "14px",
                                width: "480px",
                            },
                        },
                        {
                            default: () =>
                                h("div", { innerHTML: props.content }),
                        }
                    );
                },
                closable: true,
                duration: 5000,
            }
        );
    }
}

function deleteFromBasket(id) {
    emit("delete_item", id);
}

const in_favourites = computed(() => {
    if (store.state.itemsInFavourites?.length > 0) {
        return store.state.itemsInFavourites.find(
            (el) => el.id == props?.card?.id
        );
    } else {
        return false;
    }
});
</script>
<style>
.horizontal-card {
    display: grid;
    grid-template-columns: 1fr 140px 300px;
    align-items: center;
    gap: 60px;
    padding: 40px 0;
    border-top: 1px solid var(--gray);
    width: 100%;
}

.horizontal-card.history {
    align-items: flex-start;
    grid-template-columns: 1fr 0.5fr 300px;
}
.horizontal-card.history .horizontal-card-left {
    justify-content: flex-start;
}

.horizontal-card:last-of-type {
    border-bottom: 1px solid var(--gray);
}
.horizontal-card-left {
    display: flex;
    /* justify-content: space-between; */
    gap: 40px;
}
.horizontal-card-choose {
    align-self: center;
}
.horizontal-card-img {
    height: 200px;
}
.horizontal-card-img img {
    width: 80px;
    height: 100%;
    object-fit: contain;
}
.horizontal-card-main-info {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.horizontal-card-main-info h4 {
    word-break: break-word;
}

.horizontal-card-main-info.furniture {
    cursor: default;
}

.horizontal-card-title {
    text-align: left;
    text-decoration: none;
    color: var(--black);
    transition: 0.5s;
}
.horizontal-card-title:hover {
    color: var(--hover);
}

.horizontal-card-sub-info {
    padding: 0;
    margin: 0;
    list-style: none;
    display: grid;
    gap: 10px;
}
.horizontal-card-sub-info li {
    display: inline-block;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--dark_gray);
}
.horizontal-card-sub-info li span {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black);
}
.horizontal-card-right {
    display: flex;
    gap: 60px;
    align-items: center;
}
.horizontal-card__price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 7px;
}
.horizontal-card__price.mob {
    display: none;
}
.horizontal-card__price-main {
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
    color: var(--dark_gray);
}
.horizontal-card__price-main h4 {
    display: inline;
    color: var(--black);
}
.horizontal-card__price-counter {
    align-self: flex-end;
    font-family: Manrope;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.39px;
    letter-spacing: 0.045em;
    text-align: right;
    display: inline-flex;
    flex-wrap: nowrap;
    gap: 5px;
}

.horizontal-card-btns {
    display: flex;
    align-items: center;
    gap: 10px;
}
.horizontal-card-btns button {
    color: var(--gray);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    transition: 0.5s;
    cursor: pointer;
}
@media (hover: hover) {
    .horizontal-card-btns button:hover {
        color: var(--black);
    }
}

.horizontal-card-btns button.active {
    color: var(--black);
}
.horizontal-card-date {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0%;
}
.horizontal-card-date.mob {
    display: none;
}
.horizontal-card-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 60px;
}
@media screen and (max-width: 1500px) {
    .horizontal-card {
        gap: 30px;
    }
}
@media screen and (max-width: 1000px) {
    .horizontal-card.history {
        grid-template-columns: 1fr 0.5fr 200px;
    }
}

@media screen and (max-width: 800px) {
    .horizontal-card.history .horizontal-card-img img {
        width: 120px;
    }
    .horizontal-card.history {
        padding: 20px 0;
    }
    .horizontal-card-right {
        display: none;
    }
    .horizontal-card.history .horizontal-card-main-info {
        gap: 10px;
    }
    .horizontal-card-sub-info li.horizontal-card__price.mob span {
        font-size: 10px;
        font-weight: 400;
        line-height: 13.66px;
        letter-spacing: 0.045em;
    }
    .horizontal-card.history .horizontal-card-sub-info {
        margin-top: 10px;
    }
    .horizontal-card-date.mob {
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0%;
        display: block;
    }
    .horizontal-card-date.pc,
    .horizontal-card__price.pc {
        display: none;
    }
    .horizontal-card__price.mob {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: flex-start;
    }
    .horizontal-card__price.mob .horizontal-card__price-counter {
        align-self: flex-start;
    }
    .horizontal-card {
        display: flex;
        flex-direction: column;
        padding: 20px 0;
    }
    .horizontal-card-left {
        width: 100%;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .horizontal-card-choose {
        align-self: flex-start;
    }
    .horizontal-card-right {
        align-self: flex-end;
        gap: 40px;
    }
    .horizontal-card__price-main {
        font-size: 14px;
        line-height: 19.12px;
        letter-spacing: 0.045em;
        text-align: left;
    }
    .horizontal-card__price-counter {
        font-size: 10px;
        font-weight: 400;
        line-height: 13.66px;
        letter-spacing: 0.045em;
        text-align: left;
    }
    .horizontal-card-right-mob {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
    }
}
@media screen and (max-width: 360px) {
    .horizontal-card .counter {
        margin-left: 30px;
    }
    .horizontal-card-right {
        align-self: flex-end;
        gap: 40px;
    }
    .counter.pc {
        display: none;
    }
}
</style>
