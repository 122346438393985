<template>
    <section>
        <div class="container zamer__wrapper">
            <h1>{{ props?.zamer?.title }}</h1>
            <div class="zamer-content">
                <div class="zamer-subtitle">
                    <h3 v-html="props?.zamer?.subtitle"></h3>
                    <p v-html="props?.zamer?.text"></p>
                </div>
                <div class="zamer-cards">
                    <div
                        class="zamer-cards-item"
                        v-for="(item, index) in props?.zamer?.cards"
                        :style="[
                            index == 0
                                ? 'background-image: linear-gradient(42.52deg, #1C1C1C 8.28%, rgba(102, 102, 102, 0) 117.35%), url(' +
                                  props?.zamer?.img +
                                  ')'
                                : '',
                        ]"
                        :class="index == 0 ? 'main-card' : ''"
                    >
                        <h5 class="zamer-cards-item-count">
                            {{ (index + 1).toString().padStart(2, 0) }}
                        </h5>
                        <p v-html="item.text"></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script setup>
const props = defineProps({
    zamer: Object,
});

const img = require("@/assets/img/zamer.jpg");
const cards = [
    {
        text: "Измерьте ширину дверного проема. <br>Для этого измерьте расстояние между боковыми стенами проема.",
    },
    {
        text: "Измерьте высоту дверного проема. <br>Для этого измерьте расстояние от пола до верхней части проема.",
    },
    {
        text: "Измерьте ширину дверного проема. <br>Для этого измерьте расстояние между боковыми стенами проема.",
    },
    {
        text: "Измерьте ширину дверного проема. <br>Для этого измерьте расстояние между боковыми стенами проема.",
    },
    {
        text: "Измерьте ширину дверного проема. <br>Для этого измерьте расстояние между боковыми стенами проема.",
    },
];
</script>
<style>
.zamer__wrapper {
    display: flex;
    flex-direction: column;
    gap: 60px;
}
.zamer-content {
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.zamer-subtitle {
    display: flex;
    justify-content: space-between;
}

.zamer-subtitle h3 p {
    font-family: Montserrat;
    font-size: 26px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0.045em;
}

.zamer-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

.zamer-cards-item.main-card {
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.zamer-cards-item-count {
    background-color: var(--white);
    padding: 10px;
    width: max-content;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.zamer-cards-item {
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    background-color: var(--light_gray);
}
.zamer-cards-item p {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
    color: var(--black);
}
.zamer-cards-item.main-card p {
    color: var(--white);
}
.zamer-subtitle p {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    letter-spacing: 0.045em;
    text-align: left;
}
@media screen and (max-width: 1200px) {
    .zamer-content {
        gap: 10px;
    }
    .zamer__wrapper {
        gap: 20px;
    }
    .zamer-subtitle {
        flex-direction: column;
        gap: 10px;
    }
    .zamer-subtitle br {
        display: none;
    }
    .zamer-subtitle p {
        font-size: 14px;
        line-height: 19.12px;
    }
    .zamer-cards {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .zamer-cards-item p {
        font-size: 14px;
        line-height: 19.12px;
    }
}
</style>
