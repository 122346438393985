<template>
    <div class="catalog-view">
        <Catalog />
    </div>
</template>
<script setup>
import Catalog from "@/components/catalog/Catalog.vue";
import { useStore } from "vuex";
// import { mapMutations } from "vuex";

const store = useStore();

store.commit("setBreadcrumb", [
    { name: "Главная", link: "/" },
    { name: "Каталог" },
    //параметр
]);
</script>
