import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/HomeView.vue";
import Company from "@/views/CompanyView.vue";
import Catalog from "@/views/CatalogView.vue";
import Product from "@/views/ProductView.vue";
import Favourites from "@/views/FavouritesView.vue";
import Contacts from "@/views/ContactsView.vue";
import NotFound from "@/views/404View.vue";
// import Page from "@/views/PageView.vue";
import DeliveryView from "@/views/DeliveryView.vue";
import MontageView from "@/views/MontageView.vue";
import ZamerView from "@/views/ZamerView.vue";
import Stock from "@/views/StocksView.vue";
import Comparison from "@/views/ComparisonView.vue";
import Registration from "@/views/RegistrationView.vue";
import Autho from "@/views/AuthorizationView.vue";
import Forgot from "@/views/ForgotPasswordView.vue";
import Basket from "@/views/BasketView.vue";
import MakingOrder from "@/views/MakingOrderView.vue";
import Profileviews from "@/views/Profileviews.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";
import SearchRusultsView from "@/views/SearchRusultsView.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
    },
    {
        path: "/policy/:slug",
        name: "policy",
        component: PrivacyPolicyView,
    },
    {
        path: "/profile/:slug",
        name: "profile",
        component: Profileviews,
    },
    {
        path: "/company",
        name: "company",
        component: Company,
    },
    {
        path: "/catalog",
        name: "catalog",
        component: Catalog,
    },
    {
        path: "/product/:slug",
        name: "product",
        component: Product,
    },
    {
        path: "/favourites",
        name: "favourites",
        component: Favourites,
    },
    {
        path: "/contacts",
        name: "contacts",
        component: Contacts,
    },
    // {
    //     path: "/privacy-policy",
    //     name: "privacy-policy",
    //     component: Page,
    // },
    {
        path: "/delivery",
        name: "delivery",
        component: DeliveryView,
    },
    {
        path: "/measuring",
        name: "measuring",
        component: ZamerView,
    },
    {
        path: "/montage",
        name: "montage",
        component: MontageView,
    },
    {
        path: "/stock",
        name: "stock",
        component: Stock,
    },
    {
        path: "/comparison",
        name: "comparison",
        component: Comparison,
    },
    {
        path: "/signup",
        name: "registration",
        component: Registration,
    },
    {
        path: "/login",
        name: "authorization",
        component: Autho,
    },
    {
        path: "/password_reset",
        name: "forgot-password",
        component: Forgot,
    },
    {
        path: "/basket",
        name: "basket",
        component: Basket,
    },
    {
        path: "/making_order",
        name: "making_order",
        component: MakingOrder,
    },
    {
        path: "/search_results",
        name: "search_results",
        component: SearchRusultsView,
    },
    {
        path: "/404",
        component: NotFound,
    },
    {
        path: "/:catchAll(.*)",
        redirect: "/404",
    },
];

const router = createRouter({
    history: createWebHistory("/"),
    routes,
});

export default router;
