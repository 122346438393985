<template>
    <div class="profile-card">
        <template v-if="user">
            <div class="profile-card-group">
                <div class="profile-card-user">
                    <ProfileAvatar />
                    <div class="profile-card-user-info">
                        <h4>{{ user.display_name }}</h4>
                        <span>{{ user.email }}</span>
                    </div>
                </div>
                <div class="profile-card-menu">
                    <a href="/profile/history">История заказов</a>
                    <a href="/profile/address">Адрес доставки</a>
                    <a href="/profile/settings">Настройки</a>
                </div>
            </div>
            <button class="signout-btn" @click="logout">Выход</button>
        </template>
        <template v-else>
            <div class="profile-card-menu">
                <a href="/signup">Зарегистрироваться</a>
                <a href="/login">Войти</a>
            </div>
        </template>
    </div>
</template>

<script setup>
import { useStore } from "vuex";
import Button from "@/components/UI/Button.vue";
import ProfileAvatar from "@/components/UI/header/ProfileAvatar.vue";
import userApi from "@/assets/js/api/user.js";

const store = useStore();
const user = store.state.user;

async function logout() {
    await userApi.logout();
    window.location.href = "/";
}
</script>

<style>
.profile-card {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}

.profile-card-group {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}

.profile-card-user {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.profile-card-user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.profile-card-user-info {
    cursor: default;
}

.profile-card-user-info span {
    font-family: Manrope;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.045em;
    color: var(--dark_gray);
    text-decoration: none;
    outline: none;
}

.profile-card-menu {
    display: flex;
    flex-direction: column;
}

.profile-card-menu a {
    padding: 10px 20px;
    display: block;
    color: var(--black);
    text-decoration: none;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
    transition: 0.5s;
    outline: none;
}

.profile-card-menu a:hover {
    color: var(--hover);
}

.signout-btn {
    padding: 20px 80px;
    border-radius: 5px;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    letter-spacing: 0.045em;
    text-align: center;
    color: #fff;
    background-color: var(--black);
    transition: 0.5s;
    border: none;
    cursor: pointer;
}

.signout-btn:hover {
    background-color: var(--dark_gray);
}

@media screen and (max-width: 1200px) {
    .profile-card {
        padding: 0;
        row-gap: 20px;
        justify-content: space-between;
        height: 100%;
    }

    .profile-card-group {
        row-gap: 40px; /* Специально сделал больше */
    }

    .profile-card-user {
        align-items: center;
        justify-content: flex-start;
        column-gap: 20px;
    }

    .profile-card-user .n-avatar {
        width: 42px;
        height: 42px;
    }

    .profile-card-user-info {
        row-gap: 5px;
    }

    .profile-card-menu a {
        padding: 10px 0;
        font-size: 12px;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 0.045em;
        text-align: left;
    }

    .signout-btn {
        padding: 10px;
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0.045em;
        text-align: center;
    }
}
</style>
